
import { Link } from 'react-router-dom';

export default function RequestButton({ className }) {

	return <Link to="/kraamzorg-aanvragen.html" className={`${className} text-white px-4 py-3 bg-green rounded-lg font-bold flex md:block hover:bg-green-dark`}>
		<span className="flex-auto text-center lg:text-left">Direct kraamzorg aanvragen</span> 
		<img src="/assets/images/request-button-icon.svg" alt="vraag aan" className="md:inline md:pl-2"/>
	</Link>

}

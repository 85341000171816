import { Fragment, useEffect, useState } from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Reviews, { Review } from '../Components/Reviews';
import RequestButton from '../Components/RequestButton';
import LimitedPlacesNotification from '../Components/LimitedPlacesNotification';
import Cookie from '../Components/Cookie';
import ShortSummaryLogos from '../Components/ShortSummaryLogos';
import AanvragenAndPostcodeCheck from '../Components/AanvragenAndPostcodeCheck';

// #DB1F6A

export default function Faq() {
  return (
    <Fragment>
        
        <Header activePage={`/veelgestelde-vragen.html`} />

		<LimitedPlacesNotification />

		<div className="md:hidden">
			<img alt="twee vrouwen die met elkaar praten over de intake" className="header-bg-clip bg-cover" src="/assets/images/intakekraamtijd-header-sm.jpg" />
		</div>

		<div className="md:container-fluid md:bg-intake-header md:h-[24rem] md:bg-no-repeat md:bg-center  md:header-bg-clip bg-cover">
			<div className="container mx-auto py-4 lg:py-14 flex justify-start">
				<div className="md:w-[65%] lg:w-[45%] md:bg-black md:bg-opacity-40 md:box-border md:rounded-lg md:z-10 md:relative">
					
				</div>
			</div>
		</div>


        <div className="md:container-fluid mt-0 lg:mt-16">
			<div className="container mx-auto ">

                <h1 className="text-3xl text-pink font-bold px-4 lg:px-0">Kom je er niet uit?</h1>
                <p className="text-lg mt-4 px-4 lg:px-0">
                    Ben je niet zo thuis in die papierwinkel van je verzekering? Wij zoeken de mogelijkheden van jouw verzekeringsmaatschappij graag voor je uit! Bel naar onze informatielijn: <span className="text-green">088 - 711 0100</span>

                </p>

                <FaqItems />

            </div>
        </div>








		<AanvragenAndPostcodeCheck />

		

        <Footer />

		<Cookie />

    </Fragment>
  );
}


function FaqItem({ item }) {

    const [expanded, setExpanded] = useState(false);

    let className = " hidden";
    if(expanded) {
        className = " block";
    }


    return <div className="bg-gray-design border border-b-0 border-l-0 border-r-0 lg:border-l lg:border-r p-4">

        <h3 className="text-lg font-bold cursor-pointer flex" onClick={() => setExpanded(!expanded)}>
            <div className="flex-auto" dangerouslySetInnerHTML={{ __html: item.question }}></div>
            <img alt="pijl" className="flex-initial" src={`/assets/images/${expanded ? 'up' : 'down'}-arrow-faq.svg`} />
        </h3>
        <div className={`text-lg prose lg:prose-lg ${className}`} dangerouslySetInnerHTML={{ __html: item.answer }}></div>


    </div>
}

function FaqItems() {

    const [faqItems, setFaqItems] = useState([]);
    let groupedItems = {};
    let groups = [];

    useEffect(async () => {
        let data = await fetch('/api/questions');
        console.log("fetched data in effect");
        // console.log(await data.json());
        let receivedItems = await data.json();
        setFaqItems(receivedItems);
    }, [])

    faqItems.forEach((item) => {
        if(!groupedItems[item.group]) {
            groups.push(item.group);
            groupedItems[item.group] = [];
        }

        groupedItems[item.group].push(item);
    })

    console.log(groupedItems);

    return <div className="mt-8">

        {groups.map((group) => {
            let items = groupedItems[group];
            
            return <div key={group}>
                
                <h2 className="text-2xl text-pink px-4 lg:px-0">{group}</h2>
                <div className="mt-4 mb-8 border-b">

                    {items.map((item, index) => {


                        return <FaqItem item={item} key={index.toString()} />
                    })}

                </div>


            </div>


        })}



    </div>




}
import { Fragment, useState } from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Reviews, { Review } from '../Components/Reviews';
import RequestButton from '../Components/RequestButton';
import ApplyJobButton from '../Components/ApplyJobButton';
import LimitedPlacesNotification from '../Components/LimitedPlacesNotification';
import Cookie from '../Components/Cookie';
import ShortSummaryLogos from '../Components/ShortSummaryLogos';
import { Link } from 'react-router-dom';
import moment from 'moment';

// #DB1F6A

export default function Vacancy({ place }) {
  return (
    <Fragment>
        
        <Header activePage={`/vacature-leerling.html`} />

		<LimitedPlacesNotification />

		<div className="md:hidden">
			<img alt="vacature" className="w-full header-bg-clip bg-cover" src="/assets/images/contact-image-header-sm.png" />
		</div>

		<div className=" md:block md:container-fluid md:bg-contact-header md:h-[30rem] md:bg-no-repeat md:bg-center  md:header-bg-clip bg-cover">
			<div className="container mx-auto py-4 lg:py-8 flex justify-start">
                <div className="md:w-[65%] lg:w-[45%] md:bg-white md:border md:box-border md:rounded-lg md:z-10 md:relative">
					<h1 className="text-2xl lg:text-4xl p-4 lg:py-4 lg:px-8 lg:pb-4 font-ro-sans font-bold">Voor alle vacatures geldt</h1>
					<div className="px-4 pb-2 lg:px-8 lg:pb-8  text-lg">
						

						<ul className="text-xl font-ro-sans ">
							<li className="py-1 lg:py-0 flex items-center">
								<div className="w-[50px] min-w-[50px] h-[50px] bg-white p-1 rounded-full flex place-content-center place-items-center">
                                    <img alt="96" className="max-h-[40px]" src="/assets/images/icon-pink-96.svg"/>
                                </div>
								<div className="pl-4 md:pl-4">Medewerkerstevredenheid score 9.6</div>
							</li>
                            <li className="py-1 lg:py-0 flex items-center">
								<div className="w-[50px] min-w-[50px] h-[50px] bg-white p-1 rounded-full flex place-content-center place-items-center">
                                    <img alt="kleine teams" className="max-h-[40px]" src="/assets/images/icon-pink-small-teams.svg"/>
                                </div>
								<div className="pl-4 md:pl-4">Kleine gezellige regionale zorgteams</div>
							</li>
                            <li className="py-1 lg:py-0 flex items-center">
								<div className="w-[50px] min-w-[50px] h-[50px] bg-white p-1 rounded-full flex place-content-center place-items-center">
                                    <img alt="eigen kraamschool" className="max-h-[40px]" src="/assets/images/icon-pink-school.svg"/>
                                </div>
								<div className="pl-4 md:pl-4">Een eigen kraamschool met een professionele skillab</div>
							</li>
                            <li className="py-1 lg:py-0 flex items-center">
								<div className="w-[50px] min-w-[50px] h-[50px] bg-white p-1 rounded-full flex place-content-center place-items-center">
                                    <img alt="betrokken organisatie" className="max-h-[40px]" src="/assets/images/icon-pink-organisation.svg"/>
                                </div>
								<div className="pl-4 md:pl-4">Betrokkken organisatie; je bent bij ons geen nummer</div>
							</li>
							<li className="py-1 lg:py-0 flex items-center">
								<div className="w-[50px] min-w-[50px] h-[50px] bg-white p-1 rounded-full flex place-content-center place-items-center">
                                    <img alt="duim omhoog" className="max-h-[40px]" src="/assets/images/icon-pink-thumbup.svg"/>
                                </div>
								<div className="pl-4 md:pl-4">Iedereen wordt gehoord, gezien en gewaardeerd</div>
							</li>
							
						</ul>
						<div className="mt-4 lg:mt-0 md:block">
							<ApplyJobButton className=" md:mt-4 md:inline-block" />
						</div>
					</div>
				</div>
			</div>
		</div>


        <div className="md:container-fluid mt-0 lg:mt-16">
			<div className="container mx-auto p-8 lg:p-0">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-16">
                    <div>
                        <h2 className="text-3xl text-pink">Vacature leerling kraamverzorgende - Werken en leren bij Kraamzorg Mama</h2>
                        <p className="mt-4 text-xl">
                            Uiteraard zoeken wij voor deze prachtige groeiende organisatie ook mensen die gedreven zijn het vak van kraamverzorgende te leren. Ben jij van nature een verzorgend type en spreekt de geboortezorg je aan? Kraamzorg Mama biedt jou nu de kans om opgeleid te worden tot Kraamverzorgende in een leer/werktraject. Als Kraamverzorgende ga jij een gezin zo goed mogelijk op weg helpen met een nieuw kindje, zodat het vanaf de eerste dag helemaal goed gaat. Jij zet alles op alles om onze overtuigingen waar te maken: Jouw huis, jouw kindje, jouw kraamtijd!
                        </p>
                    </div>
                    <div>
                    <div className="relative">
							<video controls="controls" className="object-scale-down"  id="kzmvideotv" title="Kraamzorg Mama">
								<source src="/assets/video/werken-bij-kraamzorgmama.mp4" />
							</video>
						</div>
                    </div>
                </div>
            </div>
        </div>

        <div className="md:container-fluid mt-0 lg:mt-16">
			<div className="container mx-auto p-8 lg:p-0">
                <p className="mt-4 text-xl">
                    Heb je géén of een bescheiden achtergrond in de zorg, maar wil je wél heel graag kraamverzorgende worden? Of ben je op latere leeftijd, en overweeg je een 180 graden carrièreswitch? Woon je daarnaast in of vlakbij Rotterdam, Den Haag, Utrecht of Breda? En heb je (minstens) een vmbo-diploma, of een overgangsbewijs voor havo/vwo 3 naar havo/vwo 4 op zak? Dan biedt Kraamzorg Mama jou nu de kans om die move te maken, door middel van een aantrekkelijke leerwerkovereenkomst!
                </p>
                 <h3 className="text-2xl mt-4 text-pink">Duur van de opleiding</h3>
                <p className="mt-4 text-xl">
                    Afhankelijk van je vooropleiding en opleidingsinstantie kan de opleiding tussen de 18 en 24 maanden duren. In deze maanden ga je afwisselend naar school en naar werk. De opleiding start in januari!
                </p>
                <h3 className="text-2xl mt-4 text-pink">Skillslab</h3>
                <p className="mt-4 text-xl">
                    Kraamzorg Mama heeft speciaal voor de verwezenlijking van deze educatieve doelstellingen een werkruimte als skillslab ingericht. Het onderwijslokaal laat zich het best omschrijven als een (bijzonder) grote en ultramoderne praktijkruimte waarin, naast relevant en professioneel onderwijsmateriaal, de nieuwste hulpmiddelen zijn geplaatst en geïnstalleerd. Van babybadjes tot (medische) hulp- en leermiddelen. Van houten ledikanten tot een elektrisch bedienbaar ziekenhuisbed. Van (bevallings- en borst)simulatoren tot meerdere anatomische verzorgings- en bevallingspoppen (oefenpoppen). In ons high-end skillslab kan, zonder énige overdrijving, de meest complexe casussen en praktisch iedere werksituatie worden nagebootst.
                </p>
                <h3 className="text-2xl mt-4 text-pink">Quotum</h3>
                <p className="mt-4 text-xl">
                    Vanwege de aanwezigheid van een unieke Skillslab zijn onze leerwerktrajecten zeer gewild. Iedere (leerling-)kraamverzorgende die onze hoofdlocatie bezoekt, in het bijzonder ons high-end skillslab, kijkt haar ogen uit. Door een quotum (een maximum aantal aan te nemen studenten) in te stellen waarborgen wij ook de kwaliteit van de opleiding. Ben je door onze selectiecriteria heen gekomen, en kom je als gevolg daarvan in aanmerking voor een leerwerktraject? Besef dan dat je op een buitengewone wijze op de praktijk gaat worden voorbereid!
                </p>
                <h3 className="text-2xl mt-4 text-pink">Toelatingseisen</h3>
                <p className="mt-4 text-xl">
                    Kraamzorg Mama stelt leerwerkplekken beschikbaar voor studenten van branchegerichte (kraam)opleidingen. Je wordt echter niet zomaar toegelaten: er gelden strikte voorwaarden. Voldoe je hier niet aan dan wordt jouw sollicitatie niet in behandeling genomen. Daarnaast geldt er, zoals eerder vermeld, een quotum. Zo houden wij de onderwijskwaliteit op peil.
                </p>
            </div>
        </div>

        <div className="md:container-fluid">
			<div className="container mx-auto mt-8 pt-0 md:mt-16  bg-gray-design rounded-xl">				
				<div className="flex flex-col lg:flex-row">
					<div className="lg:w-2/4 px-4 lg:px-0 py-8 lg:py-16 lg:pr-8 lg:pl-8 order-2 lg:order-1">
						<h3 className="font-ro-sans text-2xl md:text-3xl pb-4 lg:pb-4 font-bold leading-none">Wat kun je van ons verwachten?</h3>
                        <ul className="text-xl font-ro-sans ">
                            <li className="py-2 flex">
                                <div className="min-w-[40px] pt-1"><img className="w-7" alt="vinkje" src="/assets/images/icon-form-good.svg"/></div>
                                <div className="pl-1 md:pl-1">
                                    Tijdens je opleiding ontvang je een leerlingsalaris conform de CAO Kraamzorg;
                                </div>
                            </li>
                            <li className="py-2 flex">
                                <div className="min-w-[40px] pt-1"><img className="w-7" alt="vinkje" src="/assets/images/icon-form-good.svg"/></div>
                                <div className="pl-1 md:pl-1">
                                    Je krijgt een min-max contract van 60 - 90 uur per maand;
                                </div>
                            </li>
                            <li className="py-2 flex">
                                <div className="min-w-[40px] pt-1"><img className="w-7" alt="vinkje" src="/assets/images/icon-form-good.svg"/></div>
                                <div className="pl-1 md:pl-1">
                                    Schooluren (de lesuren op dagen waarop je theorieles volgt) worden conform CAO vergoed als werkuren;
                                </div>
                            </li>
                            <li className="py-2 flex">
                                <div className="min-w-[40px] pt-1"><img className="w-7" alt="vinkje" src="/assets/images/icon-form-good.svg"/></div>
                                <div className="pl-1 md:pl-1">
                                    Je wordt intensief begeleid door ervaren kraamverzorgenden;
                                </div>
                            </li>
                            <li className="py-2 flex">
                                <div className="min-w-[40px] pt-1"><img className="w-7" alt="vinkje" src="/assets/images/icon-form-good.svg"/></div>
                                <div className="pl-1 md:pl-1">
                                    Je krijgt een praktijkbegeleider toegewezen die jou helpt met het behalen van je leerdoelen;
                                </div>
                            </li>
                            <li className="py-2 flex">
                                <div className="min-w-[40px] pt-1"><img className="w-7" alt="vinkje" src="/assets/images/icon-form-good.svg"/></div>
                                <div className="pl-1 md:pl-1">
                                    Behaal je jouw diploma, en functioneer je nadien volgens de doelstellingen van onze organisatie? Dan kom je na de tijdelijke arbeidsovereenkomsten in aanmerking voor een vast contract;
                                </div>
                            </li>
                            <li className="py-2 flex">
                                <div className="min-w-[40px] pt-1"><img className="w-7" alt="vinkje" src="/assets/images/icon-form-good.svg"/></div>
                                <div className="pl-1 md:pl-1">
                                    Je kunt gebruik maken van de roze leaseauto van Kraamzorg Mama;
                                </div>
                            </li>
                            <li className="py-2 flex">
                                <div className="min-w-[40px] pt-1"><img className="w-7" alt="vinkje" src="/assets/images/icon-form-good.svg"/></div>
                                <div className="pl-1 md:pl-1">
                                    Ga je als volwaardige kraamverzorgende voor ons aan de slag? Dan kun je kiezen uit verschillende functieprofielen. Zo is het mogelijk om te gaan werken via een vast rooster waarin je standaard 8 dagen werkt en 4 of 6 dagen vrij bent. Je kunt ook de voorkeur geven aan een flex-functie waarbij je in overleg met de planning je werkdagen en vrije dagen bepaalt. Er is bij Kraamzorg Mama dus van alles mogelijk!
                                </div>
                            </li>
					    </ul>

					</div>
					<div className="lg:py-16 lg:pl-8 lg:pr-8 order-1 lg:order-2 md:self-center">
						<img alt="ervaren" className="hidden lg:block" src="/assets/images/intakekraamtijd-image-eenervaren.png" />
                        <img alt="ervaren" className="block lg:hidden" src="/assets/images/intakekraamtijd-image-eenervaren-sm.png" />
					</div>

				</div>



			</div>
		</div>            

        <div className="md:container-fluid mt-0 lg:mt-16 lg:mb-16">
			<div className="container mx-auto p-4 lg:p-8 lg:p-0">
                <div className="grid grid-cols-1 lg:grid-cols-2">
                    <div className=""> 
                        <h2 className="text-3xl text-pink">Wat verwachten wij van jou?</h2>
                        <ul className="mt-4 text-xl font-ro-sans ">
                            <li className="py-2 flex">
                                <div className="min-w-[40px] pt-1"><img className="w-7" alt="vinkje" src="/assets/images/icon-form-good.svg"/></div>
                                <div className="pl-1 md:pl-1">
                                    Je beschikt over een rijbewijs;
                                </div>
                            </li>
                            <li className="py-2 flex">
                                <div className="min-w-[40px] pt-1"><img className="w-7" alt="vinkje" src="/assets/images/icon-form-good.svg"/></div>
                                <div className="pl-1 md:pl-1">
                                    Je hebt een mbo-opleiding afgerond op niveau 2 of 3 (bij voorkeur met een zorgachtergrond) of een afgeronde middelbare schoolopleiding met minimaal vmbo theoretische leerweg;
                                </div>
                            </li>
                            <li className="py-2 flex">
                                <div className="min-w-[40px] pt-1"><img className="w-7" alt="vinkje" src="/assets/images/icon-form-good.svg"/></div>
                                <div className="pl-1 md:pl-1">
                                    Je bent flexibel inzetbaar, 7 dagen per week: overdag, in de avond en in de nacht (een zorgweek duurt minimaal 8 en maximaal 10 dagen, achtereenvolgend);
                                </div>
                            </li>
                            <li className="py-2 flex">
                                <div className="min-w-[40px] pt-1"><img className="w-7" alt="vinkje" src="/assets/images/icon-form-good.svg"/></div>
                                <div className="pl-1 md:pl-1">
                                    Je bent een sociaal persoon, en beschikt over voldoende inlevingsvermogen;
                                </div>
                            </li>
                            <li className="py-2 flex">
                                <div className="min-w-[40px] pt-1"><img className="w-7" alt="vinkje" src="/assets/images/icon-form-good.svg"/></div>
                                <div className="pl-1 md:pl-1">
                                    Je hebt een gedreven en proactieve instelling (je hebt passie voor het vak);
                                </div>
                            </li>
                            <li className="py-2 flex">
                                <div className="min-w-[40px] pt-1"><img className="w-7" alt="vinkje" src="/assets/images/icon-form-good.svg"/></div>
                                <div className="pl-1 md:pl-1">
                                    Je bent de Nederlandse taal machtig in woord en geschrift;
                                </div>
                            </li>
                            <li className="py-2 flex">
                                <div className="min-w-[40px] pt-1"><img className="w-7" alt="vinkje" src="/assets/images/icon-form-good.svg"/></div>
                                <div className="pl-1 md:pl-1">
                                    Je bent je ervan bewust dat het volwassenenonderwijs betreft. Je bent dus zélf verantwoordelijk voor het behalen van je leerdoelen;
                                </div>
                            </li>
                            <li className="py-2 flex">
                                <div className="min-w-[40px] pt-1"><img className="w-7" alt="vinkje" src="/assets/images/icon-form-good.svg"/></div>
                                <div className="pl-1 md:pl-1">
                                    Je bent bereid om de schoolkosten, het boekengeld en de reiskosten (van en naar de onderwijsinstelling) zélf te bekostigen. Afhankelijk van de gekozen opleidingsvariant variëren de opleidingskosten tussen de 2500,- en de 3.500,- euro. Het is overigens mogelijk om deze opleiding in termijnen te betalen.
                                </div>
                            </li>
                        </ul>
                        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 text-lg mt-4">
                            <div>
                                <Link target="_blank" to="//wa.me/+31634103693" className={`text-center text-white px-4 py-3 bg-pink rounded-lg font-bold flex md:block hover:bg-black`}>
                                    <img src="/assets/images/request-button-icon.svg" alt="verder" className="md:inline md:pr-2"/>
                                    <span className="flex-auto text-center lg:text-left">Whatsapp ons</span>
                                </Link>
                            </div>  
                            <div>
                                <ApplyJobButton className="text-center" />
                            </div>                                        
                        </div>
                    </div>
                    {/* <div className="text-lg ">
                        <div className="flex-col flex justify-end ">
                            <div className="pb-4 lg:pl-16">
                               
                            </div>
                            <div className="flex bg-yellowish border-l-8 border-[#FCE66C] mt-4 lg:mt-8 lg:w-4/5 h-min self-center">
                                <div className="p-4">
                                    <img className="w-16 lg:w-10"  alt="notificatie" src="/assets/images/header-notice-icon.svg" /> 
                                </div>
                                <div className="p-4 pl-0">
                                    <div className="lg:text-xl font-bold">Verwachte startdatum: {(() => {
                                        let date = moment();
                                        date.endOf('month');
                                        return date.format('DD-MM-YYYY');
                                    })()}</div>
                                    <p className="lg:text-lg mt-1">
                                        Voldoe jij aan bovenstaande criteria? Wacht dan niet langer met solliciteren, want wij
                                        zijn per direct op zoek naar jouw?
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>                     
        <Footer />
		<Cookie />
    </Fragment>
  );
}


import { useState } from 'react';
export const _reviews = [
	{
		name: 'Regina Singh', page: 'home',
		type: 'google',
		picture: 'regina-singh.png',
		stars: 5,
		link: 'https://www.google.com/maps/contrib/107912902226401581138?hl=nl-NL&sa=X&ved=2ahUKEwjBg8393Mz1AhV3h_0HHVKeDn0QvvQBegQIARAy',
		text: 'Wij hebben voor het eerst kraamzorg Mama gehad, jammer dat ik ze niet bij mijn andere kinderen had. Wat een fijne ervaring heb ik gehad met Diana. Zorgzaam, een luisterend oor, goed met mijn andere kinderen en fantastisch voor de baby. Zal kraamzorg Mama altijd bij anderen aanbevelen, mede dankzij de onvergetelijke ervaring met Diana! Kraamzorg Mama en Diana wij zeggen enorm bedankt en ga vooral zo door!'
	},
	{
		name: 'Jewel Busscher',  page: 'home',
		type: 'google',
		picture: 'jewel-busscher.png',
		stars: 5,
		link: 'https://www.google.com/maps/contrib/104236694343605857753?hl=nl-NL&sa=X&ved=2ahUKEwi_042A3cz1AhU_h_0HHVfeBsgQvvQBegQIARAP',
		text: 'Onze kraamweek was echt super dankzij Shirley. We hebben echt super veel handige informatie van haar gehad en het was heel fijn om haar hulp in het algemeen te hebben. Ze was er niet alleen voor de kraamzorg maar was ook geïnteresseerd in ons waardoor we een goede band met elkaar op konden bouwen. Echt super!!!'
	},
	{
		name: 'Emma de Wit',  page: 'home',
		type: 'google',
		picture: 'emma-de-wit.png',
		stars: 5,
		link: 'https://www.google.com/maps/contrib/110367392513135679991?hl=nl-NL&sa=X&ved=2ahUKEwjzwJuI3cz1AhWbiv0HHVp0B2cQvvQBegQIARBa',
		text: 'Fijne kraamweek gehad dankzij kraamzorg Mama. Onze kraamhulp Asha was fantastisch. Ze heeft goede adviezen gegeven, ons goed op weg geholpen en onze dochter van 3 was gek op haar! Heel fijn ook dat kraamzorg Mama de beloofde uren ook werkelijkheid maken.'
	},
	{
		name: 'Sarah Krul',  page: 'home',
		type: 'google',
		picture: 'sarah-krul.png',
		stars: 5,
		link: 'https://www.google.com/maps/contrib/105873829947496334615?hl=nl-NL&sa=X&ved=2ahUKEwjsh5793Mz1AhV8lf0HHWnqC6oQvvQBegQIARB0',
		text: 'Wij hebben een hele fijne kraamweek gehad met Mischa en Daphne! Ons kindje wilde moeilijk/niet aan de borst en ik heb hierbij hele goede hulp gehad. Er was veel geduld en rust, zo kon ik ook ontspannen. Verder veel goede tips en informatie gehad en ook hadden we een hele leuke persoonlijke klik! We kijken heel prettig terug op deze week. Mocht er een volgend kindje komen dan hoop ik een van hun weer te zien!'
	},
	{
		name: 'Jessica van Walsum',  page: 'home',
		type: 'google',
		picture: 'jessica-van-walsum.png',
		stars: 5,
		link: 'https://www.google.com/maps/contrib/118161299531981770829?hl=nl-NL&sa=X&ved=2ahUKEwi-teu81cz1AhWH7rsIHcZDDakQvvQBegQIARBI',
		text: 'Chantal heeft ons gezin tijdens de kraamperiode heel goed geholpen. Ze is een gezellige, maar rustige persoonlijkheid. Ze heeft veel kennis en kan dit op een prettige manier overbrengen. Chantal is geduldig, behulpzaam, empathisch, flexibel en pro-actief, niets is teveel voor haar. Zowel voor onze pasgeboren baby als voor onze peuter was ze heel lief (en voor ons ook!).'
	},
	{
		name: 'Zina Rwina',  page: 'home',
		type: 'google',
		picture: 'zina-rwina.png',
		stars: 5,
		link: 'https://www.google.com/maps/contrib/104906819770305454510?hl=nl-NL&sa=X&ved=2ahUKEwi_042A3cz1AhU_h_0HHVfeBsgQvvQBegQIARBS',
		text: 'Ik heb zo erg genoten van deze kraamtijd dat was niet normaal me eerste kindje en beter dan dit had het niet kunnen zijn ik raad iedereen ook zeker aan vraag om NANDA!! De liefste en serieus de beste echt zonder haar had ik dit niet gekunt ik ben haar dankbaar en dat voor altijd !!!!!!! LOVE IT 💖💖'
	},
	{
		name: 'José Boekelmans',  page: 'home',
		type: 'google',
		picture: 'jose-boekelmans.png',
		stars: 5,
		link: 'https://www.google.com/maps/contrib/108088767319940884211?hl=nl-NL&sa=X&ved=2ahUKEwj0vMjR1sz1AhUMg_0HHcLFC9QQvvQBegQIARAD',
		text: 'Onze kraamweek begon met een mindere start maar sinds Mioma voor de deur stond kwam de rust terug en hebben we optimaal genoten van de kraamzorg. Ik zelf was al new mommy erg gestrest en onzeker maar dankzij Mioma heb ik vertrouwen in de toekomst als ouders van onze lieve Mees. Lieve Mioma, ontzettend bedankt. We hopen je over een aantal jaar weer te zien bij een eventuele volgende spruit❤'
	},
	{
		name: 'Marleen Das',  page: 'home',
		type: 'google',
		picture: 'marleen-das.png',
		stars: 5,
		link: 'https://www.google.com/maps/contrib/107114702543216151856?hl=nl-NL&sa=X&ved=2ahUKEwiiz5H-28z1AhUhiP0HHZ6WCCQQvvQBegQIARAO',
		text: 'Wat een super ervaring hebben wij gehad bij Kraamzorg Mama. We maakten ons een beetje zorgen over ons kleine huisje en of de kraamhulp zich wel thuis zou voelen. Eenmaal terug uit het ziekenhuis stond daar Jeannette. Een opgewekte vrolijke en krachtige vrouw, die ons vanaf moment een al een vertrouwd gevoel gaf. We hebben veel met elkaar kunnen lachen en daarnaast was het ook een super leerzame week voor ons. Jeannette kon ons alles vertellen over het geven van borstvoeding en heeft ons daar super in ondersteund. Hoe lastig zo\'n opstart soms ook kan zijn... Daarnaast werden wij als kersverse ouders heerlijk in de watten gelegd. Lunch, fruithapjes, allerlei klusjes in huis die om ons heen werden uitgevoerd... we missen je wel een beetje nu ;) Ook wil ik nog even zeggen dat ik het erg knap vind hoe Jeannette echt keek naar ons als gezin en daarop inspeelde. Ze wist wat goed was voor de baby, maar verloor ons daarin niet uit het oog. Bedankt Jeannette en bedankt Kraamzorg Mama!'
	},
	{
		name: 'kim de vogel', 
		type: 'google',
		picture: 'kim-de-vogel.png',
		stars: 5,
		link: 'https://www.google.com/maps/contrib/102880972749828977909?hl=nl-NL&sa=X&ved=2ahUKEwjTya773Mz1AhWwh_0HHSVnCmgQvvQBegQIARBm',
		text: 'Ik heb een hele fijne kraamzorg gehad, door alle hectiek heen heeft zij mij het gevoel gegeven dat er ook rust in de tent kwam. Goede communicatie en de kraamverzorgster was flexibel en paste zich aan aan ons gezinnetje. Veel goede tips gehad zodat ik nu zelfverzekerd verder kan. Top ervaring bij Kraamzorg mama!'
	},
	{
		name: 'Lisa A', 
		type: 'google',
		picture: 'lisa-a.png',
		stars: 5,
		link: 'https://www.google.com/maps/contrib/106577558886018713718?hl=nl-NL&sa=X&ved=2ahUKEwi9oO_83Mz1AhU98rsIHQaoAT4QvvQBegQIARBj',
		text: 'Super ervaring met Sylvia ❤️. Heel erg lief voor de kinderen, en ze heeft schandalig lekker gekookt voor ons. Sylvia heeft veel voor ons betekent na een hele vervelende ervaring met een ander bureau. Ze heeft voor mij zeker weer het vertrouwen in kraamzorg weten te herstellen. Echt super lief en vond het verschrikkelijk dat ze weg ging. Gelukkig houden we nog contact👶🏻❤️'
	},
	{
		name: 'Marja Kemp', 
		type: 'google',
		picture: 'marja-kemp.png',
		stars: 5,
		link: 'https://www.google.com/maps/contrib/102486379153789467562?hl=nl-NL&sa=X&ved=2ahUKEwjsh5793Mz1AhV8lf0HHWnqC6oQvvQBegQIARBc',
		text: 'Super lieve, fijne kraamzorg gehad van Ellen! Wellicht zonder baby (prematuur dus tijdens de kraamweek nog in het ziekenhuis). Elke ochtend gezellig en vrolijk, luisterde goed en had altijd tips. Zeer tevreden. Marja Kemp.'
	},
	{
		name: 'Emel Demirova', 
		type: 'google',
		picture: 'emel-demirova.png',
		stars: 5,
		link: 'https://www.google.com/maps/contrib/104893309983165594468?hl=nl-NL&sa=X&ved=2ahUKEwiDwNCA3cz1AhW5lP0HHUyoCY4QvvQBegQIARAx',
		text: 'Een top ervaring gehad met Anita en Anita M 🤗 dankzij jullie hulp, informatie en steun als kraamverzorgsters kon ik na de bevalling weer bijkomen en mijn zelfvertrouwen opbouwen als nieuwe moeder. Erg leuke gezellige meelevende en behulpzaamste dames. Ik kan jullie niet genoeg bedanken voor de steun en hulp en informatie wat jullie mij hebben aangeboden 💙'
	},
	{
		name: 'Miranda Vd maarel', 
		type: 'google',
		picture: 'miranda-vd-maarel.png',
		stars: 5,
		link: 'https://www.google.com/maps/contrib/105038740813610937280?hl=nl-NL&sa=X&ved=2ahUKEwjczoKC3cz1AhWOlP0HHXQLAC8QvvQBegQIARAD',
		text: 'Nadat onze zoon 2 weken in het ziekenhuis had gelegen kwam de liefste kraamverzorgster Cindy B bij ons binnen lopen. Wat een lief, fijn mens en wat was het fijn om toch nog handige tips te krijgen en verzorgd te worden. Ze dacht met ons mee en kwam zelfs op haar vrije dag nog even langs om onze zoon te wegen!'
	},
	{
		name: 'Shadon Bindraban', 
		type: 'google',
		picture: 'shadon-bindraban.png',
		stars: 5,
		link: 'https://www.google.com/maps/contrib/101109115215282725851?hl=nl-NL&sa=X&ved=2ahUKEwj47bSg18z1AhUAlP0HHQ8-Cv0QvvQBegQIARAm',
		text: 'Voor Merian, beste kraamverzorgster! Zorgzaam,liefdevol en zo betrokken. Ik zou echt geen ander willen!!!! Bij mn eerste kindje en nu al tweede kindje ! Dankjewel Merian !!!!!!'
	},
	{
		name: 'Yentl Westerhout', 
		type: 'google',
		picture: 'yentl-westerhout.png',
		stars: 5,
		link: 'https://www.google.com/maps/contrib/105373732743449002341?hl=nl-NL&sa=X&ved=2ahUKEwj47bSg18z1AhUAlP0HHQ8-Cv0QvvQBegQIARBe',
		text: 'ik ben ontzettend warm en lief verzorgd door eveline.. ik zou iedereen zo’n fijne week gunnen na een bevalling. Ze is niet alleen warm en lief maar ook erg begaan en weet precies wat je nodig heeft maar ook haar informatie en hulp waren super. Ik ben fijn begeleid in de meest belangrijkste week van het leven van mijn kindje we zijn niks te kort gekomen en hebben het als super fijn ervaren. maar ook mijn gezin dus mijn andere 2 kindjes en man waren erg blij met haar. Ik zou mijn omgeving zeker aanbevelen om zich hier aan te melden als je een fijne en mooie kraamweek zou willen.'
	},
	{
		name: 'Isis Hommema', 
		type: 'google',
		picture: 'isis-hommema.png',
		stars: 5,
		link: 'https://www.google.com/maps/contrib/115529134496169942461?hl=nl-NL&sa=X&ved=2ahUKEwiKxL2H3cz1AhVjhf0HHaDOA5kQvvQBegQIARBg',
		text: 'We hebben een heerlijke kraamweek gehad met onze kraamhulp Tamara. Toen we gebeld hadden dat we naar huis mochten stond zij al snel voor de deur en heeft ons deze week super geholpen met alles voor onze dochter en in het huis. Ze leerde ons alles wat we moeten weten over onze kleine meid, waardoor we nu met vertrouwen goed voor haar kunnen zorgen. Tamara zorgde daarnaast voor het schoonhouden van het huis, klaarmaken van ontbijt en lunch, en we konden iedere middag nog wat uurtjes slaap inhalen, terwijl de baby bij haar in veilige handen was. Heerlijk, dankjewel voor de fijne week Tamara!'
	},
	{
		name: 'Vicky van der Bijl', page: 'kraamverzorgende',
		type: 'google',
		picture: 'vicky-van-der-bijl.png',
		stars: 5,
		link: 'https://www.google.com/maps/contrib/104905955793992961380?hl=nl-NL&sa=X&ved=2ahUKEwitifOK3cz1AhV5h_0HHX_YA60QvvQBegQIARBt',
		text: 'Wij zijn super blij met onze keuze voor Kraamzorg Mama. Vakbekwaam en goede communicatie bij de intake en het contact met de planning. Onze kraamweek hebben wij dan ook super afgesloten. Miranda heeft ons ontzettend geholpen en we hebben een geweldige kraamweek gehad. Vol enthousiasme, gezelligheid, tips en goede adviezen heeft zij ons de week door geholpen en ervoor gezorgd dat wij met vertrouwen de week af konden sluiten. Een volgende keer kiezen wij zeker weer voor Kraamzorg Mama en hopen wij dat Miranda weer kan komen om de zorg te verlenen.'
	},
	{
		name: 'Daniël Vercouteren', 
		type: 'google',
		picture: 'daniel-vercouteren.png',
		stars: 5,
		link: 'https://www.google.com/maps/contrib/109261572708024237064?hl=nl-NL&sa=X&ved=2ahUKEwjF2MKN3cz1AhU0lP0HHSwvAswQvvQBegQIARA8',
		text: 'Afgelopen week een fantastische kraamweek gehad, Corrie heeft ons perfecte begeleiding gegeven na een aantal dagen in het ziekenhuis. Het is fijn om iemand in huis te hebben die goed weet wat ze doet, de tijd neemt om alle kneepjes, tips en tricks aan ons te leren en ondertussen ook zorg draagt voor het huishouden. We hebben enorm genoten van de kraamweek en kunnen iedereen Kraamzorg Mama aanraden. De begeleiding vanuit Kraamzorg Mama, inclusief tussentijdse evaluatie van de kraamverzorgster en afrondend gesprek tonen aan dat Kraamzorg Mama er alles aan doet om een goede kraamperiode aan te bieden.'
	},
	{
		name: 'K Marina', page: 'kraamverzorgende',
		type: 'google',
		picture: 'k-marina.png',
		stars: 5,
		link: 'https://www.google.com/maps/contrib/113643305548690684588?hl=nl-NL&sa=X&ved=2ahUKEwjQ1pLIhs31AhXfif0HHcE1B8MQvvQBegQIARAb',
		text: 'Wij hebben de kraamzorg Mama als zeer prettig en goed ervaren! Als kraamverzorgster hebben wij Ellen gehad en wat mag je werkgever toch trots zijn met zo\'n werknemer. Wij willen jou uit de grond van ons hart bedanken, en zullen specifiek vragen om jouw voor ons volgende kindje😀 Je eigengemaakte presentje waarderen we heel erg!'
	},
	{
		name: 'Anouk', page: 'borstvoeding',
		type: 'google',
		picture: 'anouk.png',
		stars: 5,
		link: 'https://www.google.com/maps/contrib/102035774454837505110?hl=nl-NL&sa=X&ved=2ahUKEwijsqrRiM31AhUpi_0HHUUpBh8QvvQBegQIARAn',
		text: 'Na een wat lastige start in het ziekenhuis, thuis hele fijne hulp gehad van Ellen! Ze heeft ons handige tips gegeven en heel fijn ondersteunt in alles. Ellen is dag 9 en 10 gebleven om nog extra te helpen en te ondersteunen met de borstvoeding. Ellen weet van aanpakken en alles is spik en span achter gelaten. Ze heeft een gezellige babbel en het klikte met ons erg goed, ze houdt van een geintje en kon onze humor waarderen. Ze bakt de lekkerste pannekoeken! We vonden het heel jammer dat haar tijd bij ons erop zat. Ellen was echt een hele fijn kraamhulp en wij zullen zeker Kraamhulp Mama bij andere aanraden!'
	},
	{
		name: 'Linny Spankielee', page: 'kraamverzorgende',
		type: 'google',
		picture: 'linny-spankielee.png',
		stars: 5,
		link: 'https://www.google.com/maps/contrib/100634866041022198570?hl=nl-NL&sa=X&ved=2ahUKEwjXkuTKjM31AhWoiv0HHa3aDcYQvvQBegQIARBj',
		text: 'Shirley...in 1 woord GEWELDIG...ze heeft 3 van mijn 9 kids mogen verzorgen na de bevalling. En ook ik en de rest van ons gezin. En met zoveel kids is dat niet niets. Als ik had mogen kiezen had ik haar ook bij onze andere kinderen willen hebben (helaas waren die toen al geboren) Ze heeft ook bij mijn dochter en kleindochter gekraamd. Ze is lief zorgzaam en luistert ook echt naar je. Iets wat een kraamvrouw graag wilt. Af en toe mis ik haar gewoon nog hier hahaha had ze maar kunnen blijven. Liefs Linda Spangenberg'
	},
	{
		name: 'Silvana Van veen', 
		type: 'google',
		picture: 'silvana-van-veen.png',
		stars: 5,
		link: 'https://www.google.com/maps/contrib/115108732164321426245?hl=nl-NL&sa=X&ved=2ahUKEwit0rainc31AhXnlP0HHXXaBz8QvvQBegQIARAD',
		text: 'We hebben een heerlijke kraamweek gehad met Marjolein! Ze had goede tips, gaf je de ruimte, en bied een luisterend oor. Na een moeilijke kraamtijd na onze eerste zoon was dit een wereld van verschil. Het was fijn mijn hart af en toe te kunnen luchten en te kunnen praten over mijn mentale gezondheid. Ook onze oudste zoon was dol op haar en vond het maar wat leuk als hij mocht kijken of helpen. Mocht er ooit een derde kindje komen, willen wij absoluut weer Marjolein! Wat een topper!'
	},
	{
		name: 'Sibel Sibel', 
		type: 'trustpilot',
		picture: 'sibel-sibel.png',
		stars: 5,
		link: 'https://nl.trustpilot.com/users/61eb3b5d1666870012958c5e',
		text: 'Het voelde vertrouwd inmiddels al een tijdje geleden bevallen,maar wil toch mijn beoordeling geven voor de onvergetelijke week met de kraamverzorgende asha! ik was bevallen van mijn vierde kindje waardoor ik iets minder vragen had voor haar,maar ze vroeg regelmatig of ze nog iets kon doen of eventueel nog iets kon opschrijven zodat het mij bij bleef als ze er niet meer zou zijn...ik gaf bijvoorbeeld borstvoeding en wist dus eigenlijk niet meer hoelang flesvoeding/of afgekolfde melk uit fles mee ging na het opwarmen ervan..dat schreef ze dan voor mij op in het schrift,zodat ik het later nog eens kon bekijken. Het voelde heel erg vertrouwd en kon heerlijk mezelf zijn bij haar. daarom schrijf ik nu toch nog even mijn beoordeling, zodat elke nieuwe mama kan genieten van de goede zorgen die ik ook heb gekregen :) Liefs Sibel.'
	},
	{
		name: 'Raehana van den Bosch', 
		type: 'trustpilot',
		picture: 'raehana-van-den-bosch.png',
		stars: 5,
		link: 'https://nl.trustpilot.com/users/61eadfd82a66bd00131a689f',
		text: 'Dankbaarheid en waardering!!! Na een prachtig zwangerschap en super mooie bavalling werd het hele process afgesloten met een geweldige kraamtijd. Ik heb het echt getroffen met Asha Burgers. De kraamtijd is zo\'n belangrijk moment in je leven. Asha heeft zoveel moois gebracht met haar behulpzaamheid, haar adviezen en inzichten. Woorden kunnen niet beschrijven hoe dankbaar ik ben dat Asha op mijn pad is gekomen. Heel proffesioneel maar ook zeer betrokken waardoor het contact persoonlijker was. Dat vond ik fijn. Asha je bent een topper. Bedankt voor wie je bent. Ik draag mijn kraamtijd voor altijd diep mee in mijn hart!!!'
	},
	{
		name: 'David Hakopjan', 
		type: 'trustpilot',
		picture: 'david-hakopjan.png',
		stars: 5,
		link: 'https://nl.trustpilot.com/users/61e9215349b2af00147f2ba2',
		text: 'Ik ben erg tevreden over kraamzorg… Ik ben erg tevreden over kraamzorg mama. We hadden goede hulp van Mischa. Ze hielp mij goed met verzorgen van baby en mijn gezin. Ze ondersteunde mij ook bij het huishouden. Wat ik er prettig vond omdat ik toch een groot gezin heb. Ze gaf goede adviezen en veel informatie. Mocht er in de toekomst nog een baby komen vragen we zeker Mischa weer. Bedankt voor alles. Groeten van Tamara Artenyants'
	},
	{
		name: 'Wendy Droefs - Vervenne', 
		type: 'trustpilot',
		picture: 'wendy-droefs-vervenne.png',
		stars: 5,
		link: 'https://nl.trustpilot.com/users/5efcce13bf25036acf88ad67',
		text: 'Twee maal dezelfde kraamverzorgster Asha, een topper! Asha is voor de tweede keer bij ons wezen kramen. Echt een topper! Nu bij de tweede wondertje is ze bij ons bij het Ronald mac donalshuis geweest en thuis. Ze helpt je niet alleen met de baby, maar is ook een luisterende oor. In overleg welke taken je graag gedaan wilt hebben in huis, voert ze deze uit. Nogmaals dankjewel lieve Asha! Ook het contact met het kantoor voorliep goed en soepel.'
	},
	{
		name: 'Joanna Kopeć', 
		type: 'trustpilot',
		picture: 'joanna-kopec.png',
		stars: 5,
		link: 'https://nl.trustpilot.com/users/61d7889d0ab79100127d591f',
		text: 'Wat een geweldig ervaring. Wat een geweldig ervaring. Als net geworden ouders, niet alleen van onze eerste kindje maar ook nog van kleintje die 3 weken eerder ten wereld kwam, wisten we niks van hoe je moet omgaan met een Newborn. Het was zeer spannend voor mij en mijn partner om na de zware bevalling en anderhalf dag in ziekenhuis maar onze huis te gaan met de kleine wonder van ons. Gelukkig we hebben een geweldig kraamzorg gekregen. Cindy B. heeft niet alleen aan ons goed uitgelegd hoe we met de kleine moeten omgaan maar ook heel goed voor mij en mijn partner gezorgd en gesteund in zware momenten. En niet alleen wij vonden het prettig om haar in huis hebben. Onze 2 katten vonden Cindy ook heel leuk vanaf de eerste dag. Ze is veel meer geworden voor ons dan een kraamzorg en we zijn ontzettend dankbaar voor alles. Mochten we nog een kindje krijgen gaan we zeker bij kraamzorg mama vragen of Cindy bij ons weer mag komen. Kraamzorg Mama is geweldig organisatie met vriendelijke, leuke, gezellige en fantastische mensen. Zeker een aanrader voor iedereen.'
	},



 ]


export default function Reviews({ disableNavigation, reviewClassName, filterPage }) {

	let reviews = _reviews.map(x => ({...x, picture: `/assets/reviews/${x.picture}`}));
	if(filterPage) {
		reviews = reviews.filter(x => x.page == filterPage);
	}


	const reviewAmount = 6;
	const [currentReview, setCurrentReview] = useState(0);
	const [touchX, setTouchX] = useState([0,0]);
	const [swipedRight, setSwipedRight] = useState(0);

	const nextReview = () => {
		setCurrentReview((currentReview + 1) % reviewAmount)
		setSwipedRight(1);
	}

	const previousReview = () => {
		setSwipedRight(0);
		setCurrentReview((currentReview - 1 + reviewAmount) % reviewAmount)
	}

	const swipeStart = (evt) => {
		setTouchX([evt.targetTouches[0].clientX, touchX[1]]);
	}

	const swipeMove = (evt) => {
		setTouchX([touchX[0], evt.targetTouches[0].clientX]);
	}

	const swipeEnd = () => {
		const [touchStart, touchEnd] = touchX;
		if(touchStart - touchEnd > 50 && touchEnd) {
			!disableNavigation && nextReview()
		}
		if(touchStart - touchEnd < -50 && touchEnd) {
			!disableNavigation && previousReview();
		}
		setTouchX([0,0]);
		
	}


	
	let reviewMap = [
		(reviewAmount + currentReview - 1) % reviewAmount, 
		currentReview, (currentReview + 1) % reviewAmount, 
		(currentReview + 2) % reviewAmount, 
		(currentReview + 3) % reviewAmount]

	console.log(reviewMap);

	return <div className="flex  overflow-hidden  pb-8 relative " onTouchStart={swipeStart} onTouchMove={swipeMove} onTouchEnd={swipeEnd}>
		{ !disableNavigation && <div href="javascript:;" 
			onClick={() => previousReview()} 
			className={`sticky left-2 md:static ${currentReview != 0 ? 'bg-white' : ''} block h-40-px min-w-40-px w-40-px p-1 box-border border border-gray-300 self-center rounded-full flex mr-2`}>
			<img src="/assets/images/left-arrow.svg" alt="pijl links" className={`block my-auto mx-auto w-4 h-4`} />
		</div> }
		<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-5">
			
			{reviews.map((review, index) => {

				let reviewMapIndex = reviewMap.indexOf(index);

				let orderClass = ["order-9999", "order-1", "order-2","order-3"];

				let className = reviewClassName || "";
				className += " " + (orderClass[reviewMapIndex] || "");

				return <Review key={`review-${index}`} 
				isPrevious={reviewMapIndex < 1}
				isNext={reviewMapIndex > 1}
				isVisibleOnLg={reviewMapIndex == 1 || reviewMapIndex == 2 || reviewMapIndex == 3}
				isVisibleOnMd={reviewMapIndex == 1 || reviewMapIndex == 2}
				showReviewOnMobile={disableNavigation && (reviewMapIndex == 1 || reviewMapIndex == 2 || reviewMapIndex == 3)}
				isActive={reviewMapIndex == 1}
				picture={review.picture}
				className={className}
				name={review.name}
				stars={review.stars}>{review.text}</Review>
			})}

			
			
		</div>
		{ !disableNavigation && <div
			onClick={(e) => { nextReview()}} 
			className={`sticky right-2 md:static ${(currentReview + 1) != reviewAmount ? 'bg-white' : ''} block h-40-px min-w-40-px w-40-px p-1 box-border border border-gray-300 self-center rounded-full flex ml-2`}>
			<img alt="pijl rechts" src="/assets/images/right-arrow.svg" className={`block my-auto mx-auto w-4 h-4`} />
		</div>}
	</div>
}

export function Review({ picture, name, stars, children, className = "", isActive, isNext, isPrevious, isVisibleOnLg, showReviewOnMobile, isVisibleOnMd, isIsolated }) {
	if(isNext && !isIsolated) {
		className += " translate-x-[100vw]";
		if(!isVisibleOnLg) {
			className += " lg:translate-x-[100vw]";
		}
	} else if(isPrevious && !isIsolated) {
		className += " -translate-x-[100vw]";
		if(!isVisibleOnLg) {
			className += " lg:-translate-x-[100vw]";
		}
	}
	if(showReviewOnMobile) {
		isActive = true;
	}
	if(!isActive && !isIsolated) {
		className = (className || "") + " absolute "
		if(isVisibleOnMd) {
			className = (className || "") + " md:static "
		}
		if(isVisibleOnLg) {
			className = (className || "") + " lg:static "
		}
	} else {
		className += " static translate-x-0"
	}
	if(isVisibleOnLg && !isIsolated) {
		className += " lg:opacity-1 lg:translate-x-0"
	} 
	if(isVisibleOnMd && !isIsolated) {
		className += " md:opacity-1 md:translate-x-0"
	} 
	let starsElement = [];
	for(let i = 1; i <= stars; i++) {
		starsElement.push(<img key={"el" + i.toString()} className="inline pr-1"  src="/assets/images/review-star.svg" />)
	}
	return <div className={`bg-white border border-solid text-base tracking-normal md:block duration-300 ease-in-out transition-all `+ className}>
		<div className="flex justify-between">
			<div className="p-3 w-min-content">
				<img src={picture} alt="persoon" /> 
			</div>
			<div className="py-3 bg-gray flex-auto">
				<h3 className="font-bold">{name}</h3>
				{starsElement}
			</div>
			<div className="w-min-content p-2">
				<img alt="google" src="/assets/images/google.svg" />
			</div>
		</div>
		<div className="p-3">
			{children}
		</div>
	</div>
}
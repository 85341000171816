import React, { useState } from 'react';
import {Link} from 'react-router-dom';


export default function Menu({ className, activePage }) {

	let [opened, setOpened] = useState(false);




  return <div className={"flex text-black lg:text-white font-ro-sans text-lg " + className}>
		<div className={`${opened ? 'hidden' : 'block'} lg:hidden ml-3`} onClick={() => setOpened(!opened)}><img alt="menu" src="/assets/images/mob-menu.svg" /></div>
		<div className={`transform lg:transition-none lg:block w-screen lg:w-auto lg:left-0 h-screen lg:h-auto bg-black bg-opacity-30 lg:bg-transparent top-0 fixed lg:static -left-full transition-transform ${opened ? 'block translate-x-full' : ' '}`}>
			<div className={`lg:transition-none lg:block w-4/5 lg:w-auto md:w-2/5 h-screen lg:h-auto bg-white lg:bg-transparent fixed lg:static p-0 lg:p-0 box-border`}>
				<div className={`${opened ? 'block' : 'hidden'} lg:hidden p-3 relative`} >
					<div className=" text-pink font-bold text-xl bg-gray-200 w-fit p-3 leading-4 rounded-full absolute right-3" onClick={() => setOpened(!opened)}>&#10006;</div>
				</div>
				<div className={`flex flex-col lg:flex-row mt-8 lg:mt-0`}>
					<Link to="/" className="px-4 flex-auto border-b lg:border-b-0 p-3 block">
						<span className={`lg:hidden ${activePage == '/' ? 'border-b-[2px] font-bold' : ''}`}>Home</span>
					</Link>
					<Link to="/wie-zijn-wij.html" className="px-4 flex-auto border-b lg:border-b-0 p-3 block">
						<span className={`lg:hover:border-b-[2px] lg:hover:pb-[2px] lg:hover:border-white ${activePage == '/wij-zijn-wij.html' ? 'border-b-[2px] lg:pb-[2px] lg:border-white font-bold' : ''}`}>Wie zijn wij</span>
					</Link>
					<Link to="/ervaringen" className="px-4 flex-auto border-b lg:border-b-0 p-3 block">
						<span className={`lg:hover:border-b-[2px] lg:hover:pb-[2px] lg:hover:border-white ${activePage == '/ervaringen' ? 'border-b-[2px] lg:pb-[2px] lg:border-white font-bold' : ''}`}>Ervaringen</span>
					</Link>
					<Link to="/intakegesprek.html" className="px-4 flex-auto border-b lg:border-b-0 p-3 block">
						<span className={`lg:hover:border-b-[2px] lg:hover:pb-[2px] lg:hover:border-white ${activePage == '/intakegesprek.html' ? 'border-b-[2px] lg:pb-[2px] lg:border-white font-bold' : ''}`}>Intake en kraamtijd</span>
					</Link>
					<Link to="/verzekering.html" className="px-4 flex-auto border-b lg:border-b-0 p-3 block">
						<span className={`lg:hover:border-b-[2px] lg:hover:pb-[2px] lg:hover:border-white ${activePage == '/verzekeringen.html' ? 'border-b-[2px] lg:pb-[2px] lg:border-white font-bold' : ''}`}>Kosten en verzekering</span>
					</Link>

					<div className="flex-auto p-4 lg:p-0">
					<Link to="/kraamzorg-aanvragen.html" className="rounded-lg px-8 text-center text-white bg-green hover:bg-green-dark  p-3 block">Aanvragen</Link>
					</div>
					
				</div>
			</div>
		</div>
  </div>
}

import { Fragment, useState } from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Cookie from '../Components/Cookie';
import Reviews, { Review } from '../Components/Reviews';
import RequestButton from '../Components/RequestButton';
import LimitedPlacesNotification from '../Components/LimitedPlacesNotification';
import { RatingLogos } from '../Components/ShortSummaryLogos';
import AanvragenAndPostcodeCheck from '../Components/AanvragenAndPostcodeCheck';
// #DB1F6A

export default function GeneralConditions() {
  return (
    <Fragment>
        
        <Header activePage="/algemene-voorwaarden.html" />

		<LimitedPlacesNotification />

		<div className="md:hidden">
			<img alt="twee vrouwen die met elkaar praten" className="header-bg-clip bg-cover" src="/assets/images/intakekraamtijd-header-sm.jpg" />
		</div>

		<div className="md:container-fluid md:bg-ervaringen-header md:h-[24rem] md:bg-no-repeat md:bg-right md:bg-[#666666]  md:header-bg-clip md:bg-contain">
			<div className="container mx-auto py-4 lg:py-7 flex justify-start">
				<div className="md:w-[65%] lg:w-[45%] md:bg-black md:bg-opacity-40 md:box-border md:rounded-lg md:z-10 md:relative">
					<h1 className="text-2xl lg:text-4xl md:text-white p-4 lg:py-4 lg:px-8 lg:pb-4 font-ro-sans font-bold">Algemene voorwaarden</h1>
					<div className="px-4 pb-2 lg:px-8 lg:pb-8 md:text-white text-lg">
						<p>
							Deze algemene voorwaarden van BO Geboortezorg zijn tot stand gekomen in overleg met de Consumentenbond, de patientenfederatie Nederland en
                            de LOC Zeggenschap in Zorg in het kader van de Coordinatiegroep Zelfreguleringsoverleg (CZ) van de Sociaal-Economische Raad en treden in 
                            werking per 1 oktober 2016.
						</p>
                        <div className="hidden md:block">
							<RequestButton className="hidden md:mt-8 md:inline-block" />
						</div>
                    </div>
                    
				</div>
			</div>
		</div>



        <div className="md:container-fluid mt-16">
			<div className="container mx-auto justify-end">
                <div className="p-4 lg:p-0">


                    
                    <p className="mt-4">&nbsp;ARTIKEL 1 - DEFINITIES</p>

                    <p className="mt-4">Cli&euml;nte: de natuurlijke persoon die kraamzorg afneemt bij Kraamzorg Mama. Hieronder word voor de bevalling de zwangere en na de bevalling de kraamvrouw verstaan.</p>

                    <p className="mt-4">Kraamzorgaanbieder: (rechts)persoon die kraamzorg verleent, gefinancierd door de Zorgverzekering al dan niet in combinatie met particulier gefinancierde kraamzorg en/of aanvullende diensten.</p>

                    <p className="mt-4">Verloskundige: een zelfstandig medisch beroepsbeoefenaar, die de zwangere vrouw en haar partner gedurende de zwangerschap en de bevalling begeleidt en regelmatig contact heeft met de cli&euml;nt. Ook wordt hieronder de verloskundig actieve huisarts verstaan.</p>

                    <p className="mt-4">Kraamverzorgende: De natuurlijke persoon die kraamzorg en partusassistentie geeft onder de medische verantwoordelijkheid van de verloskundige.</p>

                    <p className="mt-4">Kraamzorg: Zorg, ondersteuning, instructie en voorlichting aan de cli&euml;nte en de pasgeborene.</p>

                    <p className="mt-4">Minimale kraamzorg: Het minimum aantal uren kraamzorg exclusief partusassistentie van 24 uren verdeeld over acht dagen. Dit is landelijk bepaald.</p>

                    <p className="mt-4">Indicatiestelling: De indicatiestelling op basis van het Landelijk Indicatieprotocol Kraamzorg (LIP).</p>

                    <p className="mt-4">Landelijk Indicatieprotocol Kraamzorg (LIP): Protocol waarin beschreven wordt wat kwalitatief verantwoorde kraamzorg is regelt de hoeveelheid uren kraamzorg die nodig is voor goede kraamzorg aan de cli&euml;nte en de pasgeborene.</p>

                    <p className="mt-4">Inschrijving: Schriftelijk/ telefonisch verzoek van de cli&euml;nte aan Kraamzorg Mama om kraamzorg te leveren.</p>

                    <p className="mt-4">Overeenkomst: De tussen de cli&euml;nte en Kraamzorg Mama gesloten overeenkomst met betrekking tot kraamzorg.</p>

                    <p className="mt-4">Intake: Een persoonlijk of telefonisch gesprek tussen een vertegenwoordiger van Kraamzorg Mama en de cli&euml;nte voor de 34e week van de zwangerschap, waarin onder andere de aard en omvang van de te leveren kraamzorg en eventuele aanvullende kraamzorg en diensten worden vastgesteld. Wat de zorgbehoeftes van de cli&euml;nte zijn en wat van de cli&euml;nte verwacht wordt om goede zorg te ontvangen.</p>

                    <p className="mt-4">Praktijkbegeleider: De natuurlijke persoon die een kraamverzorgende in opleiding of stagiaire begeleidt op de werkplek/stageplek.</p>

                    <p className="mt-4">JGZ-overdracht: Overdracht van gegevens uit de kraamperiode over onder andere de cli&euml;nte, de pasgeborene, de gezinssituatie, de bevalling en het verloop van de kraamzorgperiode aan de jeugdgezondheidszorg.</p>

                    <p className="mt-4">Incident: Ieder niet beoogd of onvoorzien voorval in het kraamzorgproces met direct of op termijn merkbare gevolgen voor de cli&euml;nte en/of de pasgeborene.</p>

                    <p className="mt-4">Schriftelijk: Onder schriftelijk wordt ook verstaan digitaal of per e-mail.</p>

                    <p className="mt-4">Elektronische weg: Het overbrengen of opslaan van gegevens via een website, internet of e-mail.</p>

                    <p className="mt-4">ARTIKEL 2 - TOEPASSELIJKHEID</p>

                    <p className="mt-4">1. Deze algemene voorwaarden zijn van toepassing op de overeenkomst.<br />
                    2. Deze algemene voorwaarden beschrijven de rechten en plichten van de zorgaanbieder en cli&euml;nte. 3. Deze algemene voorwaarden laten dwingendrechtelijke bepalingen onverlet.</p>

                    <p className="mt-4">ARTIKEL 3 - BEKENDMAKING ALGEMENE VOORWAARDEN<br />
                    1. Kraamzorg Mama overhandigt deze algemene voorwaarden aan de cli&euml;nte voorafgaand aan of bij de totstandkoming van de overeenkomst en licht deze op verzoek van de cli&euml;nte mondeling toe. De algemene voorwaarden zijn tevens via de website van Kraamzorg Mama in te zien.<br />
                    2. a. Als de overeenkomst tot stand komt via de elektronische weg dan kunnen de algemene voorwaarden via de elektronische weg ter beschikking worden gesteld op een dusdanige wijze dat ze opgeslagen kunnen worden zodat ze later toegankelijk zijn;<br />
                    b. Als de overeenkomst niet via de elektronische weg tot stand komt dan kunnen de algemene voorwaarden ook op vergelijkbare wijze via de elektronische weg worden verstrekt, echter alleen als de consument hier mee akkoord gaat.<br />
                    c. Als een persoon zich digitaal of telefonisch inschrijft voor kraamzorg bij Kraamzorg Mama gaat de cli&euml;nt automatisch akkoord met de algemene voorwaarden. De cli&euml;nt krijgt na inschrijving een bevestigingsemail met een verwijzing naar de algemene voorwaarden op de website.</p>

                    <p className="mt-4">ARTIKEL 4 - AFWIJKING VAN DE ALGEMENE VOORWAARDEN<br />
                    De kraamzorgaanbieder en de cli&euml;nt kunnen niet afwijken van deze algemene voorwaarden, tenzij dat uitdrukkelijk is overeengekomen met de cli&euml;nte of kraamzorgaanbieder en de afwijking niet in het nadeel is van de cli&euml;nte, pasgeborene of kraamzorgaanbieder. Afwijkingen zijn alleen geldig indien ze schriftelijk zijn overeengekomen.</p>

                    <p className="mt-4">ARTIKEL 5 - DUIDELIJKE INFORMATIE<br />
                    &nbsp;1. Kraamzorg Mama zorgt ervoor dat hij die informatie beschikbaar heeft (schriftelijk of op de website) die het voor de cli&euml;nte mogelijk maakt een goede vergelijking te maken met andere kraamzorgaanbieders, teneinde een keuze te kunnen maken.<br />
                    2. In deze informatie vermeldt Kraamzorg Mama in ieder geval:<br />
                    a. dat er een overeenkomst tot stand komt op het moment dat Kraamzorg Mama de inschrijving accepteert;<br />
                    b. dat de cli&euml;nte tot 14 dagen na de acceptatie door Kraamzorg Mama kosteloos het recht heeft de overeenkomst ongedaan te maken;<br />
                    c. eventuele voorbehouden ten aanzien van het kunnen leveren van de overeen te komen kraamzorg.<br />
                    3. Kraamzorg Mama zorgt ervoor dat de cli&euml;nte gedurende de looptijd van de overeenkomst voldoende ge&iuml;nformeerd blijft over voor haar en voor de pasgeborene relevante aangelegenheden aangaande de uitvoering van de overeenkomst.<br />
                    4. Kraamzorg Mama gaat na of de cli&euml;nte de informatie begrepen heeft, alvorens een inschrijving te accepteren.</p>

                    <p className="mt-4">3. OVEREENKOMST EN NADERE AFSPRAKEN<br />
                    ARTIKEL 6 - DE OVEREENKOMST<br />
                    1. De schriftelijke, telefonische of digitale inschrijving door de cli&euml;nte vormt het verzoek aan de kraamzorgaanbieder kraamzorg aan de cli&euml;nte te leveren. De kraamzorgaanbieder accepteert de inschrijving schriftelijk of digitaal waarmee de overeenkomst tot stand komt. De cli&euml;nte heeft tot 14 dagen na het sluiten van de overeenkomst het recht de overeenkomst (kosteloos) ongedaan te maken.<br />
                    2. Als de cli&euml;nte zich telefonisch inschrijft, stuurt de kraamzorgaanbieder daarna een bevestiging van de inschrijving per email, met een doorverwijzing naar de algemene voorwaarden. In dit geval komt de overeenkomst tot stand na de telefonische bevestiging.<br />
                    3. Als de cli&euml;nte jonger is dan 18 jaar dan moet de cli&euml;nte de overeenkomst, ondanks het 6 Feit dat zij vanaf de leeftijd van 16 jaar een geneeskundige overeenkomst mag aangaan (WGBO), laten ondertekenen door een vertegenwoordiger vanwege de financi&euml;le garantstelling tot 18 jaar.<br />
                    4. De overeenkomst bevat in ieder geval:<br />
                    a. een verwijzing naar het LIP voor de aard en omvang van de kraamzorg. De aard en omvang van de kraamzorg wordt tijdens het intakegesprek (voor de 34e week van de zwangerschap) schriftelijk vastgesteld;<br />
                    b. als inschrijving heeft plaatsgevonden v&oacute;&oacute;r de 5e maand van de zwangerschap, een bepaling dat de ge&iuml;ndiceerde uren kraamzorg aan de hand van het LIP worden geleverd;<br />
                    c. als inschrijving heeft plaatsgevonden in of na de 5e maand van de zwangerschap, een bepaling dat in ieder geval de minimale kraamzorg wordt gegarandeerd;<br />
                    d. indien van toepassing een duidelijke omschrijving van de voorbehouden ten aanzien van het kunnen leveren van de afgesproken kraamzorg en de gevolgen daarvan;<br />
                    e. dat afspraken over aanvullende kraamzorg en diensten tijdens de intake (zie artikel 8) worden besproken en schriftelijk worden vastgelegd in een addendum op de overeenkomst. Als dit voor cli&euml;nte kosten oplevert, dient er een specificatie van de kosten in dit addendum opgenomen te worden;<br />
                    f. een bepaling dat cli&euml;nte een landelijk bepaalde eigen bijdrage verschuldigd is over de geleverde uren kraamzorg. Deze is in 2017&nbsp;vastgesteld op &euro; 4,30&nbsp;per uur.;<br />
                    g. een regeling betreffende toestemming voor gebruik van gegevens van de cli&euml;nte en de pasgeborene;<br />
                    &nbsp;- voor verplichte meting van zorginhoudelijke kwaliteitsindicatoren en voor de benadering van cli&euml;nten voor het meten van cli&euml;ntervaringen in de zorg (CQ-meting);<br />
                    &nbsp;- voor controles door zorgverzekeraars ter uitvoering van het contract met de kraamzorgaanbieder in overeenstemming met de geldende regels;<br />
                    - voor overdracht van gegevens aan de jeugdgezondheidszorg;<br />
                    h. een eventuele annuleringskostenregeling;<br />
                    i. een bepaling dat wijziging van de overeenkomst alleen mogelijk is na overleg tussen kraamzorgaanbieder en cli&euml;nt en dat deze schriftelijk moet worden vastgelegd;<br />
                    j. een verwijzing naar deze algemene voorwaarden en de toepasselijkheid hiervan.</p>

                    <p className="mt-4">ARTIKEL 7 - AFWIJKING VAN DE OVEREENKOMST<br />
                    1. Afwijking van de overeengekomen kraamzorguren kan alleen in onderling overleg tot stand komen en dient schriftelijk te worden vastgelegd. Afwijking van de voorgeschreven minimale kraamzorg is niet mogelijk. Bij afwijking van de overeenkomst kan in overleg door beide partijen schriftelijk een genoegdoening afgesproken worden.<br />
                    2. Een eigen bijdrage is de cli&euml;nte na afwijking van de overeenkomst alleen verschuldigd over het werkelijk afgenomen aantal uren kraamzorg. Bij het ondertekenen van het urenregistratieformulier gaat de cli&euml;nt akkoord met de afgenomen uren die vermeld staan op het urenregistratieformulier.<br />
                    <br />
                    ARTIKEL 8 - DE INTAKE<br />
                    1. Bij de intake bespreekt de kraamzorgaanbieder de indicatiestelling met de cli&euml;nte. In dit gesprek wordt besproken:<br />
                    a. de procedure ter verkrijging van een (her)indicatie conform het LIP en de toelichting over de (her)indicatie en de consequenties van voortijdige be&euml;indiging van de kraamzorg door de cli&euml;nte;<br />
                    b. de vaststelling van de aard en omvang van de te leveren kraamzorg aan de hand van het LIP en de wensen van de cli&euml;nte;<br />
                    c. een beschrijving en eventuele vaststelling van de aanvullende kraamzorg (vergoed door zorgverzekeraar in aanvullend pakket of particulier gefinancierd) en van de diensten waar de cli&euml;nte gebruik van kan maken en eventuele vaststelling daarvan wat volgens artikel 6 lid 2e wordt vastgelegd.<br />
                    2. Voor of tijdens de intake biedt de kraamzorgaanbieder de cli&euml;nte schriftelijke informatie aan over tenminste de volgende punten:<br />
                    a. de verantwoordelijkheidsverdeling tussen kraamverzorgende en verloskundige;<br />
                    b. sleutelbeheer;<br />
                    c. welke voorzieningen de cli&euml;nte moet treffen om de kraamverzorgende in staat te stellen veilig te werken conform de regelgeving met betrekking tot arbeidsomstandigheden en hygi&euml;ne;<br />
                    d. het verbod op het gebruik van de auto van de cli&euml;nte en/of partner door de kraamverzorgende;<br />
                    e. het parkeerbeleid en de eventuele parkeerkosten;<br />
                    f. het privacybeleid;<br />
                    g. het medicatiebeleid;<br />
                    h. de informatieplicht aan cli&euml;nte over inzet van kraamverzorgenden in opleiding en toestemmingsplicht bij inzet stagiaires.<br />
                    i. de eventuele annuleringskostenregeling<br />
                    j. de consequenties van de arbeidstijden en de collectieve arbeidsovereenkomst (cao) voor de inzet van kraamverzorgenden<br />
                    k. schaderegeling: de regeling voor vergoeding van schade, veroorzaakt door de medewerker van de kraamzorgaanbieder<br />
                    3. De in het intakegesprek gemaakte afspraken worden schriftelijk vastgelegd (zie artikel 6 lid 3 onder e).<br />
                    <br />
                    ARTIKEL 9 - HET KRAAMZORGPLAN 1.<br />
                    De kraamverzorgende stelt schriftelijk op basis van de indicatiestelling uit het Landelijk Indicatieprotocol (LIP) en in samenspraak met de cli&euml;nte een kraamzorgplan op bij aanvang van de kraamzorg.<br />
                    2. In het kraamzorgplan worden de doelen en afspraken vastgelegd en afgestemd op de wensen, gewoontes en omstandigheden van de cli&euml;nte en de pasgeborene.<br />
                    3. In het kraamzorgplan wordt voorts in ieder geval vastgelegd:<br />
                    - welke gezinsleden of andere mantelzorg bij de kraamzorgverlening worden betrokken;<br />
                    - de gemaakte afspraken over ondersteuning, instructie en voorlichting te leveren door de kraamverzorgende aan partner en/of andere gezinsleden.<br />
                    - de momenten van evaluatie van het kraamzorgplan.<br />
                    4. Als de kraamverzorgende de overeengekomen kraamzorg niet conform het kraamzorgplan kan verlenen, stelt de kraamverzorgende/kraamzorgaanbieder de cli&euml;nte daarvan meteen in kennis. Als de cli&euml;nte de overeengekomen kraamzorg niet conform het kraamzorgplan kan/wil ontvangen, stelt de cli&euml;nte de kraamverzorgende en buiten de werktijden van de kraamverzorgende de kraamzorgaanbieder daarvan meteen in kennis. In overleg en samenspraak met de cli&euml;nte wordt het kraamzorgplan door de kraamverzorgende vervolgens bijgesteld.<br />
                    <br />
                    4. PRIVACY ARTIKEL 10 - ALGEMEEN<br />
                    1. Voor de in dit hoofdstuk bedoelde gegevens geldt onverkort wat is bepaald in de regeling bescherming persoonsgegevens.<br />
                    2.&nbsp;Onze medewerkers mogen niet roken in de huis van de cli&euml;nt, of in de bijzijn van de cli&euml;nt of de pasgeborene.<br />
                    3.&nbsp;Onze medewerkers mogen nooit namens de cli&euml;nt geldzaken regelen, bijvoorbeeld door gebruik te maken van een creditcard, chipknip en/of de bank-giropas van de cli&euml;nt.<br />
                    4.&nbsp;Onze medewerkers mogen geen cadeaus, giften en/of spullen van de cli&euml;nten aannemen.<br />
                    5.&nbsp;Onze medewerkers mogen geen gebruik maken van de auto&nbsp;van de cli&euml;nt of die van &eacute;&eacute;n van de huisgenoten.<br />
                    6.&nbsp;Het is voor onze medewerkers verboden om voor de cli&euml;nt of &eacute;&eacute;n van de huisgenoten, onder het mom van &lsquo;zorgverlening&rsquo;, te vervoeren in hun eigen (bedrijfs)auto.<br />
                    &nbsp;</p>

                    <p className="mt-4">ARTIKEL 11 - BEWAREN VAN GEGEVENS<br />
                    1. De kraamzorgaanbieder dient gegevens over de cli&euml;nte en de pasgeborene te bewaren. Deze gegevens zijn vastgelegd in de overeenkomst, het LIP-formulier, JGZ overdracht, de urenregistratie en een weergave van de registratie, interpretatie en te nemen acties bij de cli&euml;nte en/of de pasgeborene ten behoeve van signalering van gezondheidsproblemen.<br />
                    2. Bij be&euml;indiging van de overeenkomst bewaart de kraamzorgaanbieder bovenstaande gegevens en blijven deze gegevens ter beschikking van zowel de kraamzorgaanbieder als de cli&euml;nte. De cli&euml;nte krijgt een kopie als zij dat wil. Voor de gegevens gelden de daar bepaalde bewaartermijn en de 8 rechten van de cli&euml;nte en ten aanzien van correctie en vernietiging. Voor andere gegevens geldt de norm genoemd in de regeling bescherming persoonsgegevens.<br />
                    <br />
                    ARTIKEL 12 - GEGEVENSVERSTREKKING EN VERLENING VAN INZAGE DOOR DE KRAAMZORGAANBIEDER AAN DERDEN<br />
                    1. De kraamzorgaanbieder verstrekt zonder de schriftelijke toestemming van de cli&euml;nte geen (inzage in) gegevens over de cli&euml;nte en de pasgeborene aan derden, behalve ter voldoening aan een landelijk verplichting of naleving van de meldcode kindermishandeling indien toestemming niet gevraagd kan worden vanwege veiligheid kind/gezin.<br />
                    2. Onder derden als bedoeld in het eerste lid worden niet verstaan de verloskundige en diegene die namens en/of in opdracht van de kraamzorgaanbieder betrokken zijn bij de levering van de kraamzorg, voor zover de verstrekking van (inzage in) gegevens noodzakelijk is voor de door dezen te verrichten werkzaamheden.<br />
                    3. Na overlijden van de cli&euml;nte en/of de pasgeborene geeft de kraamzorgaanbieder desgevraagd inzage in de door de kraamzorgaanbieder bewaarde gegevens aan de nabestaanden voor zover de cli&euml;nte daarvoor schriftelijk toestemming heeft gegeven of toestemming mag worden verondersteld. 4. De kraamverzorgende en degenen die namens en/of in opdracht van de kraamzorgaanbieder betrokken zijn bij de levering van de kraamzorg zijn gehouden aan een geheimhoudingsplicht. De kraamzorgaanbieder stelt de cli&euml;nte hiervan op de hoogte.<br />
                    <br />
                    <br />
                    5. KWALITEIT EN VEILIGHEID ARTIKEL 13 - KRAAMZORG<br />
                    1. De kraamzorgaanbieder levert kraamzorg met inachtneming van de normen &ldquo;verantwoorde kraamzorg&rdquo; zoals die door representatieve organisaties van in ieder geval kraamzorgaanbieders en cli&euml;nten in overleg met de Inspectie voor de Gezondheidszorg zijn vastgesteld en de zorg omschreven in het LIP.<br />
                    2. De kraamzorgaanbieder zorgt ervoor dat alle kraamverzorgenden die binnen de organisatie van de kraamzorgaanbieder of in opdracht van de kraamzorgaanbieder kraamzorg verlenen aan de cli&euml;nte: a. hiertoe te allen tijde bevoegd en bekwaam zijn;<br />
                    b. ingeschreven staan in het Kwaliteitsregister van het Kenniscentrum Kraamzorg<br />
                    c. handelen overeenkomstig de voor de kraamverzorgende geldende professionele standaarden waaronder de richtlijnen van de beroepsgroep en in ieder geval als een redelijk bekwaam en redelijk handelend beroepsbeoefenaar. Afwijking van de professionele standaard moet de kraamzorgverzorgende motiveren en aan de cli&euml;nte uitleggen. De kraamzorgverzorgende maakt aantekening van de afwijking en van de uitleg aan de cli&euml;nte in het kraamzorgplan.<br />
                    3. De kraamverzorgende in opleiding mag uitsluitend kraamzorg verlenen onder supervisie van een praktijkbegeleider<br />
                    4. De kraamzorgaanbieder zorgt voor continu&iuml;teit van de kraamzorg.<br />
                    <br />
                    ARTIKEL 14 - VEILIGHEID<br />
                    De kraamzorgaanbieder maakt gebruik van deugdelijk materiaal dat zij voor de uitoefening van het beroep nodig heeft.<br />
                    <br />
                    ARTIKEL 15 - AFSTEMMING (&Eacute;&Eacute;N CLI&Euml;NTE &ndash; MEER ZORGVERLENERS)<br />
                    Als de cli&euml;nte en/of de pasgeborene te maken heeft met twee of meer zorgverleners die namens of in opdracht van de kraamzorgaanbieder betrokken zijn bij de levering van de kraamzorg zorgt de kraamzorgaanbieder ervoor dat:<br />
                    a. alle betrokken zorgverleners elkaar bij overdracht of via het kraamzorgplan informeren en indien nodig bevragen over relevante gegevens van de cli&euml;nte en/of de pasgeborene, waarbij de ervaringen van de cli&euml;nte worden meegenomen en de cli&euml;nte daarover wordt ge&iuml;nformeerd;<br />
                    b. de taken en verantwoordelijkheden rond de kraamzorgverlening aan de cli&euml;nte en/of de pasgeborene tussen de betrokken zorgverleners helder zijn afgebakend en afgestemd;<br />
                    c. alle zorgverleners het kraamzorgplan bijhouden en raadplegen.<br />
                    <br />
                    ARTIKEL 16 - INCIDENTEN<br />
                    1. Zo spoedig mogelijk na een incident informeert de kraamzorgaanbieder de cli&euml;nte over:<br />
                    a. de aard en de oorzaak van het incident;<br />
                    b. of en welke maatregelen zijn genomen om soortgelijke incidenten te voorkomen.<br />
                    2. Als een incident gevolgen heeft voor de gezondheidstoestand van de cli&euml;nte en/of de pasgeborene, bespreekt de kraamverzorgende dit meteen met de verloskundige.<br />
                    3. De kraamverzorgende verleent adequate kraamzorg op instructie van de verloskundige teneinde de gevolgen van het incident voor de cli&euml;nte en/of de pasgeborene te beperken.<br />
                    4. In geval een incident direct om ingrijpen vraagt, handelt de kraamverzorgende direct en meldt dit zo spoedig mogelijk aan de verloskundige.<br />
                    5. Kraamzorg Mama zorgt voor adequate melding van incidenten in de daarvoor vastgestelde registratiesystemen.</p>

                    <p className="mt-4">ARTIKEL 17 - ZORG VOOR PERSOONLIJKE EIGENDOMMEN<br />
                    De kraamzorgaanbieder zorgt ervoor dat degenen die onder zijn verantwoordelijkheid betrokken zijn bij de kraamzorg voor de cli&euml;nte en de pasgeborene, zorgvuldig omgaan met hun eigendommen.<br />
                    <br />
                    6. VERPLICHTINGEN VAN DE CLI&Euml;NTE ARTIKEL 18 - VERPLICHTINGEN VAN DE CLI&Euml;NTE<br />
                    1. De cli&euml;nte legitimeert zich voorafgaand aan de totstandkoming van de overeenkomst of gedurende de looptijd van de overeenkomst op verzoek van de kraamzorgaanbieder met een in Nederland erkende, geldig legitimatiebewijs.<br />
                    2. De cli&euml;nte geeft de kraamzorgaanbieder, mede naar aanleiding van diens vragen, naar beste weten de inlichtingen en de medewerking die deze redelijkerwijs voor het uitvoeren van de overeenkomst behoeft.<br />
                    3. De cli&euml;nte onthoudt zich van gedrag zoals agressie, discriminatie, (seksuele) intimidatie en/of ander gedrag dat schadelijk is voor de gezondheid of het welzijn van de kraamverzorgende en andere personen werkzaam bij of in opdracht van de kraamzorgaanbieder. De cli&euml;nte spant zich tevens in dat gezinsleden en bezoekers zich onthouden van bovenstaand gedrag.<br />
                    4. De cli&euml;nte verleent alle noodzakelijke medewerking om de kraamzorgaanbieder in staat te stellen kraamzorg conform de regelgeving betreffende de arbeidsomstandigheden en hygi&euml;ne te kunnen laten geven.<br />
                    5. De cli&euml;nte moet kraamverzorgende en andere personen werkzaam bij of in opdracht van de kraamzorgaanbieder de gelegenheid bieden hun taken uit te voeren zoals vastgelegd in het kraamzorgplan of in het kader van veiligheid.<br />
                    6. Zodra de cli&euml;nte kraamzorg en/of diensten ontvangt van een andere kraamzorgaanbieder, informeert zij de kraamzorgaanbieder daarover.<br />
                    7. De cli&euml;nte moet bij de kraamzorgaanbieder binnen 5 dagen na afronding van de kraamzorg schriftelijk melding maken van door haar geconstateerde schade. &nbsp;Schademeldingen die na 5 dagen binnen komen worden niet in behandeling genomen. De cli&euml;nt heeft een eigen risico van &euro; 50,-&nbsp;per schadegeval. De cli&euml;nt ontvangt een schadevergoeding op basis van de hoogste dagwaarde van het beschadigde product.<br />
                    8. De cli&euml;nte wordt geacht verzekerd te zijn voor aansprakelijkheid.<br />
                    9. Je accepteert dat wij geen rekening kunnen houden met de eventuele voorkeur voor leeftijd, geslacht, levensbeschouwing, huidskleur of seksuele geaardheid van de medewerker waarmee jij te maken krijgt. Je mag geen medewerkers om discriminerende redenen weigeren.<br />
                    <br />
                    7. BETALING ARTIKEL 19 - BETALING<br />
                    1. De cli&euml;nte is de kraamzorgaanbieder de overeengekomen prijs verschuldigd voor de overeengekomen kraamzorg en diensten voor zover rechtstreeks door de zorgverzekeraar zijn verschuldigd.<br />
                    2. Voor de overeengekomen kosten van aanvullende kraamzorg, eigen bijdrage en/of diensten als bedoeld in artikel 6 lid 3 onder e en f stuurt de kraamzorgaanbieder een duidelijke en gespecificeerde factuur aan de cli&euml;nte.<br />
                    3. De kraamzorgaanbieder stuurt na het verstrijken van een betalingstermijn van 14 dagen een betalingsherinnering en geeft de cli&euml;nte de gelegenheid binnen 14 dagen na ontvangst van de herinnering alsnog te betalen.<br />
                    4. Als na het verstrijken van de tweede betalingstermijn nog steeds niet is betaald is de kraamzorgaanbieder gerechtigd we incassokosten in rekening te brengen vanaf het verstrijken van de eerste betalingstermijn.<br />
                    5. Indien er parkeerkosten worden gemaakt voor het leveren van kraamzorg, dan zal Kraamzorg Mama deze kosten bij de cli&euml;nt in rekening brengen. De cli&euml;nt mag de parkeerbonen direct tijdens de zorgverlening betalen, anders krijgt de cli&euml;nt achteraf een factuur van de gemaakte parkeerkosten.&nbsp;De cli&euml;nt mag de kraamverzorgende ook &eacute;&eacute;n van de volgende alternatieven aanbieden:<br />
                    -&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; een parkeerkaart/ bezoekerspas;<br />
                    -&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; of een gratis parkeerplaats binnen redelijke loopafstand (max 10 minuten) van het huis waar de zorg wordt verleend.<br />
                    6. Als de cli&euml;nt heeft meegedaan aan een inschrijfactie waarbij er een cadeau/waardebon is verstrekt na de inschrijving, dan worden de kosten van dit cadeau/waardebon bij die cli&euml;nt in rekening gebracht.<br />
                    <br />
                    8. BE&Euml;INDIGING VAN DE OVEREENKOMST ARTIKEL 20 - BE&Euml;INDIGING OVEREENKOMST<br />
                    1. De overeenkomst eindigt:<br />
                    a. door opname van de cli&euml;nte in een ziekenhuis als deze niet binnen 10 dagen na de bevalling uit het ziekenhuis terugkeert en de pasgeborene gedurende deze 10 dagen geen kraamzorg nodig heeft, tenzij de cli&euml;nte aanvullend verzekerd is voor uitgestelde kraamzorg;<br />
                    b. door opname van de pasgeborene in een ziekenhuis als deze niet binnen 10 dagen na de bevalling uit het ziekenhuis terugkeert en de cli&euml;nte geen kraamzorg nodig heeft gedurende deze 10 dagen, tenzij de cli&euml;nte aanvullend verzekerd is voor uitgestelde kraamzorg;<br />
                    c. bij wederzijds goedvinden wat schriftelijk wordt vastgelegd;<br />
                    d. door overlijden van de cli&euml;nte als de pasgeborene geen kraamzorg nodig heeft;<br />
                    e. door overlijden van de foetus of pasgeborene als de cli&euml;nte geen kraamzorg nodig heeft;<br />
                    f. op basis van medische gronden bij de cli&euml;nte;<br />
                    2. Indien de cli&euml;nte de overeenkomst anders dan op basis van bovenstaande onderdelen eenzijdig opzegt, brengt Kraamzorg Mama annuleringskosten van 90 euro in rekening brengen.<br />
                    3. Annulering kan alleen schriftelijk doorgeven worden.&nbsp;Bijvoorbeeld door een e-mail of een brief naar ons te sturen.</p>

                    <p className="mt-4">ARTIKEL 21 - OPZEGGING DOOR DE KRAAMZORGAANBIEDER<br />
                    De kraamzorgaanbieder kan de overeenkomst uitsluitend schriftelijk opzeggen om gewichtige redenen mits is voldaan aan de volgende voorwaarden:<br />
                    a. de kraamzorgaanbieder heeft de gronden waarop de voorgenomen opzegging berust met de cli&euml;nte besproken;<br />
                    b. de kraamzorgaanbieder heeft met de cli&euml;nte een passend alternatief besproken;<br />
                    c. de kraamzorgaanbieder heeft de cli&euml;nte gewezen op de mogelijkheid een klacht in te dienen.<br />
                    <br />
                    ARTIKEL 22 KLACHTEN EN GESCHILLEN - KLACHTENREGELING<br />
                    1. De zorgaanbieder hanteert een op de Kwaliteit Klachten en Geschillen Zorg gebaseerde en voldoende bekend gemaakte regeling voor de opvang, en afhandeling van klachten en behandelt de klacht overeenkomstig deze klachtenprocedure.<br />
                    2. Onderdeel van de klachtenregeling is de klachtenfunctionaris. Deze zorgt o.a. voor opvang van de klacht, kan bemiddelen en een oplossing voor de klacht aandragen. Deze klachtenfunctionaris werkt onafhankelijk van de directie / raad van bestuur / eigenaar van de zorgaanbieder. Naam en contactgegevens van deze functionaris staan in de boven bedoelde klachtenregeling.<br />
                    3. De klachtenregeling is makkelijk vindbaar geplaatst op de website van de zorgaanbieder. Indien gewenst ontvangt de pati&euml;nt hiervan een papieren versie.<br />
                    <br />
                    ARTIKEL 23 - GESCHILLENREGELING<br />
                    1. Een geschil ontstaat als de procedure zoals omschreven in artikel 22 niet goed is gevolgd of niet heeft geleid tot het in voldoende mate wegnemen van de klacht of indien van de pati&euml;nt in redelijkheid niet mag worden verwacht de klacht eerst bij de zorgaanbieder in te dienen.<br />
                    2. De pati&euml;nt en de zorgaanbieder kunnen een geschil voorleggen aan de commissie zoals genoemd in de klachten- en geschillenregeling van de zorgaanbieder en die voldoet aan alle gestelde eisen.<br />
                    3. De regeling is afgestemd met vertegenwoordigende partijen van pati&euml;nten / consumenten.<br />
                    4. De commissie behandelt klachten en claims en kan een schadevergoeding toekennen van ten hoogste &euro; 25.000.<br />
                    5. De regeling is makkelijk vindbaar geplaatst op de website van de zorgaanbieder. Indien gewenst ontvangt de pati&euml;nt hiervan een papieren versie.<br />
                    <br />
                    10. OVERIGE ARTIKEL 24 - WIJZIGING<br />
                    Deze algemene voorwaarden kunnen slechts worden gewijzigd in overleg tussen Bo Geboortezorg enerzijds en de Consumentenbond, de LOC Zeggenschap in de zorg en de Pati&euml;ntenfederatie Nederland anderzijds.</p>

                    <p className="mt-4">Artikelsgewijze toelichting bij de algemene leveringsvoorwaarden kraamzorg<br />
                    <br />
                    ARTIKEL 1 - DEFINITIES</p>

                    <p className="mt-4">Cli&euml;nte: Onder cli&euml;nte wordt verstaan de (minderjarige) zwangere/kraamvrouw.<br />
                    <br />
                    Kraamzorgaanbieder: onder deze definitie vallen alle kraamzorgaanbieders (zowel rechtspersonen als natuurlijke personen) die een contract voor het leveren van kraamzorg hebben afgesloten met zorgverzekeraars en kraamzorg leveren gefinancierd door de zorgverzekering, en alle kraamzorgaanbieders die een mix leveren van publiek en particulier gefinancierde kraamzorg. Bij sommige kraamzorgaanbieders kan een cli&euml;nte namelijk in aanvulling op het pakket kraamzorg afnemen die niet in het basis en/of aanvullende pakket van de polis van de zorgverzekeraar is opgenomen. Een gecontracteerde kraamzorgaanbieder (hoofdaannemer) kan ervoor kiezen de kraamzorg te laten leveren door andere kraamzorgaanbieders (onderaannemers) zijnde rechtspersonen en/of natuurlijke personen. Deze andere kraamzorgaanbieders dienen dan deze Algemene Voorwaarden toe te passen c.q. uit te voeren. De gecontracteerde kraamzorgaanbieder (hoofdaannemer) blijft in alle gevallen eindverantwoordelijk.<br />
                    <br />
                    Kraamzorg: is zorg gefinancierd uit de zorgverzekeringspakket is opgenomen in het basispakket. Daarnaast kan er sprake zijn van een aanvullend pakket van de kraamzorgverzekeraar en/of particuliere kraamzorg gefinancierd door de cli&euml;nte zelf. Ook leveren sommige kraamzorgaanbieders aanvullende diensten zoals verhuur van hulpmiddelen, cursussen, etc.<br />
                    <br />
                    ARTIKEL 2 - LID 2, TOEPASSELIJKHEID<br />
                    Dit betekent dat dwingendrechtelijke bepalingen altijd voorgaan boven deze Algemene Voorwaarden.<br />
                    <br />
                    <br />
                    ARTIKEL 3 - BEKENDMAKING ALGEMENE VOORWAARDEN<br />
                    De algemene voorwaarden wordt in principe altijd door of namens degene die het contract aangaat fysiek aan de cli&euml;nte overhandigd. Er zijn echter twee andere mogelijkheden:<br />
                    - Als de overeenkomst via de elektronische weg tot stand is gekomen, dan mogen de algemene voorwaarden ook via elektronische weg ter hand worden gesteld. Echter ze moeten wel opgeslagen kunnen worden en toegankelijk blijven voor latere raadpleging. Dit betekent dat de cli&euml;nte ze moet kunnen downloaden. Als dit redelijkerwijs niet mogelijk is dan moet de kraamzorgaanbieder ze later alsnog via e-mail sturen.<br />
                    - Als de overeenkomst niet via de elektronische weg tot stand is gekomen, dan kan de kraamzorgaanbieder ze ook langs elektronische weg (zelfde voorwaarden als hierboven) ter beschikking stellen maar alleen als er uitdrukkelijke toestemming is van de consument dat ze elektronisch worden verstrekt. Er moet dan dus bijvoorbeeld een apart onderdeel zijn op het contract wat aangekruist kan worden waarbij de consument instemt met het via e-mail/internet verstrekken van de algemene voorwaarden. In elk geval moet de cli&euml;nte akkoord zijn met de algemene voorwaarden voordat zij een overeenkomst sluit.<br />
                    &nbsp;</p>

                    <p className="mt-4">ARTIKEL 4 LID 1 DUIDELIJKE INFORMATIE<br />
                    Indien de kraamzorgaanbieder op www.kiesbeter.nl zijn gegevens heeft vermeld is voldaan aan de informatie zoals vermeld in artikel 5. Zo niet, dan zal de kraamzorgaanbieder dezelfde informatie op een andere manier beschikbaar moeten stellen.</p>

                    <p className="mt-4">ARTIKEL 5 - LID 2C<br />
                    De kraamzorgaanbieder kan bijvoorbeeld op zijn website aangeven dat de mate waarin de ge&iuml;ndiceerde uren kraamzorg kunnen worden geleverd, afhankelijk is van de regio, de periode waarin de kraamzorg nodig is en het moment van inschrijving.</p>

                    <p className="mt-4">ARTIKEL 5 - LID 4<br />
                    Het gaat erom dat de kraamzorgaanbieder bij acceptatie van de inschrijving er zeker van is dat de cli&euml;nte het heeft begrepen. Dit kan door de website in te richten met het bekende hokje dat de cli&euml;nt moet aanvinken voordat zij verder gaat met het inschrijvingsproces. Of, bij andere methode van inschrijving, door in het telefoongesprek dit nog uitdrukkelijk te vragen.</p>

                    <p className="mt-4">ARTIKEL 6 - DE OVEREENKOMST<br />
                    De overeenkomst, waarvan de algemene leveringsvoorwaarden onderdeel uit maken wordt individueel met de cli&euml;nte afgesloten. Deze overeenkomst wordt ondertekend door de cli&euml;nte en de Raad van Bestuur of degene die door de Raad van Bestuur is gemachtigd tot het tekenen van de overeenkomst. Indien de cli&euml;nte jonger dan 18 jaar is, dient de vertegenwoordiger (ouder of voogd) te tekenen. Van belang is dat de cli&euml;nte voor dat deze de overeenkomst sluit, op de hoogte is van de voorwaarden en hiermee instemt. Zie verder artikel 3 en de toelichting van artikel 3. Dit laat onverlet dat vanaf de leeftijd van 16 jaar zelfstandig het kraamzorgplan kan worden overeengekomen tussen kraamverzorgende en cli&euml;nte.<br />
                    <br />
                    ARTIKEL 6 - LID 2 E EN F<br />
                    De hoogte van de eigen bijdrage wordt jaarlijks door het Zorginstituut (ZiNl)vastgesteld. De hoogte van de eigen bijdrage is op te vragen bij ZiNl of de kraamzorgaanbieder. In sommige gevallen kan de zorgverzekeraar de eigen bijdrage of aanvullende kraamzorg bij een aanvullende verzekering vergoeden aan de cli&euml;nte. Het is aan de cli&euml;nte om na te gaan of dit in haar situatie het geval is.<br />
                    <br />
                    ARTIKEL 8 - LID 2H EN 2 I: DE INTAKE Indien de kraamzorgaanbieder bij een intakegesprek of bij de kraamzorg een stagiaire aanwezig wil laten zijn, moet hij daarvoor toestemming krijgen van de cli&euml;nte. Indien de kraamzorg wordt gegeven door een kraamverzorgende in opleiding dan informeert de kraamzorgaanbieder de cli&euml;nte hierover. De kraamzorgaanbieder informeert de cli&euml;nte bij het maken van de intakeafspraak over de annuleringskosten voor het niet afzeggen van een intakegesprek. De kosten voor het niet afzeggen van een intakegesprek bedragen ten hoogste het tarief voor intakekosten. Tevens informeert de kraamzorgaanbieder de cli&euml;nte tijdens de intake over annuleringskosten bij eenzijdige opzegging van de zorgovereenkomst (artikel 20, Lid 2).<br />
                    <br />
                    ARTIKEL 11 - LID 1 BEWAREN VAN GEGEVENS<br />
                    Bij een weergave van de registratie, interpretatie en te nemen acties bij de kraamvrouw ten behoeve van signalering van gezondheidsproblemen moet tenminste bewaard worden: - Weergaven van de temperatuur; - Weergave van de sociale, emotionele en fysieke gesteldheid. Bij een weergave van de registratie, interpretatie en te nemen acties bij de pasgeborene ten behoeve van signalering van gezondheidsproblemen moet tenminste bewaard worden:<br />
                    - Weergave van de temperatuur;<br />
                    - Weergave van de vochtbalans;<br />
                    - Weergave van de kleur van de huid;<br />
                    - Weergave van het gewicht.<br />
                    - Weergave van de voeding<br />
                    <br />
                    ARTIKEL 11 - LID 2<br />
                    De regeling bescherming persoonsgegevens geeft geen concrete bewaartermijn voor persoonsgegevens. De regeling regelt dat persoonsgegevens niet langer bewaard mogen worden dan noodzakelijk is voor de doeleinden waarvoor zij zijn verzameld of worden gebruikt. De kraamzorgaanbieder bepaalt aan de hand van het doel hoelang de gegevens bewaard moeten worden. Dit is een algemene regel waarvan de uitwerking per situatie kan verschillen. Ten behoeve van de bedrijfsvoering en overdracht is bewaren van overige documenten anders dan medische gegevens, noodzakelijk.</p>

                    <p className="mt-4">ARTIKEL 12 - GEGEVENSVERSTREKKING EN VERLENING VAN INZAGE DOOR DE KRAAMZORGAANBIEDER AAN DERDEN<br />
                    De meldcode zoals genoemd in lid 1 is opgesteld door organisaties zoals de KNMG en V&amp;VN.</p>

                    <p className="mt-4">ARTIKEL 12 - LID 2<br />
                    Hierin wordt aangegeven dat onder &quot;derden&quot; niet worden verstaan degenen die rechtstreeks betrokken zijn bij de uitvoering van de overeenkomst. Dit betekent dat de gegevens intern bij de kraamzorgaanbieder gebruikt worden ten behoeve van de individuele kraamzorg aan de betrokken cli&euml;nte en bijvoorbeeld voor interne kwaliteitsbewaking en (financi&euml;le) administratie.<br />
                    <br />
                    ARTIKEL 12 - LID 3<br />
                    De veronderstelde toestemming bij overlijden is gebaseerd op de geldende regelgeving. Indien er geen sprake is van door cli&euml;nte gegeven toestemming voor dossierinzage door een derde, kan het beroepsgeheim na overlijden slechts worden doorbroken als kan worden uitgegaan van veronderstelde toestemming van die cli&euml;nte of als de belangen van nabestaanden bij inzage zodanig zwaarwegend zijn dat zij in redelijkheid behoren te worden geplaatst boven het belang dat de geheimhoudingsplicht behoort te beschermen. Voorbeelden zijn gegevens die nodig zijn bij een erfelijkheidsonderzoek.</p>

                    <p className="mt-4">ARTIKEL 12 - LID 4<br />
                    Deze beroepscodes zijn opgesteld door beroepsorganisaties zoals V&amp;VN en NBvK. Ook kan de geheimhoudingsplicht contractueel voortvloeien bijvoorbeeld uit de arbeidsovereenkomst zodat bijvoorbeeld ook de administratief medewerker gehouden is aan de geheimhoudingsplicht.<br />
                    ARTIKEL 13 - LID 1 KRAAMZORG<br />
                    De normen waarnaar wordt verwezen in lid 1 zijn opgenomen in de visie op Verantwoorde Kraamzorg die onder andere door BTN, ActiZ, IGZ, diverse beroepsgroepen en pati&euml;nten- /cli&euml;ntenorganisaties zijn opgesteld.<br />
                    <br />
                    14 ARTIKEL 14 - ARTIKEL 14 IN RELATIE MET ARTIKEL 18 LID 4 VERPLICHTINGEN VAN DE CLI&Euml;NTE Hieronder wordt onder andere verstaan dat de cli&euml;nte verantwoordelijk is voor de aanwezigheid van deugdelijk en veilige materialen die door de kraamverzorgende gebruikt moeten worden. Daarnaast moet de zorgaanbieder ook conform artikel 14 gebruik maken van deugdelijk materiaal.<br />
                    <br />
                    ARTIKEL 19 - LID 2 EN 3 BETALING<br />
                    Voor de overeengekomen aanvullende kraamzorg die voor rekening van de cli&euml;nte komt, de eigen bijdrage en voor de overeengekomen diensten die voor rekening van de cli&euml;nte komen, krijgt zij een gespecificeerde factuur.<br />
                    <br />
                    ARTIKEL 21 - LID 1 A OPZEGGING DOOR DE KRAAMZORGAANBIEDER<br />
                    Wat onder gewichtige redenen moet worden verstaan is afhankelijk van de omstandigheden. In het algemeen zal sprake zijn van een gewichtige reden als de cli&euml;nte geen medewerking verleent om de kraamzorgaanbieder in staat te stellen zich aan de regelgeving omtrent arbeidsomstandigheden te houden (art. 18 lid 5). De arbeidsinspectie zal in dat geval immers de kraamzorgaanbieder beletten de overeenkomst verder uit te voeren (of een boete opleggen als de kraamzorgaanbieder de regels overtreedt). Op grond van het verleden blijkt dat opzegging wegens gewichtige redenen onder bijzondere omstandigheden toelaatbaar wordt geacht. Bij de vraag of er in geval van gedrag als bedoeld in artikel 21 lid 3 of lid 5 voldoende gewichtige redenen zijn, wordt rekening gehouden met de vraag of sprake is van een ernstige mate van bedreiging en/of intimidatie die de situatie onwerkbaar maakt en/of de vertrouwensrelatie onherstelbaar heeft verstoord, dan wel een ernstige verstoring van de dagelijkse gang van zaken die de zorgverlening aan anderen in gevaar brengt. Zowel de handelwijze van de cli&euml;nte, als die van diens partner/familie jegens de kraamverzorgende/kraamzorgaanbieder kan van belang zijn. Doorgaans zal, tenzij de situatie zeer acuut en ernstig is, opzegging van de overeenkomst wegens gewichtige redenen niet zonder meer kunnen geschieden. Met name wordt ook gekeken naar de zorgvuldigheid van handelen door de kraamzorgaanbieder. Gelet op de praktijk zullen ten aanzien van deze zorgvuldigheid doorgaans de volgende vereisten gelden:<br />
                    1. Er dient meerdere malen op verandering van het gedrag gewezen te zijn en deze aanwijzingen dienen bij voorkeur in het kraamzorgplan te worden opgenomen.<br />
                    2. De cli&euml;nte (en familie) moet worden gewezen op de gevolgen van het niet nakomen van de afspraken inzake de verandering van het gedrag.<br />
                    <br />
                    ARTIKEL 21 - SUB B Wat onder &ldquo;passend&rdquo; moet worden verstaan, kan uiteenlopen van een goede andere kraamzorgaanbieder zoeken tot het alleen maar inlichten van de zorgverzekeraar in het extreme geval dat doorgaan met het verlenen van de zorg redelijkerwijs niet kan worden verwacht, bijvoorbeeld bij ernstig misdragen door de cli&euml;nte of gezinsleden. Wat passend is, is dus sterk afhankelijk van de omstandigheden.<br />
                    <br />
                    ARTIKEL 22 - KLACHTENREGELING De kraamzorgaanbieder houdt zich aan de klachtenregeling van de brancheorganisatie Bo Geboortezorg.<br />
                    <br />
                    ARTIKEL 23 - De Geschillencommissie beslist in de vorm van een bindend advies. Dit betekent dat het geschil niet meer voorgelegd kan worden aan de rechter. Er is dus geen hoger beroep mogelijk.<br />
                    <br />
                    ARTIKEL 24 - WIJZIGING Deze algemene voorwaarden zijn opgesteld door BO Geboortezorg enerzijds en de Consumentenbond, de LOC Zeggenschap in de zorg en de Pati&euml;nten federatie Nederland anderzijds. Deze set kan niet inhoudelijk door de kraamzorgaanbieder gewijzigd worden tenzij bovengenoemde partijen nieuwe algemene voorwaarden opstellen.</p>

                    <p className="mt-4">&nbsp;</p>

                    <p className="mt-4">&nbsp;</p>

                    <p className="mt-4">&nbsp;</p>
                </div>

            </div>
        </div>

        <AanvragenAndPostcodeCheck />




        <Footer />

		<Cookie />

    </Fragment>
  );
}


function PageReviews() {

    const totalReviews = 8;

    const [loadedReviews, setLoadedReviews] = useState([
        {},{},{}
    ])

    const moreReviews = () => {
        if(loadedReviews.length < totalReviews) {
            setLoadedReviews([...loadedReviews, {}, {}, {}]);
        }
    }


    return <Fragment>

        <div className="mt-8 px-4 lg:px-32 grid grid-col-1 gap-8">
                  
            {loadedReviews.map((x) => {
                return <PageReview />
            })}

        </div>

        { loadedReviews.length < totalReviews && <div className="mt-8 flex justify-center content-center px-4">
            <div className="border-pink hover:bg-grey hover:bg-opacity-10 border py-2 px-16 rounded-lg text-pink flex hover:cursor-pointer w-full lg:w-auto content-center justify-center" onClick={() => moreReviews()}>
                <div className="text-lg font-bold">Lees meer</div><img alt="pijl naar beneden" className="pl-2" src="/assets/images/icon-arrow-down-pink.svg" />
            </div>
        </div> }

    </Fragment>


}

function PageReview() {

    return <div className={`bg-white border border-solid text-base tracking-normal md:block rounded-xl`}>
        <div className="flex justify-between">
            <div className="p-3 w-min-content">
                <img alt="foto persoon" src="/assets/images/test-review-profile2.png" /> 
            </div>
            <div className="py-3 bg-gray flex-auto">
                <h3 className="font-bold">1. Nola van donselaar</h3>
                <img alt="ster" key={"el" + (1).toString()} className="inline pr-1"  src="/assets/images/review-star.svg" />
                <img alt="ster" key={"el" + (2).toString()} className="inline pr-1"  src="/assets/images/review-star.svg" />
                <img alt="ster" key={"el" + (3).toString()} className="inline pr-1"  src="/assets/images/review-star.svg" />
                <img alt="ster" key={"el" + (4).toString()} className="inline pr-1"  src="/assets/images/review-star.svg" />
                <img alt="ster" key={"el" + (5).toString()} className="inline pr-1"  src="/assets/images/review-star.svg" />
            </div>
            <div className="w-min-content p-3">
                <img alt="google" src="/assets/images/google.svg" />
            </div>
        </div>
        <div className="p-3 text-lg">
            Amazing customer support and fantastic product to showcase your reviews on your website. Highy recommended.
            Amazing customer support and fantastic product to showcase your reviews on your website. Highy recommended.
            Amazing customer support and fantastic product to showcase your reviews on your website. Highy recommended.
        </div>
    </div>

}




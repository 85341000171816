import { Fragment, useState } from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Reviews from '../Components/Reviews';
import Cookie from '../Components/Cookie';
import RequestButton from '../Components/RequestButton';
import LimitedPlacesNotification from '../Components/LimitedPlacesNotification';
import ShortSummaryLogos from '../Components/ShortSummaryLogos';
import { Link } from 'react-router-dom';
// #DB1F6A

export default function Home({ place, seaPlace }) {
  return (
    <Fragment>
        
        <Header />

		<LimitedPlacesNotification />

		<div className="md:container-fluid bg-homepage-header-sm lg:bg-homepage-header md:h-[25rem] lg:h-30-rem bg-no-repeat md:bg-center lg:bg-gray-700 header-bg-clip bg-cover">
			<div className="container mx-auto p-4 md:p-8 lg:p-16 flex justify-end">
				<div className="w-4/6 md:w3/5 lg:w-2/5 bg-black bg-opacity-40 box-border rounded-lg z-10 relative">
					<h1 className="text-xl lg:text-4xl text-white p-4 lg:p-8 font-ro-sans font-bold">De beste kraamzorg in {seaPlace || 'jouw regio!'}</h1>
					<div className="px-4 pb-4 lg:px-8 lg:pb-8 text-white text-lg">
					
						<Link to={`/ervaringen`} className="flex text-blue-darker tracking-none flex-row flex-wrap gap-2 lg:text-lg">

							<div className="pt-1 text-white">4,9</div>
							<div className="flex flex-row gap-1 max-h-max">
								<img className="w-4 lg:w-5"  src="/assets/images/review-star.svg" />
								<img className="w-4 lg:w-5"  src="/assets/images/review-star.svg" />
								<img className="w-4 lg:w-5"  src="/assets/images/review-star.svg" />
								<img className="w-4 lg:w-5"  src="/assets/images/review-star.svg" />
								<img className="w-4 lg:w-5"  src="/assets/images/review-star.svg" />
							</div> 
							<div className="px-2 py-1 bg-[rgba(255,255,255,0.4)] rounded-lg">
								1716 Google Reviews
							</div>

						</Link>

						<div className="hidden md:block">
							<RequestButton className="hidden md:mt-8 md:inline-block" />
						</div>
					</div>
				</div>
			</div>
		</div>

		<div className="md:container-fluid">
			<div className="container mx-auto flex flex-col lg:flex-row">
				<div className="px-4 md:px-8 lg:p-8 md:border-solid md:border md:border-gray-200 md:rounded-xl md:-mt-32 bg-white md:order-1 order-2">
					<h2 className="font-ro-sans text-2xl md:text-4xl py-4 md:py-8 text-center md:text-left font-bold">Waarom zijn wij de beste?</h2>
					<ul className="text-xl font-ro-sans ">
						<li className="py-3 pt-3 flex items-center">
							<div className="w-60-px min-w-60-px"><img src="/assets/images/usp-50-plus.svg"/></div>
							<div className="pl-4 md:pl-8">Ruim <strong>50.000 tevreden moeders</strong> gingen jou voor</div>
						</li>
						<li className="py-3 flex items-center">
							<div className="w-60-px min-w-60-px"><img src="/assets/images/usp-star.svg"/></div>
							<div className="pl-4 md:pl-8">Hoogst gewaardeerde kraambureau met <strong>meer dan 1.5k+ beoordelingen</strong></div>
						</li>
						<li className="py-3 flex items-center">
							<div className="w-60-px min-w-60-px"><img src="/assets/images/usp-all-nurses.svg"/></div>
							<div className="pl-4 md:pl-8">Wij werken samen met <strong>alle verloskundigen</strong></div>
						</li>
						<li className="py-3 flex items-center">
							<div className="w-60-px min-w-60-px"><img src="/assets/images/usp-96.svg"/></div>
							<div className="pl-4 md:pl-8">Ruim 96% raden onze <strong>ervaren kraamverzorgenden</strong> aan!</div>
						</li>
						<li className="py-3 flex items-center">
							<div className="w-60-px min-w-60-px"><img src="/assets/images/usp-all-insurances.svg"/></div>
							<div className="pl-4 md:pl-8"><strong>Alle zorgverzekeraars</strong> vergoeden onze zorg</div>
						</li>
						<li className="py-3 flex items-center">
							<div className="w-60-px min-w-60-px"><img src="/assets/images/usp-borstvoeding.svg"/></div>
							<div className="pl-4 md:pl-8">Wij zijn een <strong>Borstvoeding</strong> en <strong>ISO/HKZ</strong> gecertificeerde instelling</div>
						</li>
					</ul>
				</div>
				<div className="flex flex-col md:order-2 order-1 lg:max-w-[50%]">
					<div className=" px-8 pb-4 md:p-16 md:pb-0 flex justify-between order-2 md:order-1">
						<img alt="logo hkz" src="/assets/images/homepage-image-logo-hkz.svg" className="object-scale-down max-w-20-per lg:max-w-none grayscale" />
						<img alt="logo zorg voor borstvoeding" src="/assets/images/homepage-image-logo-zorgvoorborstvoeding.svg" className="object-scale-down  max-w-20-per lg:max-w-none grayscale" />
						<img alt="logo bo" src="/assets/images/homepage-image-logo-bo.svg" className="object-scale-down max-w-20-per lg:max-w-none grayscale" />
						<img alt="logo klanttevredenheid" src="/assets/images/homepage-image-logo-96.svg" className="object-scale-down max-w-20-per lg:max-w-none grayscale" />
					</div>
					<div className="px-8 py-4 md:p-16 md:pb-0 order-1 md:order-2">
						<RequestButton className="md:hidden block md:inline-block mb-8" />
						<div className="relative">
							<video controls="controls" className="object-scale-down" poster="/assets/images/waarom-kraamzorg-mama.jpg"  id="kzmvideotv" title="Kraamzorg Mama">
								<source src="/assets/video/waarom-kraamzorg-mama.mp4" />
							</video>
						</div>
					</div>
				</div>
			</div>
		</div>


		<div className="md:container-fluid bg-gray-design">
			<div className="container mx-auto justify-end mt-8 pb-8 pt-0 md:mt-16 md:pb-16">
				<h2 className="font-ro-sans text-2xl md:text-3xl text-center p-4 pt-8 lg:pt-16 lg:pb-8 font-bold leading-none">Wat vinden andere moeders van onze kraamzorg?</h2>
				<Reviews />
				<div className="text-center">
					<Link to="/ervaringen" className="text-blue font-bold flex px-4 justify-center lg:text-xl hover:underline">
						<img alt="pijltje links" src="/assets/images/left-arrow-blue.svg" className="inline pr-2" /> 
						Lees meer ervaringen van andere moeders
					</Link>
				</div>
			</div>
		</div>

		<div className="md:container-fluid">
			<div className="container mx-auto mt-8 md:mt-16 grid grid-cols-1 lg:grid-cols-2 gap-8 p-4 md:p-0 ">
				<div className="border rounded-xl">
					<img alt="alle zorgverzekeraars" src="/assets/images/homepage-image-allezorgverzekeraars.jpg" className="w-full"/>
					<div className="p-4 md:p-8">
						<h2 className="font-ro-sans text-2xl md:text-3xl font-bold">Alle zorgverzekeraars vergoeden onze zorg!</h2>
						<p className="py-4 text-xl  font-ro-sans">
							Kraamzorg Mama heeft contacten met alle zorgverzekeraars. Dat houdt in dat wij de basisvergoeding
							rechtstreeks verrekenen met jouw zorgverzekeraar. Je hoeft ons dus niets voor te schieten.
						</p>
						<Link to="/verzekering.html" className="text-blue font-bold lg:text-xl flex justify-start hover:underline">
							<img alt="pijl links" src="/assets/images/left-arrow-blue.svg" className="inline pr-2" /> 
							Lees meer over kosten en verzekering</Link>
					</div>
				</div>
				<form method="post" action="/controleer-postcode.html" className="bg-gray-design p-4 md:p-8 rounded-xl">
					<h2 className="font-ro-sans text-2xl md:text-3xl font-bold">Waar leveren wij kraamzorg?</h2>
					<p className="py-4 text-xl">Wij leveren kraamzorg in heel Zuid-Holland, Utrecht, Breda en in de Bollenstreek. Vul je postcode
					 in en <strong>check meteen</strong> of wij zorg leveren in jouw stad of gemeente</p>
					<div className="text-center">
						<img alt="kaart nederland" src="/assets/images/map-nl.svg" className="inline-block w-3/6 md:w-5/12" />
					</div>
					<div className="grid grid-cols-2 gap-2 md:gap-4 mt-8">
						<div className="border px-2 md:px-4  rounded-md md:rounded-lg bg-white">
							<input name="zipcode" className="outline-none bg-location-pin bg-right bg-no-repeat w-full py-2 md:py-3" type="number" placeholder="Jouw postcode" />
						</div>
						<div>
							<button type="submit" className="bg-green text-white text-center font-bold px-4 md:px-8 py-2 md:py-3 rounded-md md:rounded-lg block hover:bg-green-dark">Check postcode</button>
						</div>
					</div>
				</form>
			</div>
		</div>

		{ place && <div className="md:container-fluid">
			<div className="container mx-auto mt-8 lg:mt-16 grid grid-cols-1 lg:grid-cols-2 gap-8">
				<div className="px-4 lg:px-0 py-4 order-2 lg:order-1">
					<h2 className="font-ro-sans text-2xl md:text-3xl font-bold">Kraamzorg {place}</h2>
					<p className="py-4 text-xl font-ro-sans">
						Je bent zwanger en je zoekt de beste kraamzorg in {place}. Een organisatie met de juiste kennis 
						en ervaring. Wij begrijpen dat! Onze organisatie staat al jaren goed aangeschreven bij cliënten, verloskundigen 
						en álle zorgverzekeraars. Dit komt doordat wij in {place} en omstreken werken met ervaren kraamverzorgenden 
						die met hun hart werken. Je kunt er dus van uitgaan dat je kraamzorg bij ons altijd goed geregeld is.

					</p>
					<p className="py-4 text-xl font-ro-sans">
						Per jaar kiezen honderden gezinnen in <strong>{place} en omstreken</strong> voor het kraambureau dat alom het best is 
						gewaardeerd. Ons kraambureau heeft keer op keer heeft bewezen, uitstekende kraamzorg te verlenen. 
						Dankzij onze kraamverzorgenden zijn er overal in {place} wel gezinnen te vinden waar alles naar wens, én 
						op rolletjes verloopt. Wij voelen ons daarom verheugd, en bovenal vereerd, om ook jou als cliënt te mogen verwelkomen!


					</p>
				</div>
				<div className="order-1 lg:order-2">
					<img alt="regel je kraamzorg" src="/assets/images/homepage-image-regeljekraamzorg.jpg" />
				</div>
			</div>
		</div> }



		<div className="md:container-fluid">
			<div className="container mx-auto mt-8 lg:mt-16 grid grid-cols-1 lg:grid-cols-12 gap-0">
				<div className="md:col-span-7">
					<img alt="regel je kraamzorg" src="/assets/images/homepage-image-regeljekraamzorg.jpg" />
				</div>
				<div className="md:col-span-5 relative flex content-center ">
					<div className="p-4 md:p-8 border rounded-xl relative lg:absolute lg:-left-32 lg:top-8 lg:bottom-8 bottom-8 mx-4 lg:mx-0 lg:top-8 bg-white flex flex-col">
						<h2 className="font-ro-sans text-2xl md:text-3xl font-bold">Regel je kraamzorg op tijd</h2>
						<p className="mt-4 text-xl">
							<strong>Wij hebben een beperkt aantal plaatsen beschikbaar.</strong> Meld je daarom zo
							vroeg mogelijk aan. Wij adviseren je om je aan te melden <strong>voor de 12e</strong> week
							van je zwangerschap. Op die manier loop je <strong>niet het risico</strong> om je 
							kraamtijd in te gaan <strong>zonder</strong> kraamzorg.
						</p>
						<div className="text-right md:justify-self-end mt-4 mb-8 md:mt-4">
							<RequestButton className=" md:mt-4 block md:inline-block" />
						</div>
					</div>
				</div>
			</div>
		</div>

		<div className="md:container-fluid bg-gray-design">
			<div className="container mx-auto py-4 md:py-8 px-4">
				<h2 className="font-ro-sans text-2xl md:text-3xl text-center font-bold ">Kraamzorg Mama in het kort</h2>
				<ShortSummaryLogos />
			</div>
		</div>

        <Footer />

		<Cookie />
		


{/* 
		<div className="">
			<div className="container mx-auto p-4 border-box">
				<h1 className="font-ro-sans text-3xl">Welkom bij kraamzorgmamam</h1>
				{(() => {
					let els = [];
					for(let i = 0; i < 500; i++) {
						els.push(<Fragment key={i.toString()}>{i + ' content line in sentence how about that'}<br /></Fragment>)
					}
					return els;
				})()}
			</div>
		</div> */}
    </Fragment>
  );
}





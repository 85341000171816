import { Fragment, useState } from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Reviews, { Review } from '../Components/Reviews';
import RequestButton from '../Components/RequestButton';
import LimitedPlacesNotification from '../Components/LimitedPlacesNotification';
import Cookie from '../Components/Cookie';
import ShortSummaryLogos from '../Components/ShortSummaryLogos';
import AanvragenAndPostcodeCheck from '../Components/AanvragenAndPostcodeCheck';
import { Link } from 'react-router-dom';

// #DB1F6A

export default function Intake() {
  return (
    <Fragment>
        
        <Header activePage={`/intakegesprek.html`} />

		<LimitedPlacesNotification />

		<div className="md:hidden">
			<img alt="vrouwen praten over intake" className="header-bg-clip bg-cover" src="/assets/images/intakekraamtijd-header-sm.jpg" />
		</div>

		<div className="md:container-fluid md:bg-intake-header md:h-[24rem] md:bg-no-repeat md:bg-center  md:header-bg-clip bg-cover">
			<div className="container mx-auto py-4 lg:py-14 flex justify-start">
				<div className="md:w-[65%] lg:w-[45%] md:bg-black md:bg-opacity-40 md:box-border md:rounded-lg md:z-10 md:relative">
					<h1 className="text-2xl lg:text-4xl md:text-white p-4 lg:py-4 lg:px-8 lg:pb-4 font-ro-sans font-bold">Intake en kraamtijd</h1>
					<div className="px-4 pb-2 lg:px-8 lg:pb-8 md:text-white text-lg">
						

						<ul className="text-lg font-ro-sans ">
							<li className="py-2 pt-3 flex items-center">
								<div className="w-[30px] min-w-[30px] h-[30px] bg-white p-1 rounded-full flex place-content-center place-items-center"><img className="max-h-[20px]" src="/assets/images/usp-borstvoeding.svg"/></div>
								<div className="pl-4 md:pl-4">Jouw huis, jouw kindje, jouw kraamtijd!</div>
							</li>
							<li className="py-2 flex items-center">
								<div className="w-[30px] min-w-[30px] h-[30px] bg-white p-1 rounded-full flex place-content-center place-items-center"><img className="max-h-[20px]" src="/assets/images/usp-ervaren.svg"/></div>
								<div className="pl-4 md:pl-4">Ervaren kraamverzorgende met ruime kennis</div>
							</li>
							
						</ul>
						<div className=" md:block">
							<RequestButton className=" md:mt-4 md:inline-block" />
						</div>
					</div>
				</div>
			</div>
		</div>

		<div className="md:container-fluid">
			<div className="container mx-auto mt-8 md:mt-4 lg:mt-16 grid grid-cols-1 lg:grid-cols-12 gap-0">
                <div className="lg:col-span-6 relative flex content-center order-2 lg:order-1 ">
					<div className="p-4 lg:p-8 border rounded-xl relative lg:absolute lg:-right-64 lg:top-8 lg:bottom-8 bottom-8 mx-4 lg:mx-0 lg:top-8 bg-white flex flex-col place-center">
						<h2 className="font-ro-sans text-2xl lg:text-2xl font-bold text-pink">Jouw wensen, onze zorg</h2>
						<h3 className="font-ro-sans text-2xl lg:text-3xl font-bold">Wij komen bij jou thuis en begrijpen dat we ons moeten aanpassen. Het is tenslotte jouw huis, jouw kindje en jouw kraamtijd.</h3>
						<p className="mt-4 text-xl order-1 ">
                            Tijdens het intakegesprek zal de zorgconsulente al je wensen vastleggen in een zorgplan(boekje). Na de bevalling werkt de kraamverzorgende 
                            met dit boekje zodat ze precies weet waar ze rekening mee moet houden. Heb je bijvoorbeeld <strong>andere kinderen</strong> die naar <strong>school</strong> moeten? 
							Wil je dat jouw kraamverzorgende rekening houdt met bepaalde <strong>culturele of religieuze normen en waarden</strong>? 
                            Liever schoenen aan of uit bij jou thuis? Of een kraamverzorgende die om kan gaan met <strong>jouw hond of kat</strong>? Geen probleem, jouw wensen, onze zorg! 
						</p>
					</div>
				</div>
				<div className="lg:col-span-6 order-1 lg:order-2">
					<img alt="jouw wensen groot" className="hidden lg:block" src="/assets/images/intakekraamtijd-image-jouwwensen.png" />
                    <img alt="jouw wensen klein" className="lg:hidden block" src="/assets/images/intakekraamtijd-image-jouwwensen-sm.png" />
				</div>
			</div>
		</div>


        <div className="md:container-fluid ">
			<div className="container mx-auto mt-8 pt-0 md:mt-16 grid grid-cols-1 lg:grid-cols-2 md:bg-gray-design">

                <div>
                    <img alt="groot samen zorgen" className="hidden lg:block" src="/assets/images/intakekraamtijd-image-samenzorgen.png" />
                    <img alt="klein samen zorgen" className="lg:hidden block" src="/assets/images/intakekraamtijd-image-samenzorgen-sm.png" />
                </div>
                <div className="p-4 md:p-8 lg:p-16">
                    <h2 className="font-ro-sans text-2xl lg:text-2xl font-bold text-pink">Samen zorgen we voor de beste start</h2>
                    <h3 className="font-ro-sans text-2xl lg:text-3xl font-bold">Of dit nou je eerste of je vierde kindje is, jouw pasgeborene is het belangrijkste van de hele wereld. Niet alleen voor jou, maar ook voor je partner en voor de eventuele trotse broers of zussen. Wij begrijpen dat. </h3>
                    <p className="mt-4 text-xl">
                        De goede zorg waarmee je jouw kindje 9 maanden bij je hebt gedragen, gaan wij tijdens jouw kraamtijd voortzetten. Wij zorgen ervoor dat jouw gezin volledig in de watten wordt gelegd. Tijdens de kraamtijd zal jouw kraamverzorgende de gezondheid van je kindje in de gaten houden, maar ook zal ze jou ondersteunen bij de verzorging van je kindje. <strong>Samen zorgen we voor de beste start van je kindje!</strong> 
                    </p>
                    <p className="mt-4 text-xl">
                        Natuurlijk is de zorg voor je pasgeborene de eerste prioriteit, maar wij zullen jou ook zeker niet vergeten. Een bevalling of keizersnede is een ingrijpende gebeurtenis, waar je zowel geestelijk als lichamelijk van moet herstellen. De kraamverzorgende is er daarom ook voor de huishoudelijke taken. Alles om jou en je gezin lekker rust te bieden!
                    </p>
                </div>


            </div>
        </div>

        <div className="md:container-fluid ">
			<div className="container mx-auto mt-8 pt-0 md:mt-8 lg:mt-16 grid grid-cols-1 lg:grid-cols-2">

                <div className="p-4 md:p-0">
                    <h3 className="font-ro-sans text-2xl lg:text-3xl font-bold text-pink flex"><img src="/assets/images/icon-borstvoeding.svg" className="self-center hidden md:block" /> <div className="pl-2 leading-none self-center">Borstvoeding en kunstvoeding</div></h3>
                    <p className="mt-4 text-xl">
                        Ben je van plan om borstvoeding te geven? Dan is het belangrijk dat je hierin vooral geen ongemakken ervaart. 
                        Geen zorgen! Onze kraamverzorgende is daarom ook WHO-borstvoeding gecertificeerd. Mocht je geen borstvoeding kunnen 
                        of willen geven, dan is kunstvoeding een goed alternatief. Onze kraamverzorgenden zijn opgeleid om je met beide 
                        soorten voeding goed te begeleiden.
                    </p>
                    <h3 className="mt-4 font-ro-sans text-2xl lg:text-3xl font-bold text-pink flex"><img src="/assets/images/icon-een-gezicht.svg" className="self-center hidden md:block" /> <div className="pl-2 leading-none self-center">Eén gezicht tijdens jouw kraamtijd</div></h3>
                    <p className="mt-4 text-xl">
                        Wij weten dat het niet leuk is als je in je kraamtijd met verschillende kraamverzorgenden te maken krijgt. Je wilt, 
                        vooral in deze periode, rust en structuur. Daarom zorgen wij voor één gezicht tijdens de gehele kraamtijd. Als er 
                        onverhoopt toch een wisseling plaatsvindt, dan zorgen de kraamverzorgenden onderling voor een goede overdracht.
                    </p>
                </div>

                <div className="px-16 hidden lg:block ">
                    <Review 
                        isIsolated={true}
                        picture="/assets/reviews/anouk.png"
                        className="md:text-xl lg:text-xl"
                        name="Anouk"
                        stars="5">
                        "Na een wat lastige start in het ziekenhuis, thuis hele fijne hulp gehad van Ellen! Ze heeft ons handige tips gegeven en heel fijn ondersteunt in alles. Ellen is dag 9 en 10 gebleven om nog extra te helpen en te ondersteunen met de borstvoeding. Ellen weet van aanpakken en alles is spik en span achter gelaten. Ze heeft een gezellige babbel en het klikte met ons erg goed, ze houdt van een geintje en kon onze humor waarderen. Ze bakt de lekkerste pannekoeken! We vonden het heel jammer dat haar tijd bij ons erop zat. Ellen was echt een hele fijn kraamhulp en wij zullen zeker Kraamhulp Mama bij andere aanraden!"

                    </Review>
                </div>

            </div>
        </div>

		<div className="md:container-fluid">
			<div className="container mx-auto mt-8 pt-0 md:mt-16  bg-gray-design rounded-xl">
				
				<div className="flex flex-col lg:flex-row">

					<div className="lg:w-2/4 px-4 lg:px-0 py-8 lg:py-16 lg:pr-8 lg:pl-16 order-2 lg:order-1">
						
						<h3 className="font-ro-sans text-2xl md:text-3xl pb-4 lg:pb-4 font-bold leading-none">Een ervaren kraamverzorgende, daar kun je op rekenen!</h3>
						<p className="text-xl">
                            Kraamzorg Mama bestaat uit een team van <strong>ervaren</strong> kraamverzorgenden. Dankzij al hun kennis en ervaring kunnen ze jouw gezin de best mogelijke start geven. Natuurlijk hebben zij hun kwaliteit ook gewaarborgd in de vorm van certificaten. Onze kraamverzorgenden zijn in het bezit van het <strong>HKZ-keurmerk,WHO-borstvoedingscertificaat, EHBO- en Reanimatie Certificaat.</strong> Ieder jaar worden onze kraamverzorgende minimaal 4 keer bijgeschoold, <strong>zo weet je zeker dat je de beste zorg krijgt!</strong> Dit is waarom je op hen kunt rekenen:
						</p>

                        <ul className="text-xl font-ro-sans ">
						<li className="py-3 pt-3 flex items-center">
							<div className="w-60-px min-w-60-px"><img alt="borstvoedingscertificaat" src="/assets/images/usp-borstvoeding.svg"/></div>
							<div className="pl-4 md:pl-8">ze zijn in het bezit van het <strong>HKZ-keurmerk</strong> en het <strong>borstvoedingscertificaat</strong>; </div>
						</li>
						<li className="py-3 flex items-center">
							<div className="w-60-px min-w-60-px"><img alt="ehbo" src="/assets/images/usp-ehbo.svg"/></div>
							<div className="pl-4 md:pl-8">ze zijn in het bezit van een <strong>EHBO</strong>- en reanimatie Certificaat;</div>
						</li>
						<li className="py-3 flex items-center">
							<div className="w-60-px min-w-60-px"><img alt="bijgeschoold" src="/assets/images/usp-bijgeschoold.svg"/></div>
							<div className="pl-4 md:pl-8">ze worden jaarlijks door ons <strong>bijgeschoold in onze Kraamschool</strong>;</div>
						</li>
						<li className="py-3 flex items-center">
							<div className="w-60-px min-w-60-px"><img alt="jouw manier van leven" src="/assets/images/icon-home.svg"/></div>
							<div className="pl-4 md:pl-8">ze komen in jouw huis en begrijpen dat zich moet <strong>aanpassen aan jouw manier van leven</strong>.</div>
						</li>

                        <p className="mt-8 pl-4 border-l-8 border-pink">
                            "96% van onze cliënten raden onze kraamverzorgende aan bij familie en vrienden" 
                            <span className="text-base text-grey italic">- klanttevredenheidsonderzoek 2021</span>
                        </p>
					</ul>

					</div>
					<div className="lg:py-16 lg:pl-8 lg:pr-16 order-1 lg:order-2 md:self-center">
						<img alt="ervaren" className="hidden lg:block" src="/assets/images/intakekraamtijd-image-eenervaren.png" />
                        <img alt="ervaren" className="block lg:hidden" src="/assets/images/intakekraamtijd-image-eenervaren-sm.png" />
					</div>

				</div>

				<div className="px-4 lg:px-16 pb-4 lg:pb-8" >
					<h2 className="font-ro-sans text-2xl md:text-3xl pb-4 lg:pb-8 md:text-center font-bold leading-none">Wat vinden andere moeders van onze <span className="text-pink">kraamverzorgende</span></h2>
					<Reviews 
						filterPage="kraamverzorgende"
						reviewClassName="rounded-lg mt-4 md:mt-0"
						disableNavigation={true} />
                    <div className="text-center">
                        <Link to="/ervaringen" className="text-blue font-bold flex px-4 justify-center lg:text-xl hover:underline">
                            <img alt="pijl links" src="/assets/images/left-arrow-blue.svg" className="inline pr-2" /> 
                            Laat meer zien
                        </Link>
                    </div>
				</div>


			</div>
		</div>











		<AanvragenAndPostcodeCheck />

		

        <Footer />

		<Cookie />

    </Fragment>
  );
}





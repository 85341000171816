import React, { useState } from 'react';

export default function LimitedPlacesNotification() {

	const [expanded, setExpanded] = useState(false);

	return <div className="md:container-fluid bg-yellowish">
		<div className="container mx-auto p-2 box-border flex justify-center hover:cursor-pointer" onClick={() => setExpanded(!expanded)}>
			<div className=" md:text-center text-sm lg:text-lg flex-auto lg:flex-initial flex md:justify-self-center">
				<img alt="notificatie" className="inline pr-2 self-center w-8" src="/assets/images/header-notice-icon.svg" /> 
				<div className="self-center">
					<strong>Let op! beperkt aantal plaatsen beschikbaar</strong>
				
				</div>
			</div>
			<div className="h-min block self-start py-2 md:self-center">
				<img alt="pijl" className="pl-2" src={`/assets/images/notice-arrow-${expanded ? 'up' : 'down'}.svg`} />
			</div>
		</div>
		{ expanded && <div className="container mx-auto pb-2 box-border flex ">
			<div className={`px-10`}>
				Meld je zo vroeg mogelijk aan. Wij adviseren je om je aan te melden <strong>voor de 12e week</strong> van je zwangerschap. Op die manier loop je <strong>niet het risico</strong> om je kraamtijd in te gaan zonder kraamzorg.
			</div>
		</div> }
	</div>
}
import { Fragment, useState } from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Cookie from '../Components/Cookie';
import Reviews, { Review } from '../Components/Reviews';
import RequestButton from '../Components/RequestButton';
import LimitedPlacesNotification from '../Components/LimitedPlacesNotification';
import { RatingLogos } from '../Components/ShortSummaryLogos';
import AanvragenAndPostcodeCheck from '../Components/AanvragenAndPostcodeCheck';
import { Link } from 'react-router-dom';
// #DB1F6A

export default function Privacy() {
  return (
    <Fragment>
        
        <Header activePage="/privacy.html" />

		<LimitedPlacesNotification />

		<div className="md:hidden">
			<img alt="twee vrouwen die met elkaar praten" className="header-bg-clip bg-cover" src="/assets/images/intakekraamtijd-header-sm.jpg" />
		</div>

		<div className="md:container-fluid md:bg-ervaringen-header md:h-[24rem] md:bg-no-repeat md:bg-right md:bg-[#666666]  md:header-bg-clip md:bg-contain">
			<div className="container mx-auto py-4 lg:py-7 flex justify-start">
				<div className="md:w-[65%] lg:w-[45%] md:bg-black md:bg-opacity-40 md:box-border md:rounded-lg md:z-10 md:relative">

                    
				</div>
			</div>
		</div>



        <div className="md:container-fluid mt-16">
			<div className="container mx-auto justify-end">
                <div className="p-4 lg:p-0">

                    <h1 className="text-3xl">Privacyverklaring Kraamzorg Mama</h1>

                    <div className="italic text-base">Laatst bijgewerkt 21-08-2024</div>

                    <p className="py-4 text-lg">  
                        Wij waarderen het vertrouwen dat u in ons stelt en zien het als onze verantwoordelijkheid 
                        om uw privacy te beschermen. Op deze pagina informeren wij u over welke gegevens 
                        we verzamelen wanneer u onze website gebruikt, waarom we deze gegevens verzamelen 
                        en hoe we hiermee uw gebruikservaring verbeteren. Wij vragen op onze website alleen het 
                        minimale aan gegevens dat nodig is om onze diensten te verlenen. Dit privacybeleid is van 
                        toepassing op de diensten van Kraamzorg Mama. Door gebruik te maken van deze website, 
                        accepteert u ons privacybeleid.

                    </p>

                    <h2 className="text-2xl py-2">Ons gebruik van verzamelde gegevens</h2>

                    <h3 className="text-xl py-2 italic">Gebruik van onze diensten</h3>

                    <p className="text-lg py-2">
                        Wanneer u zich aanmeldt voor kraamzorg, vragen we u om de volgende persoonsgegevens te verstrekken:
                        <ul>
                            <li> - Naam en postcode*: Om te controleren of wij zorg kunnen verlenen binnen ons werkgebied.</li>
                            <li> - Uitgerekende datum*: Om te beoordelen of er plek is in onze planning.</li>
                            <li> - E-mailadres en telefoonnummer*: Voor het opnemen van contact met u.</li>
                        </ul>
                        Deze gegevens zijn nodig om de zorg te kunnen uitvoeren en worden opgeslagen op beveiligde, NEN-gecertificeerde servers.


                    </p>

                    <h3 className="text-xl py-2 italic">Communicatie</h3>

                    <p className="text-lg py-2">
                        Wanneer u e-mails of andere berichten naar ons stuurt, kan het zijn dat we deze 
                        berichten bewaren. Soms vragen wij om uw persoonlijke gegevens die relevant zijn voor
                        de desbetreffende situatie. Dit stelt ons in staat uw vragen te verwerken en uw 
                        verzoeken te beantwoorden.

                    </p>

                    <h3 className="text-xl py-2 italic">Cookies</h3>
                    
                    <p className="text-lg py-2">
                        Wij maken geen gebruik van cookies op onze website. Dit betekent dat we geen informatie verzamelen 
                        over uw gebruik van onze site via cookies.

                    </p>

                    <h3 className="text-xl py-2 italic">Doeleinden</h3>

                    <p className="text-lg py-2">
                        We verzamelen of gebruiken geen informatie voor andere doeleinden dan de doeleinden die worden 
                        beschreven in dit privacybeleid tenzij we van tevoren uw toestemming hiervoor hebben verkregen.
                    </p>
                    
                    <h3 className="text-xl py-2 italic">Derden</h3>

                    <p className="text-lg py-2">
                        In enkele gevallen kan de informatie intern worden gedeeld met bevoegde medewerkers indien dit 
                        noodzakelijk is voor verlenen van Kraamzorg. Onze medewerkers zijn verplicht om de vertrouwelijkheid 
                        van uw gegevens te respecteren.
                    </p>

                    <h3 className="text-xl py-2 italic">Veranderingen in deze privacyverklaring</h3>
                    <p className="text-lg py-2">
                        Deze privacyverklaring is afgestemd op het gebruik van en de mogelijkheden op deze site. 
                        Eventuele aanpassingen en/of veranderingen van deze site kunnen leiden tot wijzigingen 
                        in deze privacyverklaring. Het is daarom raadzaam om regelmatig deze privacyverklaring te raadplegen.

                    </p>

                    <h3 className="text-xl py-2 italic">Inzien, aanpassen of verwijderen van uw gegevens</h3>

                    <p className="text-lg py-2"> 
                        Wij bieden alle bezoekers de mogelijkheid om alle persoonlijke informatie die op dat 
                        moment aan ons is verstrekt, in te zien, te wijzigen of te verwijderen. Als u uw gegevens 
                        wilt aanpassen of uzelf uit onze bestanden wilt laten verwijderen, kunt u contact met ons 
                        opnemen via onderstaande contactgegevens.
                    </p>



                    <h2 className="text-2xl py-2">Contact</h2>
                    <p className="text-lg py-2">
                        Heeft u vragen over hoe privacy is geregeld bij Kraamzorg Mama? Stuur dan een mail 
                        naar: <Link className="text-blue" to="mailto:sr.kwaliteit@kraamzorgmama.nl">sr.kwaliteit@kraamzorgmama.nl</Link>
                    </p>
                </div>

            </div>
        </div>

        <AanvragenAndPostcodeCheck />




        <Footer />

		<Cookie />

    </Fragment>
  );
}


import { Fragment, useState } from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Cookie from '../Components/Cookie';
import Reviews, { Review } from '../Components/Reviews';
import RequestButton from '../Components/RequestButton';
import LimitedPlacesNotification from '../Components/LimitedPlacesNotification';
import ShortSummaryLogos from '../Components/ShortSummaryLogos';
import AanvragenAndPostcodeCheck from '../Components/AanvragenAndPostcodeCheck';

// #DB1F6A

export default function Kosten() {
  return (
    <Fragment>
        
		<Header activePage={`/verzekeringen.html`} />

		<LimitedPlacesNotification />

		<div className="md:hidden">
			<img alt="kosten en verzekering" className="header-bg-clip bg-cover" src="/assets/images/kostenenverzekering-image-header-sm.png" />
		</div>

		<div className="md:container-fluid md:bg-kosten-header md:h-[24rem] md:bg-no-repeat md:bg-right md:bg-[#EDF4F0]  md:header-bg-clip bg-cover">
			<div className="container mx-auto py-4 pb-8 lg:py-8 flex justify-start border-b lg:border-none">
				<div className="md:w-[65%] lg:w-[45%] md:bg-black md:bg-opacity-40 md:box-border md:rounded-lg md:z-10 md:relative">
					<h1 className="text-2xl lg:text-4xl md:text-white p-4 lg:py-4 lg:px-8 lg:pb-4 font-ro-sans font-bold">Kosten en verzekering</h1>
					<div className="px-4 pb-2 lg:px-8 lg:pb-8 md:text-white text-lg">

						<ul className="text-lg font-ro-sans ">
							<li className="py-2 pt-3 flex items-center">
								<div className="w-[30px] min-w-[30px] h-[30px] bg-white p-1 rounded-full flex place-content-center place-items-center "><img alt="handen" className="max-h-[20px]" src="/assets/images/usp-hands.svg"/></div>
								<div className="pl-4 md:pl-4">Wij hebben contracten met <strong>álle zorgverzekeraars</strong></div>
							</li>
							<li className="py-2 flex items-center">
								<div className="w-[30px] min-w-[30px] h-[30px] bg-white p-1 rounded-full flex place-content-center place-items-center"><img alt="alle verzekeraars" className="max-h-[20px]" src="/assets/images/icon-all-verzekeraars.svg"/></div>
								<div className="pl-4 md:pl-4">Alle zorgverzekeraars vergoeden onze zorg</div>
							</li>

                            <li className="py-2 flex items-center">
								<div className="w-[30px] min-w-[30px] h-[30px] bg-white p-1 rounded-full flex place-content-center place-items-center"><img alt="euro" className="max-h-[20px]" src="/assets/images/icon-euro.svg"/></div>
								<div className="pl-4 md:pl-4">Je hoeft ons niets voor te schieten</div>
							</li>
							
						</ul>
						<div className="mt-4 md:block">
							<RequestButton className=" md:mt-4 md:inline-block" />
						</div>
					</div>
				</div>
			</div>
		</div>

		<div className="md:container-fluid">
			<div className="container mx-auto mt-8 md:mt-4 lg:mt-16 grid grid-cols-1 lg:grid-cols-2">
				
				<div className="self-center lg:pr-16 p-4">
					<h2 className="font-ro-sans text-2xl lg:text-3xl font-bold">Alle zorgverzekeraars vergoeden onze zorg</h2>
					<p className="mt-4 text-xl">
						Wij hebben contracten met álle zorgverzekeraars. Dit houdt in dat wij de kosten voor kraamzorg direct verrekenen met jouw zorgverzekeraar. Je hoeft ons dus niets voor te schieten. 
						Afhankelijk van je verzekering, kan ook je eigen bijdrage geheel of gedeeltelijk worden vergoed. Kom je er niet uit? Wij helpen je graag!
					</p>
				</div>

				<div className="p-4 lg:p-0">
					<div className="relative">
						<video controls="controls" className="object-scale-down"   id="kzmvideotv" title="Kraamzorg Mama">
							<source src="/assets/video/inschrijving-en-verzekering.mp4" />
						</video>
					</div>
				</div>

			</div>
		</div>


		<div className="md:container-fluid">
			<div className="container mx-auto mt-8 md:mt-4 grid grid-cols-1 lg:grid-cols-12 gap-0 lg:mt-16 p-0 lg:p-16 lg:bg-gray-design">
				<div className="lg:col-span-6">
					<img alt="eigen bijdrage" src="/assets/images/kostenenverzekering-image-eigen.png"  />
				</div>
				<div className="lg:col-span-6 relative flex content-center ">
					<div className="p-4 lg:p-8 border rounded-xl relative lg:border-none mx-4 lg:mx-0  bottom-16 lg:bottom-0 bg-white lg:bg-transparent flex flex-col">
						<h2 className="font-ro-sans text-2xl lg:text-2xl font-bold text-pink">
							Eigen bijdrage kraamzorg
						</h2>

						<h3 className="font-ro-sans text-2xl lg:text-3xl font-bold">
							De eigen bijdrage is landelijk bepaald en geldt dus voor alle kraambureaus in Nederland. “Prijzen vergelijken” heeft dus geen énkele zin want de kosten voor kraamzorg zijn bij iedere kraamzorgorganisatie gelijk.
						</h3>

						<p className="mt-4 text-xl">
							Iedereen in Nederland is voor kraamzorg verzekerd in de basisverzekering, wel geldt er een eigen bijdrage als je niet voldoende aanvullende verzekerd bent. De kosten voor de eigen bijdrage is in 2022 vastgesteld op € 4,70 per uur. Ben je aanvullend verzekerd? Dan kan het zijn dat deze eigen bijdrage volledig of gedeeltelijk wordt vergoed.
						</p>
					</div>
				</div>
			</div>
		</div>




		<AanvragenAndPostcodeCheck />

		

        <Footer />

		<Cookie />

    </Fragment>
  );
}





import React, { useEffect,Fragment } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter, useLocation } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';


function ScrollToTop({ history, children }) {

  const { pathname } = useLocation();

  useEffect(() => {
      window.scrollTo(0,0);
  }, [pathname]);

  return <Fragment>
      {children}
  </Fragment>

}

ReactDOM.hydrate(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop>
        <App />
      </ScrollToTop>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { Fragment, useState } from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Reviews from '../Components/Reviews';
import Cookie from '../Components/Cookie';
import RequestButton from '../Components/RequestButton';
import LimitedPlacesNotification from '../Components/LimitedPlacesNotification';
import ShortSummaryLogos from '../Components/ShortSummaryLogos';
import { Link } from 'react-router-dom';
// #DB1F6A

export default function BedanktSollicitation() {
	return (
		<Fragment>
			<Header />
			<div className="md:container-fluid">
				<div className="container mx-auto justify-end mt-8 pb-8 pt-0 md:mt-16 md:pb-16">
					<div className="p-4 lg:p-0 grid grid-cols-1 lg:grid-cols-2">
						<div>
							<h1 className="text-3xl">Bevestiging sollicitatie</h1>
							<p className="mt-4 text-lg">
								Wat leuk dat je interesse hebt in Kraamzorg Mama als werkgever. We gaan je sollicitatie uitgebreid doornemen.
							</p>
							<p className="mt-4 text-lg">
								Mochten we hierna toch nog vragen hebben, dan nemen we even contact met je op.
							</p>
							<p className="mt-4 text-lg">
								Indien je bent geselecteerd voor een kennismakingsgesprek, ontvang je binnen 1 week bericht hierover. Wanneer je binnen 2 weken geen reactie van ons hebt ontvangen, ben je helaas niet geselecteerd voor een kennismakingsgesprek.
							</p>
							<p className="mt-4 text-lg">
								Om je alvast enigszins vertrouwd te maken met onze organisatie, verwijzen we je graag naar onze facebook pagina. Voor vragen kun je contact opnemen met onze afdeling personeelszaken via <Link to="mailto:pz@kraamzorgmama.nl">pz@kraamzorgmama.nl</Link>.
							</p>
						</div>
					</div>
				</div>
			</div>
			<Footer />
			<Cookie />
		</Fragment>
	);
}




/*

	Wat leuk dat je gesolliciteerd en dat je interesse hebt in Kraamzorg Mama als werkgever. We gaan je sollicitatie uitgebreid doornemen.

	Mochten we hierna toch nog vragen hebben, dan nemen we even contact met je op.

	Indien je bent geselecteerd voor een kennismakingsgesprek, ontvang je binnen 1 week bericht hierover. Wanneer je binnen 2 weken geen reactie van ons hebt ontvangen, ben je helaas niet geselecteerd voor een kennismakingsgesprek.

	Om je alvast enigszins vertrouwd te maken met onze organisatie, verwijzen we je graag naar onze facebook pagina. Voor vragen kun je contact opnemen met onze afdeling personeelszaken via pz@kraamzorgmama.nl.

*/
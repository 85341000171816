import { Fragment, useState, useEffect } from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Reviews from '../Components/Reviews';
import Cookie from '../Components/Cookie';
import RequestButton from '../Components/RequestButton';
import LimitedPlacesNotification from '../Components/LimitedPlacesNotification';
import ShortSummaryLogos from '../Components/ShortSummaryLogos';
import { Link } from 'react-router-dom';
// #DB1F6A

export default function Bedankt({ conversion }) {
 
  return (
    <Fragment>
        
        <Header />



        <div className="md:container-fluid">
          <div className="container mx-auto justify-end mt-8 pb-8 pt-0 md:mt-16 md:pb-16">
            <div className="p-4 lg:p-0 grid grid-cols-1 lg:grid-cols-2">
                <div>
                    <h1 className="text-3xl">Bevestiging aanmelding</h1>
                    <p className="mt-4 text-lg">
                        Van harte gefeliciteerd met je zwangerschap en bedankt voor het aanvragen van kraamzorg bij Kraamzorg Mama.
                        We verwerken je aanvragen binnen 2 werkdagen. Wanneer je inschrijving definitief is, ontvang je een
                        telefonische bevestiging van ons. Heb je nog vragen over jouw aanmeldinge?
                        Mail ons dan naar <Link to="mailto:info@kraamzorgmama.nl">info@kraamzorgmama.nl</Link> 
                    </p>
                </div>

            </div>

          </div>
        </div>

        <Footer />
		<Cookie />
		
    </Fragment>
  );
}





import { Link } from "react-router-dom";

export default function ApplyJobButton({ className }) {

	return <Link to="/solliciteer.html" className={`${className} text-white px-4 py-3 bg-green rounded-lg font-bold flex md:block hover:bg-green-dark`}>
		<span className="flex-auto text-center lg:text-left">Solliciteer direct!</span> 
		<img src="/assets/images/request-button-icon.svg" alt="solliciteer direct" className="md:inline md:pl-2"/>
	</Link>

}

import React from 'react';
import Menu from './Menu';
import { Link } from 'react-router-dom';


export default function Header({ activePage }) {

    return <div className="h-16 lg:h-20 bg-pink sticky top-0 z-20">
        <div className="container mx-auto flex h-full justify-between">
            <Link to="/" className="flex lg:flex-initial self-center order-last lg:order-first p-4 lg:p-0">
                <img className="lg:mx-0" src={`/assets/logo-white.svg`} alt="logo" />
            </Link>
            <Menu className="flex-initial self-center order-first lg:order-last p-4 lg:p-0" activePage={activePage} />
        </div>
    </div>

}


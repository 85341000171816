import React, { Fragment, useState } from 'react';

export default function Cookie() {

	const [localShow, setLocalShow] = useState(true);
	let showCookieMessage = false;

	if(typeof window !== 'undefined') {
		if(!localStorage.getItem("cookie")) {
			showCookieMessage = localShow;
		}
	}

	const closeCookie = () => {
		localStorage && localStorage.setItem("cookie", true);
		setLocalShow(false)
	}

	if(!showCookieMessage) {
		return <Fragment />
	}

	return <div className="container-fluid bg-black bg-opacity-70 fixed bottom-0 w-full">

		<div className="container mx-auto">

			<div className="flex p-4 content-center flex-col lg:flex-row">

				
				<div className="flex items-center">
					<div className="h-min pr-2 lg:self-center">
						<img alt="koekje" className="w-64 lg:w-16" src="/assets/images/icon-cookie.svg" />
					</div>
					<p className="text-white text-lg">
						“Kraamzorg Mama vindt jouw privacy uitermate belangrijk, wij verzamelen daarom ook GEEN cookies (gegevens) ”
					</p>
				</div>
				<div className="h-min self-center pl-2 mt-4 lg:mt-0">
					<button onClick={() => closeCookie()} className="text-white px-8 py-2 bg-pink rounded-lg font-bold flex md:block hover:underline">Sluiten</button>
				</div>

			</div>


		</div>

	</div>

}
import RequestButton from "./RequestButton";

export default function AanvragenAndPostcodeCheck() {


    return <div className="md:container-fluid pb-8 md:pb-16">
        <div className="container mx-auto mt-8 pt-4 md:pt-0 md:mt-8 lg:mt-16 md:rounded-xl">
            <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8 lg:gap-16">
                <div className="py-8 md:py-4 lg:py-8 px-4 bg-[#FFFADD] border-l-8 border-[#FCE66C] md:rounded-xl">
                    <h2 className="font-ro-sans text-2xl md:text-3xl lg:pb-4 font-bold leading-none">Regel je kraamzorg op tijd</h2>
                    <p className="text-xl">
                        Slechts een beperkt aantal plaatsen beschikbaar. Meld je daarom zo vroeg mogelijk aan! Zo loop je nooit het risico de kraamtijd in te gaan zonder
                    </p>
                    <div className="text-right  mt-4">
                        <RequestButton className=" md:mt-4 md:inline-block" />
                    </div>
                </div>
                <div className="bg-gray-design p-4 lg:p-8 md:rounded-xl mt-8 md:mt-0">
                    <h2 className="font-ro-sans text-2xl md:text-3xl font-bold">Waar leveren wij kraamzorg?</h2>
                    <p className="py-4 text-xl">Wij leveren kraamzorg in heel Zuid-Holland, Utrecht, Breda en in de Bollenstreek. Vul je postcode
                    in en <strong>check meteen</strong> of wij zorg leveren in jouw stad of gemeente</p>
                    <form method="post" action="/controleer-postcode.html" className="grid grid-cols-2 gap-2 md:gap-4 mt-4">
                        <div className="border px-2 md:px-4  rounded-md md:rounded-lg bg-white">
                            <input name="zipcode" className="outline-none bg-location-pin bg-right bg-no-repeat w-full py-2 md:py-3" type="number" placeholder="Jouw postcode" />
                        </div>
                        <div>
                            <button type="submit" className="bg-green text-white text-center font-bold px-4 md:px-8 py-2 md:py-3 rounded-md md:rounded-lg block hover:bg-green-dark">Direct zoeken</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

}


import { Fragment, useState } from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Cookie from '../Components/Cookie';
import Reviews, { Review, _reviews } from '../Components/Reviews';
import RequestButton from '../Components/RequestButton';
import LimitedPlacesNotification from '../Components/LimitedPlacesNotification';
import { RatingLogos } from '../Components/ShortSummaryLogos';
import AanvragenAndPostcodeCheck from '../Components/AanvragenAndPostcodeCheck';
import { Link } from 'react-router-dom';

export default function Ervaringen() {
  return (
    <Fragment>
        
        <Header activePage="/ervaringen" />

		<LimitedPlacesNotification />

		<div className="md:hidden">
			<img alt="twee vrouwen die de intake bespreken" className="header-bg-clip bg-cover" src="/assets/images/intakekraamtijd-header-sm.jpg" />
		</div>

		<div className="md:container-fluid md:bg-ervaringen-header md:h-[16rem] lg:h-[24rem] md:bg-no-repeat md:bg-right md:bg-[#676b68]  lg:header-bg-clip lg:bg-contain">
			<div className="container mx-auto py-4 lg:py-14 flex justify-start">
				<div className="md:w-[80%] lg:w-[45%] md:bg-black md:bg-opacity-40 md:box-border md:rounded-lg md:z-10 md:relative">
					<h1 className="text-2xl lg:text-4xl md:text-white p-4 lg:py-4 lg:px-8 lg:pb-4 font-ro-sans font-bold">Ervaringen</h1>
					<div className="px-4 pb-2 lg:px-8 lg:pb-8 md:text-white text-lg">
						<p>
							Kraamzorg Mama staat al jaren goed aangeschreven bij clienten, verloskundigen en alle zorgverzekeraars. We
                            kennen een zeer hoge clienttevredenheidscijfer: 97% van de clienten geeft bij de ervaring aan tevreden of zeer tevreden
                            te zijn met onze zorg.
						</p>
					</div>
				</div>
			</div>
		</div>

        <div className="md:container-fluid relative">
			<div className="container mx-auto py-4 md:py-8 px-4 bg-white  border-t lg:border lg:rounded-xl lg:-top-16 relative">
				<h2 className="font-ro-sans text-2xl md:text-3xl text-center font-bold">Hoogst gewaardeerde kraambureau</h2>
				<RatingLogos />
                <p className="pt-8 font-bold text-center">Op basis van meer dan 1.5k+ beoordelingen!</p>
			</div>
		</div>



        <div className="md:container-fluid">
			<div className="container mx-auto justify-end">
                <h2 className="font-ro-sans text-2xl md:text-3xl text-center font-bold text-pink">Lees de ervaringen van andere moeders</h2>

                <PageReviews />


            </div>
        </div>







		<AanvragenAndPostcodeCheck />

        <Footer />

		<Cookie />

    </Fragment>
  );
}


function PageReviews() {

    const totalReviews = _reviews.length;

    const [loadedReviews, setLoadedReviews] = useState([
        ..._reviews.slice(0,3)
    ])

    const moreReviews = () => {
        if(loadedReviews.length < totalReviews) {
            setLoadedReviews([...loadedReviews, ..._reviews.slice(loadedReviews.length, loadedReviews.length + 3)]);
        }
    }


    return <Fragment>

        <div className="mt-8 px-4 lg:px-32 grid grid-col-1 gap-8">
                  
            {loadedReviews.map((x) => {
                return <PageReview review={x} />
            })}

        </div>

        { loadedReviews.length < totalReviews && <div className="mt-8 flex justify-center content-center px-4">
            <div className="border-pink hover:bg-grey hover:bg-opacity-10 border py-2 px-16 rounded-lg text-pink flex hover:cursor-pointer w-full lg:w-auto content-center justify-center" onClick={() => moreReviews()}>
                <div className="text-lg font-bold">Lees meer</div><img className="pl-2" alt="pijl naar beneden" src="/assets/images/icon-arrow-down-pink.svg" />
            </div>
        </div> || 
        
             <Link target="__BLANK" className="border-pink hover:bg-grey hover:bg-opacity-10 border py-2 px-16 rounded-lg text-pink flex hover:cursor-pointer w-full lg:w-auto content-center justify-center" 
                to="//www.google.com/search?q=kraamzorg+mama&rlz=1C5CHFA_enNL933NL933&oq=kraamzorg+mama&aqs=chrome..69i57j46i175i199i512j0i512l2j46i175i199i512j69i61l3.2127j0j7&sourceid=chrome&ie=UTF-8#lrd=0x47c5b16ddc8010c1:0x1b8c574db9796ebb,1">
                <div className="text-lg font-bold">Lees meer alle beoordelingen op Google</div><img className="pl-2" alt="pijl naar beneden" src="/assets/images/icon-arrow-down-pink.svg" />
            </Link>
        }

    </Fragment>


}

function PageReview({ review }) {

    return <div className={`bg-white border border-solid text-base tracking-normal md:block rounded-xl`}>
        <div className="flex justify-between">
            <div className="p-3 w-min-content">
                <img className="w-16" alt={review.name} src={`/assets/reviews/${review.picture}`} /> 
            </div>
            <div className="py-3 bg-gray flex-auto">
                <h3 className="font-bold">{review.name}</h3>
                <img alt="ster" key={"el" + (1).toString()} className="inline pr-1"  src="/assets/images/review-star.svg" />
                <img alt="ster" key={"el" + (2).toString()} className="inline pr-1"  src="/assets/images/review-star.svg" />
                <img alt="ster" key={"el" + (3).toString()} className="inline pr-1"  src="/assets/images/review-star.svg" />
                <img alt="ster "key={"el" + (4).toString()} className="inline pr-1"  src="/assets/images/review-star.svg" />
                <img alt="ster" key={"el" + (5).toString()} className="inline pr-1"  src="/assets/images/review-star.svg" />
            </div>
            <div className="w-min-content p-3">
                <img alt="google" src="/assets/images/google.svg" />
            </div>
        </div>
        <div className="p-3 text-lg">
            {review.text}
        </div>
    </div>

}




import { Fragment, useState } from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Reviews from '../Components/Reviews';
import Cookie from '../Components/Cookie';
import RequestButton from '../Components/RequestButton';
import LimitedPlacesNotification from '../Components/LimitedPlacesNotification';
import ShortSummaryLogos from '../Components/ShortSummaryLogos';
import { Link } from 'react-router-dom';
// #DB1F6A

export default function Page404() {
  return (
    <Fragment>
        
        <Header />



        <div className="md:container-fluid">
          <div className="container mx-auto justify-end mt-8 pb-8 pt-0 md:mt-16 md:pb-16">
            <div className="p-4 lg:p-0">
              <h1 className="text-3xl">404 Oeps!</h1>
              <h2 className="text-3xl mt-4">Sorry, we kunnen deze pagina niet (meer) vinden.</h2>
              <p className="mt-4 text-lg">
                We hebben onze best gedaan, maar het lijkt erop dat deze pagina niet (meer) 
                bestaat of misschien verhuisd is. Je kunt natuurlijk altijd naar de <Link to="/">homepagina</Link> of 
                een andere pagina op onze website.
              </p>

              <ul className="mt-4 text-lg">
                <li className={`flex content-center hover:underline`}>
                  <div className={`inline-block h-fit self-center w-5`} >
                    <img className="w-2" src={`/assets/images/right-arrow.svg`}/>
                  </div>
                  <Link to={`/kraamzorg-aanvragen.html`}>Aanmelden voor kraamzorg</Link>
                  
                </li>
                <li className={`flex content-center hover:underline`}>
                  <div className={`inline-block h-fit self-center w-5`} >
                    <img className="w-2" src={`/assets/images/right-arrow.svg`}/>
                  </div>
                  <Link to={`/intakegesprek.html`}>Intake en kraamtijd</Link>
                  
                </li>
                <li className={`flex content-center hover:underline`}>
                  <div className={`inline-block h-fit self-center w-5`} >
                    <img className="w-2" src={`/assets/images/right-arrow.svg`}/>
                  </div>
                  <Link to={`/verzekering.html`}>Kosten en verzekering</Link>
                  
                </li>
                {/* <li className={`py-2 flex content-center hover:underline`}>
                  <div className={`inline-block h-fit self-center w-5`} >
                    <img className="w-2" src={`/assets/images/right-arrow.svg`}/>
                  </div>
                  <Link to={`/kraamzorg-aanvragen.html`}>Werken bij Kraamzorg Mama</Link>
                  
                </li> */}
                <li className={`py-2 flex content-center hover:underline`}>
                  <div className={`inline-block h-fit self-center w-5`} >
                    <img className="w-2" src={`/assets/images/right-arrow.svg`}/>
                  </div>
                  <Link to={`/veelgestelde-vragen.html`}>Meest gestelde vragen</Link>
                  
                </li>
              </ul>
            </div>

          </div>
        </div>

        <Footer />
		<Cookie />
		
    </Fragment>
  );
}





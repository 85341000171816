import { Fragment, useState } from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Reviews, { Review } from '../Components/Reviews';
import RequestButton from '../Components/RequestButton';
import LimitedPlacesNotification from '../Components/LimitedPlacesNotification';
import Cookie from '../Components/Cookie';
import ShortSummaryLogos from '../Components/ShortSummaryLogos';
import AanvragenAndPostcodeCheck from '../Components/AanvragenAndPostcodeCheck';

// #DB1F6A

export default function Contact() {
  return (
    <Fragment>
        
        <Header activePage={`/contact.html`} />

		<LimitedPlacesNotification />

		<div className="md:hidden">
			<img alt="contact pagina boven afbeelding" className="w-full header-bg-clip bg-cover" src="/assets/images/contact-image-header-sm.png" />
		</div>

		<div className="hidden md:block md:container-fluid md:bg-contact-header md:h-[24rem] md:bg-no-repeat md:bg-center  md:header-bg-clip bg-cover">
			<div className="container mx-auto py-4 lg:py-14 flex justify-start">
				<div className="md:w-[65%] lg:w-[45%] md:bg-black md:bg-opacity-40 md:box-border md:rounded-lg md:z-10 md:relative">
					
				</div>
			</div>
		</div>


        <div className="md:container-fluid mt-0 lg:mt-16">
			<div className="container mx-auto p-8 lg:p-0">

                <div className="lg:grid lg:grid-cols-2 lg:grid-rows-2 lg:gap-8">

                    <div className="border p-4 flex">
                        <div className="pr-2"><img src="/assets/images/icon-home.svg" /></div>
                        <div>
                            <h2 className="text-3xl">Bezoekadres</h2>
                            <p className="text-lg mt-4">
                                Sir Winston Churchilllaan 285<br />
                                2288 DA Rijswijk
                            </p>
                        </div>
                    </div>


                    <div className="border p-4 flex mt-8 lg:mt-0">
                        <div className="pr-2"><img src="/assets/images/icon-phone.svg" /></div>
                        <div>
                            <h2 className="text-3xl">Telefoonnummer</h2>
                            <p className="text-lg mt-4">
                                <strong>Informatielijn</strong>: 088 711 0100 (optie 1)<br />
                                <strong>Bevallingslijn</strong>: 088 711 0100 (optie 3)
                            </p>
                        </div>
                    </div>


                    <div className="border p-4 flex mt-8 lg:mt-0">
                        <div className="pr-2"><img src="/assets/images/icon-at.svg" /></div>
                        <div>
                            <h2 className="text-3xl">E-mail</h2>
                            <p className="text-lg mt-4">
                                <strong>Klantenservice</strong>: info@kraamzorgmama.nl<br />
                                <strong>Personeelszaken</strong>: pz@kraamzorgmama.nl<br />
                                <strong>Administratie</strong>: administratie@kraamzorgmama.nl
                            </p>
                        </div>
                    </div>


                    <div className="border p-4 flex  mt-8 lg:mt-0">
                        <div className="pr-2"><img className="w-8" src="/assets/images/icon-i.svg" /></div>
                        <div>
                            <h2 className="text-3xl">Algemene informatie</h2>
                            <p className="text-lg mt-4">
                                <strong>KVK nummer</strong>: 60553065<br />
                                <strong>AGB nummer</strong>: 33006008
                            </p>
                        </div>
                    </div>



                </div>

            </div>
        </div>








		<AanvragenAndPostcodeCheck />

        <Footer />
		<Cookie />

    </Fragment>
  );
}


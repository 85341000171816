import { Fragment, useState } from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Cookie from '../Components/Cookie';
import { Link } from 'react-router-dom';
import Reviews, { Review } from '../Components/Reviews';
import RequestButton from '../Components/RequestButton';
import LimitedPlacesNotification from '../Components/LimitedPlacesNotification';
import { RatingLogos } from '../Components/ShortSummaryLogos';
// #DB1F6A

export default function NotWorkingZipcode({ showZipcodeMessage }) {

    

  return (
    <Fragment>
        
        <Header activePage="/kraamzorg-aanvragen.html" />



		<div className="hidden md:block md:container-fluid md:bg-aanvragen-header md:h-[24rem] md:bg-no-repeat md:bg-right md:bg-[#D8C6AF]  md:header-bg-clip bg-cover">
			<div className="container mx-auto py-4 lg:py-4 flex justify-center">


            </div>
		</div>

        <div className="md:container-fluid relative font-ro-sans">
			<div className="container mx-auto ">
    
                <div className={`lg:py-10 lg:px-16 lg:bg-white  lg:border-t lg:border lg:-top-64 lg:rounded-xl relative lg:w-3/6 lg:mx-auto`}>

                    


                    <div className="text-2xl lg:text-3xl p-4 lg:p-0">
                        Helaas, in de door jou ingevoerde postcode verleent Kraamzorg Mama geen kraamzorg.
                    </div>

                    <div className="lg:mt-8 p-4 lg:p-0 text-xl">
                        <p>
                            Ga je binnenkort verhuizen en woon je dan wel binnen ons werkgebied, neem dan contact met ons op via:
                        </p>

                        <div className="flex content-center mt-4">
                            <div className="w-10">
                                <img alt="telefoon" className="w-6" src="/assets/images/icon-phone.svg" />
                            </div>
                            <div className="self-center">
                                Telefoonnummer: 088 711 0100
                            </div>
                        </div>

                        <div className="flex content-center mt-4">
                            <div className="w-10">
                                <img alt="@" className="w-6" src="/assets/images/icon-at.svg" />
                            </div>
                            <div className="self-center">
                                E-mail: <Link to="mailto:info@kraamzorgmama.nl">info@kraamzorgmama.nl</Link>
                            </div>
                        </div>
                        

                    </div>

                    <div className="p-4 lg:mt-8">
                        <h2 className="text-2xl lg:text-3xl text-pink">Wij leveren kraamzorg in regio:</h2>
                        <ul className="list-disc list-inside text-xl text-pink mt-4">
                            <li><span className="text-black">Heel Zuid-Holland</span></li>
                            <li><span className="text-black">Heel Utrecht</span></li>
                            <li><span className="text-black">Bollenstreek</span></li>
                            <li><span className="text-black">Breda</span></li>
                        </ul>
                    </div>

                </div>


			</div>
		</div>


        <Footer />

        <Cookie />
    </Fragment>
  );
}

export default function ShortSummaryLogos() {
    return <div className="grid md:grid-cols-3 lg:grid-cols-4 md:grid-rows-3 lg:grid-rows-2 grid-cols-2 grid-rows-4 gap-2 lg:gap-8 px-4 lg:px-32 mt-8">
        
        			
        <ZorgkaartNederlandLogo />
        <BorstvoedingLogo />
        <GoogleLogo />
        <HKZLogo />
        <TrustPilotLogo />
        <AlleZorgverzekeraarsLogo />
        <OwnRatingLogo />
        <BOLogo />
    </div>
}

export function RatingLogos() {
    return <div className="grid md:grid-cols-3 lg:grid-cols-4 md:grid-rows-2 lg:grid-rows-1 grid-cols-2 grid-rows-2 gap-2 lg:gap-8 px-4 lg:px-32 mt-8">
        <GoogleLogo />
        <TrustPilotLogo />
        <ZorgkaartNederlandLogo />
        <OwnRatingLogo />
    </div>
}

export function ZorgkaartNederlandLogo() {
    return <div className="bg-black bg-opacity-5 p-4 text-center flex place-content-center rounded-md">
        <div className="flex flex-col box-border place-content-center place-items-center">
            <div className="bg-purple h-min w-min self-center text-white leading-tight p-2 rounded-full">9.<sup>6</sup></div>
            <img alt="zorgkaart nederland" className="w-[170px]" src="/assets/images/svg-zorgkaart-nl.svg"/>
        </div>
    </div>		
}

export function BorstvoedingLogo() {
    return <div className="bg-black bg-opacity-5 text-center p-4 flex place-content-center rounded-md">
        <img alt="zorg voor borstvoeding" className="object-scale-down" src="/assets/images/homepage-image-logo-zorgvoorborstvoeding.svg" />
    </div>
}

export function GoogleLogo() {
    return  <div className="bg-black bg-opacity-5 text-center flex place-content-center rounded-md">
        <div className="flex  box-border  ">
            <div className="text-black h-min w-min self-center text-white leading-tight  pr-2 font-bold md:font-normal md:text-2xl">4,9/5</div>
            <div className="h-min w-min self-center">
                <img alt="google" className="object-scale-down max-w-[80px] md:max-w-[100px]" src="/assets/images/svg-google.svg"/>
                <div>
                    <img alt="ster" src="/assets/images/yellow-review-star.svg" className="inline"/>
                    <img alt="ster" src="/assets/images/yellow-review-star.svg" className="inline" />
                    <img alt="ster" src="/assets/images/yellow-review-star.svg" className="inline" />
                    <img alt="ster" src="/assets/images/yellow-review-star.svg" className="inline" />
                    <img alt="ster" src="/assets/images/yellow-review-star.svg" className="inline" />
                </div>
            </div>
        </div>
    </div>
}

export function HKZLogo() {
    return <div className="bg-black bg-opacity-5 p-4 text-center flex place-content-center rounded-md">
        <div className="flex flex-col box-border place-content-center place-items-center">
            <img alt="hkz" className="w-[60px] self-center" src="/assets/images/svg-hkz.svg"/>
            <div className="self-center text-white leading-tight p-2 rounded-full text-black text-sm text-center">Kiwa gecertificeerd</div>
        </div>
    </div>
}

export function TrustPilotLogo() {
    return <div className="bg-black bg-opacity-5 text-center flex place-content-center rounded-md">
        <div className="flex  box-border  ">
            <div className="text-black h-min w-min self-center text-white leading-tight  pr-2 font-bold md:font-normal md:text-2xl">4,9/5</div>
            <div className="h-min w-min self-center">
                <img alt="trustpilot" className="object-scale-down max-w-[80px] md:max-w-[100px]" src="/assets/images/svg-trustpilot.svg"/>
                <div>
                    <img alt="trustpilot sterren" src="/assets/images/svg-trustpilot-stars.svg" className="inline"/>
                </div>
            </div>
        </div>						
    </div>
}

export function AlleZorgverzekeraarsLogo() {
    return <div className="bg-black bg-opacity-5 p-4 text-center flex place-content-center rounded-md">
        <div className="flex flex-col box-border">
            <img alt="alle zorgverzekeraars" className="w-[60px] self-center" src="/assets/images/homepage-image-allezorgverzekeraarsvergoeden.svg"/>
            <div className="self-center text-white leading-tight p-2 rounded-full text-black text-sm text-center">Alle zorgverzekeraars vergoeden onze zorg</div>
        </div>
    </div>
}

export function OwnRatingLogo() {
        return <div className="bg-black bg-opacity-5 text-center p-4 flex place-content-center rounded-md">
        <img alt="96" className="object-scale-down" src="/assets/images/homepage-image-logo-96.svg"/>
    </div>
}

export function BOLogo() {
    return <div className="bg-black bg-opacity-5 text-center p-4 flex place-content-center rounded-md">
        <img alt="bo" className="object-scale-down" src="/assets/images/homepage-image-logo-bo.svg"/>
    </div>
}
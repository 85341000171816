import { Fragment, useState } from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Cookie from '../Components/Cookie';
import Reviews, { Review } from '../Components/Reviews';
import RequestButton from '../Components/RequestButton';
import LimitedPlacesNotification from '../Components/LimitedPlacesNotification';
import { RatingLogos } from '../Components/ShortSummaryLogos';
// #DB1F6A

export default function ApplyForJob() {
  return (
    <Fragment>
        
        <Header activePage="/kraamzorg-aanvragen.html" />



		<div className="hidden md:block md:container-fluid md:bg-aanvragen-header md:h-[24rem] md:bg-no-repeat md:bg-right md:bg-[#D8C6AF]  md:header-bg-clip bg-cover">
			<div className="container mx-auto py-4 lg:py-14 flex justify-start"></div>
		</div>

        <div className="md:container-fluid relative font-ro-sans">
			<div className="container mx-auto ">
    
                <div className="lg:py-10 lg:px-16 lg:bg-white  lg:border-t lg:border lg:rounded-xl lg:-top-64 relative lg:w-3/6 lg:mx-auto">

                  

                    <div className="p-4 lg:p-0">
                        <h2 className="lg:mt-8 lg:text-center text-3xl font-bold">Vul je gegevens in</h2>

                        <RequestForm />
                    </div>

                </div>


			</div>
		</div>


        <Footer />

        <Cookie />
    </Fragment>
  );
}

function RequestForm() {

    const [submitted, setSubmitted] = useState(false);

    const [name, setName] = useState('');
    const [place, setPlace] = useState('');
    const [email, setEmail] = useState('');
    const [motivation, setMotivation] = useState('');
    const [cv, setCV] = useState('');

    const [hasDiploma, setHasDiploma] = useState(undefined);
    const [hasDriversLicense, setHasDriversLicence] = useState(undefined);

    const submitForm = () => {

        setSubmitted(true);

    }

    return <form method="post" encType='multipart/form-data' className="mt-4 lg:mt-8">

        <FormInput 
            label="Voor - Achternaam"
            isRequired={true}
            requiredLabel={"Vul je voor en achternaam in"}
            type="text"
            value={name}
            name="name"
            isActivated={submitted}
            onUpdate={(value) => setName(value)} />

        <FormInput 
            label="Woonplaats"
            isRequired={true}
            requiredLabel={"Vul je woonplaats in"}
            type="text"
            value={place}
            name="place"
            isActivated={submitted}
            onUpdate={(value) => setPlace(value)} />

        <FormInput 
            label="Email"
            isRequired={true}
            requiredLabel={"Vul een geldig e-mail in"}
            type="email"
            value={email}
            name="email"
            isActivated={submitted}
            onUpdate={(value) => setEmail(value)} />

        <FormInput 
            label="Motivatiebrief"
            isRequired={false}
            type="textarea"
            value={motivation}
            name="motivation"
            isActivated={submitted}

            onUpdate={(value) => setMotivation(value)} />

        <FormInput 
            label="CV Uploaden"
            isRequired={false}
            type="file"
            value={cv}
            name="cv"
            isActivated={submitted}
            onUpdate={(value) => setCV(value)} />

        <FormInput 
            label="Ben je in het bezit van een diploma kraamverzorgende?"
            isRequired={true}
            requiredLabel={"Geef aan of je in het bezit bent van een diploma"}
            options={[{label: "Ja", value: true}, {label: "Nee", value: false}]}
            type="radio"
            value={hasDiploma}
            name="hasDiploma"
            isActivated={submitted}
            onUpdate={(value) => setHasDiploma(value)} />

        <FormInput 
            label="Ben je in bezit van een rijbewijs?"
            isRequired={true}
            requiredLabel={"Geef aan of je in het bezit bent van een rijbewijs"}
            type="radio"
            options={[{label: "Ja", value: true}, {label: "Nee", value: false}]}
            value={hasDriversLicense}
            name="hasDriversLicense"
            isActivated={submitted}
            onUpdate={(value) => setHasDriversLicence(value)} />

        <button type="submit" className="text-white px-16 mt-8 py-3 bg-green rounded-lg font-bold mx-auto block hover:bg-green-dark">Solliciteer direct</button>

    </form>
}

function FormInput({ className, label, isRequired, requiredLabel, type, onUpdate, value, isActivated, options, name }) {

    const [activated, setActivated] = useState(isActivated);

    let inputActivated = isActivated || activated;

    let formState = "";
    let inputClassName = "";
    if(inputActivated && isRequired) {
        if(value || (type == "radio" && value !== undefined)) {
            formState = "good";
            inputClassName = " border-green";
        } else {
            formState = "bad"
            inputClassName = " border-pink";
        }
    }

    

    return <div className={`mb-4 ${className}`}>
        <div className="text-lg font-bold">
            {label} { isRequired && <sup className="text-red">*</sup> }
        </div>
        <div className="relative">
            {(() => {

                if(type == 'textarea') {
                    return <textarea
                        name={name}
                        onChange={(evt) => onUpdate(evt.target.value)} 
                        value={value} 
                        className={`outline-none p-2 text-lg bg-[#f8f8f8] border rounded-md block w-full ${inputClassName}`} 
                        onFocus={() => setActivated(true) } />
                } else if(type == 'radio') {

                    return <Fragment>
                        <input type="hidden" name={name} value={value ? 'Ja' : 'Nee'} />
                        {options.map((option, index) => {
                            
                            let checkedClassName = option.value === value ? 'bg-pink' : '';

                            return <Fragment key={index.toString()}>
                                <label className="flex content-center hover:cursor-pointer" onClick={() => { onUpdate(option.value); setActivated(true); } }>
                                    <div className={`w-4 h-4 outline-pink outline outline-1 border-2 border-white ${checkedClassName} self-center`}></div>    
                                    <div className="pl-2">
                                        {option.label}
                                    </div>
                                </label>
                            </Fragment>
                        })}
                    </Fragment>


                } else {
                    return <input type={type} 
                        name={name}
                        onChange={(evt) => onUpdate(evt.target.value)} 
                        value={value} 
                        className={`outline-none p-2 text-lg bg-[#f8f8f8] border rounded-md block w-full ${inputClassName}`} 
                        onFocus={() => setActivated(true) } />
                }


            })()}
          
           
            <div className="absolute flex top-0 right-0 h-full p-3">
                { formState == 'bad' && <img src={`/assets/images/icon-form-bad.svg`} /> }
                { formState == 'good' && <img className="w-6" src={`/assets/images/icon-form-good.svg`} /> }
            </div>
        </div>
        <div className="text-pink">
            { formState == 'bad' && requiredLabel}
        </div>
    </div>


}


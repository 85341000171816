import { Fragment, useState } from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Reviews, { Review } from '../Components/Reviews';
import RequestButton from '../Components/RequestButton';
import ApplyJobButton from '../Components/ApplyJobButton';
import LimitedPlacesNotification from '../Components/LimitedPlacesNotification';
import Cookie from '../Components/Cookie';
import ShortSummaryLogos from '../Components/ShortSummaryLogos';
import { Link } from 'react-router-dom';
import moment from 'moment';
// #DB1F6A

export default function Vacancy({ place }) {

    

  return (
    <Fragment>
        
        <Header activePage={`/vacature.html`} />

		<LimitedPlacesNotification />

		<div className="md:hidden">
			<img alt="vacature" className="w-full header-bg-clip bg-cover" src="/assets/images/contact-image-header-sm.png" />
		</div>

		<div className=" md:block md:container-fluid md:bg-contact-header md:h-[35rem] lg:h-[30rem] md:bg-no-repeat md:bg-center  md:header-bg-clip bg-cover">
			<div className="container mx-auto py-4 lg:py-8 flex justify-start">
                <div className="md:w-[95%] lg:w-[55%] md:bg-white md:border md:box-border md:rounded-lg md:z-10 md:relative">
					<h1 className="text-2xl lg:text-4xl p-4 lg:py-4 lg:px-8 lg:pb-4 font-ro-sans font-bold">Waarom kiezen voor Kraamzorg Mama</h1>
					<div className="px-4 pb-2 lg:px-8 lg:pb-8  text-lg">
						

						<ul className="text-xl font-ro-sans ">
							{/* <li className="py-1 lg:py-0 flex items-center">
                                <div className="w-[50px] min-w-[50px] h-[50px] bg-white p-1 rounded-full flex place-content-center place-items-center">
                                    <img alt="duim omhoog" className="max-h-[40px]" src="/assets/images/icon-pink-thumbup.svg"/>
                                </div>
                                <div className="pl-4 md:pl-4"><strong>TIJDELIJK!:</strong> Stap vóór 01 december over en ontvang <strong>5.000,-</strong> welkomstbonus!</div>
                                
                            </li> */}
                            <li className="py-1 lg:py-0 flex items-center">
								<div className="w-[50px] min-w-[50px] h-[50px] bg-white p-1 rounded-full flex place-content-center place-items-center">
                                    <img alt="96" className="max-h-[40px]" src="/assets/images/icon-pink-96.svg"/>
                                </div>
								<div className="pl-4 md:pl-4">Medewerkerstevredenheid score 9.6</div>
							</li>
                            <li className="py-1 lg:py-0 flex items-center">
								<div className="w-[50px] min-w-[50px] h-[50px] bg-white p-1 rounded-full flex place-content-center place-items-center">
                                    <img alt="kleine teams" className="max-h-[40px]" src="/assets/images/icon-pink-small-teams.svg"/>
                                </div>
								<div className="pl-4 md:pl-4">Kleine gezellige regionale zorgteams</div>
							</li>
                            <li className="py-1 lg:py-0 flex items-center">
								<div className="w-[50px] min-w-[50px] h-[50px] bg-white p-1 rounded-full flex place-content-center place-items-center">
                                    <img alt="eigen kraamschool" className="max-h-[40px]" src="/assets/images/icon-pink-school.svg"/>
                                </div>
								<div className="pl-4 md:pl-4">Een eigen kraamschool met een professionele skillab</div>
							</li>
                            <li className="py-1 lg:py-0 flex items-center">
								<div className="w-[50px] min-w-[50px] h-[50px] bg-white p-1 rounded-full flex place-content-center place-items-center">
                                    <img alt="betrokken organisatie" className="max-h-[40px]" src="/assets/images/icon-pink-organisation.svg"/>
                                </div>
								<div className="pl-4 md:pl-4">Betrokkken organisatie; je bent bij ons geen nummer</div>
							</li>
							<li className="py-1 lg:py-0 flex items-center">
								<div className="w-[50px] min-w-[50px] h-[50px] bg-white p-1 rounded-full flex place-content-center place-items-center">
                                    <img alt="duim omhoog" className="max-h-[40px]" src="/assets/images/icon-pink-thumbup.svg"/>
                                </div>
								<div className="pl-4 md:pl-4">Iedereen wordt gehoord, gezien en gewaardeerd</div>
							</li>
							
						</ul>
						<div className="mt-4 lg:mt-0 md:block">
							<ApplyJobButton className=" md:mt-4 md:inline-block" />
						</div>
					</div>
				</div>
			</div>
		</div>


        <div className="md:container-fluid mt-0 lg:mt-16">
			<div className="container mx-auto p-8 lg:p-0">

                <div className="grid grid-cols-1 lg:grid-cols-2 gap-16">
                    {/* <div>
                        <h2 className="text-3xl text-pink">Vacature kraamverzorgende { place || 'Den haag' } en omgeving</h2>
                        <p className="mt-4 text-xl">
                            Belangrijk om te weten is dat onze kraamverzorgenden opereren in een klein en lokaal teamverband.
                            Deze werkopzet wordt door onze kraamspecialisten als prettig en werkbaar ervaren.
                        </p>
                        <p className="mt-4 text-xl">
                            Een bijbehorend pluspunt is dat je bij Kraamzorg Mama - naast een reiskosten vergoeding - ook een reistijdvergoeding ontvangt.
                            Dit is overigens niet gebruikelijk in de kraamzorgmarkt. Maar bij Kraamzorg Mama zorgen we er dus ook voor dat het 
                            financiele plaatje voor je klopt!
                        </p>


                    </div> */}
                      <div>
                            <h2 className="text-3xl text-pink">Teamverband</h2>
                            <p className="mt-4 text-xl">
                                Belangrijk om te weten is dat onze kraamverzorgenden opereren in een klein en lokaal 
                                teamverband. Deze werkopzet wordt door onze kraamspecialisten als prettig en werkbaar ervaren.
                            </p>
                            <h2 className="mt-4 text-3xl text-pink">Werklocaties</h2>
                            <p className="mt-4 text-xl">
                                Ook op werkgebied valt er bij Kraamzorg Mama wat te kiezen. We zijn namelijk actief in de volgende regio’s:
                            </p>


                            <p className="mt-4 text-xl">
                                <ul className="list-disc list-inside px-4">
                                    <li>Den Haag, Delft, Rijswijk, Westland, Zoetermeer en omstreken</li>
                                    <li>Rotterdam, Schiedam, Vlaardingen, Barendrecht en omstreken</li>
                                    <li>Utrecht, Gouda, Bodegraven, Woerden, Zeist, Nieuwegein, Houten en omstreken</li>
                                    <li>Dordrecht, Spijkenisse, Hellevoetsluis, Zwijndrecht, Ridderkerk en omstreken</li>
                                    <li>De Bollenstreek (Hillegom, Lisse, Katwijk, Noordwijk, Teylingen, Leiden, Leiderdorp, Oegstgeest, Voorschoten Wassenaar) en omstreken</li>
                                    <li>Alphen aan de Rijn, Haarlem en Hoofddorp en omstreken</li>
                                    <li>Breda, Roosendaal, Oosterhout, Gorinchem en omstreken</li>
                                </ul>
                            </p>


                    </div>
                    <div>
                        
                        <div className="relative">
							<video controls="controls" className="object-scale-down"  id="kzmvideotv" title="Kraamzorg Mama">
								<source src="/assets/video/werken-bij-kraamzorgmama.mp4" />
							</video>
						</div>
                        {/* <div className={`bg-white border border-solid text-base tracking-normal md:block `}>
                            <div className="flex justify-between">
                                <div className="p-3 w-min-content">
                                    <img alt="foto persoon" src="/assets/images/test-review-profile2.png" /> 
                                </div>
                                <div className="py-3 bg-gray flex-auto">
                                    <h3 className="font-bold">Nola van Donselaar</h3>
                                    {(() => {
                                        let starsElement = [];
                                        for(let i = 1; i <= 5; i++) {
                                            starsElement.push(<img alt="ster" key={"el" + i.toString()} className="inline pr-1"  src="/assets/images/review-star.svg" />)
                                        }
                                        return starsElement;

                                    })()}
                                </div>
                                <div className="w-min-content p-2">
                                    <img alt="google" src="/assets/images/google.svg" />
                                </div>
                            </div>
                            <div className="p-3">
                                Amazing customer support and fantastic product to showcase your reviews on your website. Highy recommended.
                            </div>
                        </div> */}
                    </div>
                </div>
                

            </div>
        </div>


        <div className="md:container-fluid">
			<div className="container mx-auto mt-8 pt-0 md:mt-16  bg-gray-design rounded-xl">
				
				<div className="flex flex-col lg:flex-row">

					<div className="lg:w-2/4 px-4 lg:px-0 py-8 lg:py-16 lg:pr-8 lg:pl-8 order-2 lg:order-1">
						
						<h3 className="font-ro-sans text-2xl md:text-3xl pb-4 lg:pb-4 font-bold leading-none">Wat bieden wij aan?</h3>
					

                        <ul className="text-xl font-ro-sans ">
                            <li className="py-2 flex">
                                <div className="min-w-[40px] pt-1"><img className="w-7" alt="vinkje" src="/assets/images/icon-form-good.svg"/></div>
                                <div className="pl-1 md:pl-1">
                                    Per direct een contract voor <strong className="underline">onbepaalde tijd</strong><sup>*</sup>;
                                </div>
                            </li>
                            <li className="py-2 flex">
                                <div className="min-w-[40px] pt-1"><img className="w-7" alt="vinkje" src="/assets/images/icon-form-good.svg"/></div>
                                <div className="pl-1 md:pl-1">
                                    Dankzij de beschikbaarheid van vier verschillende werkprofielen heb je ook wat te kiezen.
                                    Ga voor het contract dat het beste bij jou past;
                                </div>
                            </li>
                            <li className="py-2 flex">
                                <div className="min-w-[40px] pt-1"><img className="w-7" alt="vinkje" src="/assets/images/icon-form-good.svg"/></div>
                                <div className="pl-1 md:pl-1">
                                    Bij een verandering in je privesfeer ( bijvoorbeeld samenwonen, een huwelijk, de geboorte van een kindje,
                                    enzovoorts) is het eenvoudig switchen van werkprofiel. Daar maken wij geen punt van.
                                </div>
                            </li>
                            <li className="py-2 flex">
                                <div className="min-w-[40px] pt-1"><img className="w-7" alt="vinkje" src="/assets/images/icon-form-good.svg"/></div>
                                <div className="pl-1 md:pl-1">
                                    Je werkt in een regio naar jouw persoonlijke voorkeur, in een gezellig en herkenbaar regioteam;
                                </div>
                            </li>
                            <li className="py-2 flex">
                                <div className="min-w-[40px] pt-1"><img className="w-7" alt="vinkje" src="/assets/images/icon-form-good.svg"/></div>
                                <div className="pl-1 md:pl-1">
                                    Je ontvangt een goed salaris, conform CAO Kraamzorg;
                                </div>
                            </li>
                            <li className="py-2 flex">
                                <div className="min-w-[40px] pt-1"><img className="w-7" alt="vinkje" src="/assets/images/icon-form-good.svg"/></div>
                                <div className="pl-1 md:pl-1">
                                    Je ontvangt een wachtvergoeding, conform CAO Kraamzorg;
                                </div>
                            </li>
                            <li className="py-2 flex">
                                <div className="min-w-[40px] pt-1"><img className="w-7" alt="vinkje" src="/assets/images/icon-form-good.svg"/></div>
                                <div className="pl-1 md:pl-1">
                                    Je ontvangt jaarlijks een salarisverhoging;
                                </div>
                            </li>
                            <li className="py-2 flex">
                                <div className="min-w-[40px] pt-1"><img className="w-7" alt="vinkje" src="/assets/images/icon-form-good.svg"/></div>
                                <div className="pl-1 md:pl-1">
                                    Kraamzorg Mama biedt volop doorgroeimogelijkheden, zodat je altijd het werk doet waar jouw hart ligt;
                                </div>
                            </li>
                            <li className="py-2 flex">
                                <div className="min-w-[40px] pt-1"><img className="w-7" alt="vinkje" src="/assets/images/icon-form-good.svg"/></div>
                                <div className="pl-1 md:pl-1">
                                    Je ontvangt minimaal drie werkuniformen ( zo stat je als kraamspecialist altijd paraat: een uniform trek je aan, een in de was en een in je kast)
                                </div>
                            </li>
                            <li className="py-2 flex">
                                <div className="min-w-[40px] pt-1"><img className="w-7" alt="vinkje" src="/assets/images/icon-form-good.svg"/></div>
                                <div className="pl-1 md:pl-1">
                                    Je ontvangt een auto <span className="text-pink">van de zaak</span> met tankpas (optioneel) <span className="italic">(<sup>*</sup>Vraag naar de voorwaarden)</span>
                                </div>
                            </li>
					    </ul>

					</div>
					<div className="lg:py-16 lg:pl-8 lg:pr-8 order-1 lg:order-2 md:self-center">
						<img alt="ervaren" className="hidden lg:block" src="/assets/images/intakekraamtijd-image-eenervaren.png" />
                        <img alt="ervaren" className="block lg:hidden" src="/assets/images/intakekraamtijd-image-eenervaren-sm.png" />
					</div>

				</div>



			</div>
		</div>            

        <div className="md:container-fluid mt-0 lg:mt-16 lg:mb-16">
			<div className="container mx-auto p-4 lg:p-8 lg:p-0">
                <div className="grid grid-cols-1 lg:grid-cols-2">
                    <div className=""> 
                        <h2 className="text-3xl">Wat wij zoeken</h2>
                        <ul className="mt-4 text-xl font-ro-sans ">
                            <li className="py-2 flex">
                                <div className="min-w-[40px] pt-1"><img className="w-7" alt="vinkje" src="/assets/images/icon-form-good.svg"/></div>
                                <div className="pl-1 md:pl-1">
                                    Je bent een gediplomeerde Kraamverzorgende;
                                </div>
                            </li>
                            <li className="py-2 flex">
                                <div className="min-w-[40px] pt-1"><img className="w-7" alt="vinkje" src="/assets/images/icon-form-good.svg"/></div>
                                <div className="pl-1 md:pl-1">
                                    Je staat geregistreerd bij het kenniscentrum kraamzorg;
                                </div>
                            </li>
                            <li className="py-2 flex">
                                <div className="min-w-[40px] pt-1"><img className="w-7" alt="vinkje" src="/assets/images/icon-form-good.svg"/></div>
                                <div className="pl-1 md:pl-1">
                                    Je bent gekwalificeerd, gemotiveerd, collegiaal en beschikt - naast een professionele 
                                    werkhouding - over goede communicatieve vaardigheden;
                                </div>
                            </li>
                            <li className="py-2 flex">
                                <div className="min-w-[40px] pt-1"><img className="w-7" alt="vinkje" src="/assets/images/icon-form-good.svg"/></div>
                                <div className="pl-1 md:pl-1">
                                    Je herkent jezelf in de visie en organisatie van Kraamzorg Mama;
                                </div>
                            </li>
                            <li className="py-2 flex">
                                <div className="min-w-[40px] pt-1"><img className="w-7" alt="vinkje" src="/assets/images/icon-form-good.svg"/></div>
                                <div className="pl-1 md:pl-1">
                                    In het bezit zijn van een geldig rijbewijs is gewenst, maar niet noodzakelijk;
                                </div>
                            </li>
                        </ul>
                        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 text-lg mt-4">
                            <div>
                                <Link target="_blank" to="//wa.me/+31634103693" className={`text-center text-white px-4 py-3 bg-pink rounded-lg font-bold flex md:block hover:bg-black`}>
                                    <img src="/assets/images/request-button-icon.svg" alt="verder" className="md:inline md:pr-2"/>
                                    <span className="flex-auto text-center lg:text-left">Whatsapp ons</span> 
                                    
                                </Link>
                            </div>  
                            <div>
                                <ApplyJobButton className="text-center" />
                            </div>                                        
                        </div>
                    </div>

                   


                    <div className="text-lg flex justify-end content-center">

                        <div className="flex bg-yellowish border-l-8 border-[#FCE66C] mt-4 lg:mt-0 lg:w-4/5 h-min self-center">
                            <div className="p-4">
                                <img className="w-16 lg:w-10"  alt="notificatie" src="/assets/images/header-notice-icon.svg" /> 
                            </div>
                            <div className="p-4 pl-0">
                                <div className="lg:text-xl font-bold">Deze vacature sluit op: {(() => {
                                    let date = moment();
                                        date.endOf('month');
                                        return date.format('DD-MM-YYYY');
                                    })()}
                                </div>
                                <p className="lg:text-lg mt-1">
                                    
                                    Voldoe jij aan bovenstaande criteria? Wacht dan niet langer met solliciteren, want wij
                                    zijn per direct op zoek naar jouw?

                                </p>
                            </div>
                        </div>
                    </div>



                </div>
            </div>
        </div>                     



        <Footer />
		<Cookie />

    </Fragment>
  );
}


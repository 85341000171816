import { Fragment, useState } from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Reviews from '../Components/Reviews';
import Cookie from '../Components/Cookie';
import RequestButton from '../Components/RequestButton';
import LimitedPlacesNotification from '../Components/LimitedPlacesNotification';
import ShortSummaryLogos from '../Components/ShortSummaryLogos';
import AanvragenAndPostcodeCheck from '../Components/AanvragenAndPostcodeCheck';
// #DB1F6A

export default function WieZijnWij() {
  return (
    <Fragment>
        
        <Header activePage={`/wij-zijn-wij.html`} />

		<LimitedPlacesNotification />

		<div className="md:hidden">
			
			<img alt="wie zijn wij" className="header-bg-clip bg-cover" src="/assets/images/wiezijnwij-header-sm.png" />
			
		</div>

		<div className="md:container-fluid md:bg-wiezijnwij-header md:h-30-rem md:bg-no-repeat md:bg-right md:bg-gray-700 md:bg-auto header-bg-clip bg-cover">
			
			
			<div className="container mx-auto py-4 lg:py-8 flex justify-start">
				<div className="md:w-[65%] lg:w-[45%] md:bg-black md:bg-opacity-40 md:box-border md:rounded-lg md:z-10 md:relative">
					<h1 className="text-2xl lg:text-4xl md:text-white p-4 lg:py-4 lg:px-8 lg:pb-4 font-ro-sans font-bold">Wie zijn wij?</h1>
					<div className="px-4 pb-2 lg:px-8 lg:pb-8 md:text-white text-lg">
						
						<div>Wij zijn een....</div>

						<ul className="text-lg font-ro-sans ">
							<li className="py-2 pt-3 flex items-center">
								<div className="w-[30px] min-w-[30px] h-[30px] bg-white p-1 rounded-full flex place-content-center place-items-center"><img alt="borstvoeding" className="max-h-[20px]" src="/assets/images/usp-borstvoeding.svg"/></div>
								<div className="pl-4 md:pl-4">Organisatie die <strong>borstvoeding</strong> en <strong>ISO/HKZ</strong> is gecertificeerd</div>
							</li>
							<li className="py-2 flex items-center">
								<div className="w-[30px] min-w-[30px] h-[30px] bg-white p-1 rounded-full flex place-content-center place-items-center"><img alt="25" className="max-h-[20px]" src="/assets/images/usp-25.svg"/></div>
								<div className="pl-4 md:pl-4">Organisatie met meer dan <strong>25 jaar ervaring</strong> in de kraamzorg</div>
							</li>
							<li className="py-2 flex items-center">
								<div className="w-[30px] min-w-[30px] h-[30px] bg-white p-1 rounded-full flex place-content-center place-items-center"><img alt="samenwerken" className="max-h-[20px]" src="/assets/images/usp-hands.svg"/></div>
								<div className="pl-4 md:pl-4">Organisatie die samenwerkt met <strong>alle verloskundigen en zorgverzekeraars</strong></div>
							</li>
							
						</ul>
						<div className="hidden md:block">
							<RequestButton className="hidden md:mt-4 md:inline-block" />
						</div>
					</div>
				</div>
			</div>
		</div>

		

		<div className="md:container-fluid">
			<div className="container mx-auto py-8 md:py-8 px-4 md:rounded-lg border relative md:-top-12 bg-white">
				<h2 className="font-ro-sans text-2xl md:text-3xl text-center font-bold ">Kraamzorg Mama in het kort</h2>
				<ShortSummaryLogos />
			</div>
		</div>

		<div className="md:container-fluid">
			<div className="container mx-auto mt-8 md:mt-4 grid grid-cols-1 lg:grid-cols-12 gap-0">
				<div className="lg:col-span-7">
					<img alt="over ons" src="/assets/images/homepage-image-regeljekraamzorg.png" />
				</div>
				<div className="lg:col-span-5 relative flex content-center ">
					<div className="p-4 lg:p-8 border rounded-xl relative lg:absolute lg:-left-64 lg:top-8 lg:bottom-8 bottom-8 mx-4 lg:mx-0 lg:top-8 bg-white flex flex-col">
						<h2 className="font-ro-sans text-2xl lg:text-2xl font-bold text-pink">Over ons</h2>
						<h3 className="font-ro-sans text-2xl lg:text-3xl font-bold">Kraamzorg Mama is een kraamzorgorganisatie dat al jaren goed staat aangeschreven bij clienten, verloskundigen en alle zorgverzekeraars</h3>
						<p className="mt-4 text-xl">
						Je zoekt de beste kraamzorg organisatie met de juiste kennis en ervaring. Wij begrijpen dat! Wij werken met mensen die de kraamzorg en de kraamvrouw goed begrijpen, professionele en ervaren kraamverzorgenden die met hun hart zorg leveren. Je kunt er dus van uitgaan dat je kraamzorg bij ons altijd goed geregeld is.
						</p>
					</div>
				</div>
			</div>
		</div>

        <div className="md:container-fluid bg-gray-design">
			<div className="container mx-auto mt-8 pt-0 md:mt-16 flex flex-col lg:flex-row">
				
				<div className="px-4 pb-4 lg:px-0 lg:pb-4 lg:w-8/12">
					<h2 className="font-ro-sans text-xl md:text-2xl pt-8 lg:pt-16 pb-2 lg:pb-1 font-bold text-pink leading-none">Wij hebben alle kwaliteitscertificaten in huis!</h2>
					<h3 className="font-ro-sans text-2xl md:text-3xl pb-4 font-bold leading-none">Kraamzorg Mama is al jaren in het bezit van het WHO-borstvoedingscertificaat en het HKZ-keurmerk.</h3>
				
					<p className="text-xl">
						Dit betekent dat wij voldoen aan alle kwaliteitseisen die gesteld worden binnen de kraamzorg. Inmiddels hebben al meer dan 50.000 tevreden moeders onze hoogstaande zorgkwaliteit ervaren. Dit gegeven maakt ons trots, motiveert ons en geeft ons positieve energie. Want dit is uiteindelijk waar wij het voor doen!
					</p>
				</div>

				<div className="flex-auto p-4 lg:p-16 flex  place-content-center place-items-center">
					<div className="grid grid-cols-3 lg:grid-cols-1 gap-2 max-w-fit">
						<div className="bg-white border text-center py-4 px-2 md:px-8 flex place-content-center rounded-md" style={{zoom:"0.75"}}>
							<img alt="zorg voor borstvoeding" className="object-scale-down" src="/assets/images/homepage-image-logo-zorgvoorborstvoeding.svg" />
						</div>
						
						<div className="bg-white border p-4 text-center py-4 px-2 md:px-8 flex place-content-center rounded-md" style={{zoom:"0.75"}}>
							<div className="flex flex-col box-border place-content-center place-items-center">
								<img alt="hkz" className="w-[60px] self-center" src="/assets/images/svg-hkz.svg"/>
								<div className="self-center text-white leading-tight p-2 rounded-full text-black text-sm text-center">HKZ certificaat</div>
							</div>
						</div>

						<div className="bg-white border p-4 text-center py-4 px-2 md:px-8 flex place-content-center rounded-md" style={{zoom:"0.75"}}>
							<div className="flex flex-col box-border place-content-center place-items-center">
								<img alt="kiwa" className="w-[60px] self-center" src="/assets/images/logo-kiwa.svg" style={{zoom:"3"}} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div className="md:container-fluid">
			<div className="container mx-auto mt-8 pt-0 md:mt-16  bg-gray-design rounded-xl">
				
				<div className="flex flex-col lg:flex-row">

					<div className="lg:w-2/4 px-4 lg:px-0 py-8 lg:py-16 lg:pr-8 lg:pl-16 order-2 lg:order-1">
						<h2 className="font-ro-sans text-xl md:text-2xl  pb-2 lg:pb-0 font-bold text-pink leading-none">Ruim 50.000 tevreden moeders gingen jou voor</h2>
						<h3 className="font-ro-sans text-2xl md:text-3xl pb-4 lg:pb-4 font-bold leading-none">De kraamtijd met jouw kindje beleef je maar één keer. Ook als je al kinderen hebt. Je wilt daarom dat alles in één keer goed gaat. Wij begrijpen dat! </h3>
						<p className="text-xl">
							Vanaf de aanmelding stemmen wij onze zorg af op al jouw verwachtingen. Onze belofte is eenvoudig: boven al jouw verwachtingen scoren. Die belofte maken we ook echt waar, want we zien veel positieve reacties op onze zorgverlening. Dat geeft ons energie en daar doen we het voor! Inmiddels hebben wij al meer dan 50.000 tevreden moeders verzorgd. Ben je benieuwd naar de ervaringen? Lees ze hieronder:
						</p>

					</div>
					<div className="lg:py-16 lg:pl-8 lg:pr-16 order-1 lg:order-2 md:self-center">
						<img alt="waarom kraamzorg mama" src="/assets/images/wiezijnwij-image-waaromwij.png" />
					</div>

				</div>

				<div className="px-4 lg:px-16 pb-4 lg:pb-8" >
					<h2 className="font-ro-sans text-2xl md:text-3xl pb-4 lg:pb-8 md:text-center font-bold leading-none">Wat vinden andere moeders van onze <span className="text-pink">kraamverzorgende</span></h2>
					<Reviews 
						reviewClassName="rounded-lg mt-4 md:mt-0"
						disableNavigation={true} />
				</div>


			</div>
		</div>

		<AanvragenAndPostcodeCheck />

		

        <Footer />

		<Cookie />

    </Fragment>
  );
}





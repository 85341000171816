import { Fragment, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Cookie from '../Components/Cookie';
import Reviews, { Review } from '../Components/Reviews';
import RequestButton from '../Components/RequestButton';
import LimitedPlacesNotification from '../Components/LimitedPlacesNotification';
import { RatingLogos } from '../Components/ShortSummaryLogos';
// #DB1F6A

export default function Aanvragen({ showZipcodeMessage }) {
    let { zipcode } = useParams();
    let classFirstElementTop = showZipcodeMessage ? 'lg:-top-44' : 'lg:-top-64'

  return (
    <Fragment>
        
        <Header activePage="/kraamzorg-aanvragen.html" />



		<div className={`${showZipcodeMessage ? '' : 'hidden'} md:block md:container-fluid md:bg-aanvragen-header md:h-[24rem] md:bg-no-repeat md:bg-right md:bg-[#D8C6AF]  md:header-bg-clip bg-cover`}>
			<div className="container mx-auto py-4 lg:py-4 flex justify-center">

                { showZipcodeMessage && <Fragment>
                    <div className="lg:w-2/6 p-4 lg:p-8 lg:text-white lg:bg-black lg:bg-opacity-40 lg:rounded-lg justify-self-center">
                        <h2 className="text-2xl lg:text-3xl">Goed nieuws!</h2>
                        <h3 className="text-2xl lg:text-3xl">Kraamzorg Mama is ook actief in jouw buurt!</h3>
                    </div>
                </Fragment>} 

            </div>
		</div>

        <div className="md:container-fluid relative font-ro-sans">
			<div className="container mx-auto ">
                <div className={`lg:py-10 lg:px-16 lg:bg-white  lg:border-t lg:border lg:rounded-xl ${classFirstElementTop} relative lg:w-3/6 lg:mx-auto`}>
                    <div className="flex bg-yellowish border-l-8 border-[#FCE66C]">
                        <div className="p-4">
                            <img alt="notice" className="w-32 lg:w-16" src="/assets/images/header-notice-icon.svg" /> 
                        </div>
                        <div className="p-4 pl-0">
                            <div className="lg:text-lg font-bold">Let op! beperkt aantal plaatsen beschikbaar</div>
                            <p className="lg:text-lg">
                                Meld je zo vroeg mogelijk aan. Wij adviseren je om je aan te melden voor de 12e week van je zwangerschap. Op die manier loop je niet het risico om je kraamtijd in te gaan zonder kraamzorg.
                            </p>
                        </div>
                    </div>
                    <div className="mt-8 text-xl text-pink font-bold text-center">"Meer dan 50.000 tevreden moeders gingen jou voor"</div>
                    <div className="p-4 lg:p-0">
                        <h2 className="lg:mt-8 lg:text-center text-3xl font-bold">Vul je gegevens in</h2>
                        <RequestForm zipcode={zipcode} />
                    </div>

                </div>


			</div>
		</div>


        <Footer />

        <Cookie />
    </Fragment>
  );
}

function RequestForm(props) {

    const [submitted, setSubmitted] = useState(false);
    const [sending, setSending] = useState(false);
    const [notInWorkingArea, setNotInWorkingArea] = useState(false); 


    const [name, setName] = useState('');
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');
    const [zipcode, setZipcode] = useState(props.zipcode);
    const [housenumber, setHousenumber] = useState('');
    const [calculatedDate, setCalculatedDate] = useState('');

    const changeZipcode = async (value) => {
        setZipcode(value);
        if(value && value.match(/^[0-9]{4}\s{0,1}[a-zA-Z]{2}$/)) {
            let response = await fetch('/postcode-form-check', { 
                method:'POST', 
                body: JSON.stringify({ zipcode: value }), 
                headers: { 'Content-Type': 'application/json' } 
            });
            let data = await response.json();
            if(!data.delivered) {
                setNotInWorkingArea(true);
            } else {
                setNotInWorkingArea(false);
            }
        }


    }

    const sendForm = () => {
        setSending(true);
        setTimeout(() => {
            setSending(false);
        })
    }

    return <form className="mt-4 lg:mt-8" onSubmit={() => sendForm()} method="post">

        <FormInput 
            label="Voor - Achternaam"
            isRequired={true}
            requiredLabel={"Vul je voor en achternaam in"}
            type="text"
            value={name}
            name="name"
            validator="[a-zA-Z]+"
            isActivated={submitted}
            onUpdate={(value) => setName(value)} />

        <FormInput 
            label="Mobiel"
            isRequired={true}
            requiredLabel={"Vul je mobiel in, voorbeeld: 06-1234567"}
            type="number"
            value={mobile}
            validator="[0-9]{5,}"
            name="mobile"
            
            isActivated={submitted}
            onUpdate={(value) => setMobile(value)} />

        <FormInput 
            label="Email"
            isRequired={true}
            requiredLabel={"Vul een geldig e-mail in"}
            type="email"
            value={email}
            name="email"
            validator="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}"
            isActivated={submitted}
            onUpdate={(value) => setEmail(value)} />

        <FormInput 
            label="Postcode"
            isRequired={true}
            requiredLabel={"Vul je postcode in, voorbeeld: 1000 AA"}
            type="text"
            value={zipcode}
            name="zipcode"
            validator="[1-9][0-9]{3}\s{0,1}[a-zA-Z]{2}"
            isActivated={submitted}
            onUpdate={(value) => changeZipcode(value)} />

        <FormInput 
            label="Huisnummer"
            isRequired={true}
            requiredLabel={"Vul je huisnummer in"}
            type="text"
            value={housenumber}
            name="housenumber"
            validator="[a-zA-Z0-9]+"
            isActivated={submitted}
            onUpdate={(value) => setHousenumber(value)} />

        <FormInput 
            label="Uitgerekende datum"
            isRequired={true}
            requiredLabel={"Vul je uitgerekende datum in"}
            type="text"
            value={calculatedDate}
            name="calculatedDate"
            validator="[a-zA-Z0-9]+"
            isActivated={submitted}
            onUpdate={(value) => setCalculatedDate(value)} />

        { notInWorkingArea && <div className="mb-4">
            <div className="text-pink font-bold">
                Helaas, in de door jou ingevoerde postcode verleent Kraamzorg Mama geen kraamzorg.
            </div>
        </div> }

        <button type="submit" disabled={notInWorkingArea || sending} className="disabled:opacity-50 text-white px-16 mt-8 py-3 bg-green rounded-lg font-bold mx-auto block hover:bg-green-dark">
            { sending? "Bezig met indienen..." : "Aanmelden" }
        </button>

    </form>
}

function FormInput({ className, label, isRequired, requiredLabel, type, onUpdate, value, isActivated, name, validator }) {

    const [activated, setActivated] = useState(isActivated);

    let inputActivated = isActivated || activated;

    

    let formState = "";
    let inputClassName = "";
    if(inputActivated) {
        
        let validatorExpression = new RegExp(validator, "g")
        
        
        if((value && value.match(validatorExpression)) || (!isRequired && value == "")) {
            formState = "good";
            inputClassName = " border-green";
        } else {
            formState = "bad"
            inputClassName = " border-pink";
        }
    }

    

    return <div className={`mb-4 ${className}`}>
        <div className="text-lg font-bold">
            {label} { isRequired && <sup className="text-red">*</sup> }
        </div>
        <div className="relative">
            <input type={type} onChange={(evt) => onUpdate(evt.target.value)} value={value} name={name} className={`outline-none p-2 text-lg bg-[#f8f8f8] border rounded-md block w-full ${inputClassName}`} onFocus={() => setActivated(true) } />
            <div className="absolute flex top-0 right-0 h-full p-3">
                { formState == 'bad' && <img src={`/assets/images/icon-form-bad.svg`} /> }
                { formState == 'good' && <img className="w-6" src={`/assets/images/icon-form-good.svg`} /> }
            </div>
        </div>
        <div className="text-pink">
            { formState == 'bad' && requiredLabel}
        </div>
    </div>


}

